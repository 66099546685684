import { render, staticRenderFns } from "./GridHeaderRow.vue?vue&type=template&id=2df24fcd&scoped=true"
import script from "./GridHeaderRow.vue?vue&type=script&lang=js"
export * from "./GridHeaderRow.vue?vue&type=script&lang=js"
import style0 from "./GridHeaderRow.vue?vue&type=style&index=0&id=2df24fcd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df24fcd",
  null
  
)

export default component.exports