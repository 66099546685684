import { render, staticRenderFns } from "./VoiceRecorder.vue?vue&type=template&id=159c39d0&scoped=true"
import script from "./VoiceRecorder.vue?vue&type=script&lang=js"
export * from "./VoiceRecorder.vue?vue&type=script&lang=js"
import style0 from "./VoiceRecorder.vue?vue&type=style&index=0&id=159c39d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159c39d0",
  null
  
)

export default component.exports