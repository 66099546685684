<template>
  <div
    class="d-flex textCell"
    @mouseenter="showExpand = true"
    @mouseleave="showExpand = false"
  >
    <v-btn v-if="!editEntityMode" class="expand-button" icon dark x-small color="primary" v-show="$vuetify.breakpoint.mobile || showExpand" @click="showMenu = true">
      <v-icon>mdi-arrow-expand</v-icon>
    </v-btn>
    <v-menu
      v-if="!editEntityMode"
      v-model="showMenu"
      :disabled="!textAreaMode && !showMenu"
      content-class="area-menu"
      nudge-left="5"
      nudge-top="1"
      :close-on-click="readonly ? true : false"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <input
          ref="input"
          class="pl-2 grey--text text--darken-3"
          type="text"
          :readonly="readonly"
          v-model="model"
          disabled
          v-on="on"
          @keyup.enter.shift="insertNewline"
          @focus="$emit('focus')"
          @select="$emit('focus')"
          @keyup.exact.enter="nextLine"
          @blur="finishEditing()"
          data-testid="cellInput"
        />
      </template>

      <div style="position: relative;">
        <v-btn class="close-button" v-if="!readonly" icon text small @click="showMenu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <MarkdownEditorView
          v-if="showMenu"
          v-model="model"
          @blur="finishEditing()"
          :readonly="readonly"
        />
      </div>
    </v-menu>
    <MarkdownEditorView
      v-else
      v-model="model"
      class="edit-entity-dialog-editor"
      :readonly="readonly"
      @blur="finishEditing()"
    />
    <!-- <v-textarea
        v-else
        class="pl-2 edit-mode-area grey--text text--darken-3"
        solo
        flat
        auto-grow
        rows="1"
        hide-details
        :readonly="readonly"
        v-model="model"
        @focus="$emit('focus')"
        @blur="finishEditing()"
      /> -->
  </div>
</template>

<script>
import MarkdownEditorView from '@/editor/MarkdownEditorView.vue'
import { gridCells } from '@/mixins/gridCells.js'

export default {
  mixins: [gridCells],
  data() {
    return {
      showMenu: false,
      showExpand: false,
      editor: undefined
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        const sanitizedValue = newVal === '' ? null : newVal
        this.$emit('input', sanitizedValue)
      }
    },
    textAreaMode() {
      return this.isMultiline(this.model)
    }
  },
  methods: {
    isMultiline(string) {
      if (!string || !(string instanceof String)) return false
      return string.includes('\n')
    },
    insertNewline() {
      if (this.model) {
        this.model += '\n'
        this.showMenu = true
      }
    },
    finishEditing() {
      this.$emit('blur')
    }
  },
  components: {
    MarkdownEditorView
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textCell {
  position: relative;
  overflow: visible;
}

.expand-button {
  position: absolute;
  background: white;
  right: 4px;
  top: 4px;
  height: 24px;
  width: 24px;
  border-radius: 4px !important;
}

.area-menu {
  background: white;
  border-radius: 4px;
  border: 1px solid silver;
  resize: both;
  max-width: 850px;
}

.close-button {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 11;
}

.edit-entity-dialog-editor {
  width: 100%;
}
</style>