<template>
  <div>
    <v-menu
      :offset-x="!$vuetify.breakpoint.mobile"
      :offset-y="$vuetify.breakpoint.mobile"
      open-on-hover
      v-bind="$attrs" v-on="$listeners"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs">
          <MenuItem
            v-bind="attrs" v-on="on"
            @click.native="event => handleSubMenuClick(event, on)"
            :icon="activatorIcon"
            append-icon="mdi-chevron-right"
            :text="activatorText"
            data-testid="subMenuActivator"
          />
        </slot>
      </template>
      <v-list dense>
        <slot />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'

export default {
  props: {
    activatorText: null,
    activatorIcon: {
      type: String,
      default: 'mdi-dots-horizontal'
    }
  },
  methods: {
    handleSubMenuClick(event, on) {
      on.mouseenter()
      event.stopPropagation()
    }
  },
  components: {
    MenuItem
  }
}
</script>