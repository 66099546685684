import {EditorView} from 'prosemirror-view'
import {EditorState} from 'prosemirror-state'
import {schema, defaultMarkdownParser,
        defaultMarkdownSerializer} from 'prosemirror-markdown'
import {exampleSetup} from 'prosemirror-example-setup'

export default class MarkdownEditor {
  constructor(target, content, domEventHandlers, readonly = false) {
    this.view = new EditorView(target, {
      state: EditorState.create(this._initialStateConfig(content, readonly)),
      handleDOMEvents: domEventHandlers,
      editable: () => !readonly
    })
  }

  _initialStateConfig(content, readonly) {
    return {
      doc: defaultMarkdownParser.parse(content),
      plugins: readonly ? [] : exampleSetup({schema})
    }
  }

  get content() {
    return defaultMarkdownSerializer.serialize(this.view.state.doc)
  }

  setContent(content) {
    const tr = this.view.state.tr.replaceWith(0, this.view.state.doc.content.size, defaultMarkdownParser.parse(content))
    this.view.dispatch(tr)
  }

  focus() { this.view.focus() }
  destroy() { this.view.destroy() }

  setReadonly(readonly) {
    this.view.setProps({ editable: () => !readonly })
    
    const newState = EditorState.create(this._initialStateConfig(this.content, readonly))
    this.view.updateState(newState)
  }
}