<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass">
    <template v-slot:menu>
      <v-list>
        <MenuItem
          icon="mdi-link"
          :text="$t('blocks.externalHookButtonBlock.menuButton')"
          @click="openMenu"
        />
        <v-list-item>
          <v-list-item-content class=" accent--text">
            <div class="d-flex align-center mb-2">
              <v-icon small class="mr-3">mdi-format-color-fill</v-icon>
              <v-list-item-title>{{ $t('blocks.backgroundColor') }}</v-list-item-title>
              <v-spacer></v-spacer>
              <v-menu
                v-model="showBackgroundColorPicker"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <div 
                    class="ml-2 rounded elevation-2" 
                    v-bind="attrs"
                    v-on="on"
                    :style="{
                      width: '22px !important',
                      height: '22px !important',
                      backgroundColor: backgroundColor,
                      cursor: 'pointer'
                    }"
                  ></div>
                </template>
                <v-card>
                  <v-color-picker
                    v-model="backgroundColor"
                    mode="hexa"
                    show-swatches
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </div>
            </v-list-item-content>
          </v-list-item>

            <v-list-item>
              <v-list-item-content class="accent--text">
            <div class="d-flex align-center">
              <v-icon small class="mr-3">mdi-format-color-text</v-icon>
              <v-list-item-title>{{ $t('blocks.textColor') }}</v-list-item-title>
              <v-spacer></v-spacer>
              <v-menu
                v-model="showTextColorPicker"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <div 
                    class="ml-2 rounded elevation-2" 
                    v-bind="attrs"
                    v-on="on"
                    :style="{
                      width: '22px !important',
                      height: '22px !important',
                      backgroundColor: color,
                      cursor: 'pointer'
                    }"
                  ></div>
                </template>
                <v-card>
                  <v-color-picker
                    v-model="color"
                    mode="hexa"
                    show-swatches
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <div
      v-if="!resource"
      class="resource-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-button-cursor</v-icon>
      <div>{{ $t('blockTypes.externalHookButtonBlock.subtitle') }}</div>
    </div>
    <div v-else>
      <v-btn 
        rounded 
        @click="buttonClicked"
        :color="backgroundColor"
        :style="{
          color: color
        }"
      >
        {{ resource.name }}
      </v-btn>
    </div>
 
    <v-dialog
      v-model="showDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card  v-if="resource">
        <v-toolbar flat color="white">
          <v-toolbar-title class="font-weight-medium">{{ this.block.resourceBlock.resource.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
         
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0 fill-height">
        <iframe 
          v-if="showDialog" 
          :src="iframeUrl" 
          frameborder="0" 
          ref="iframe" 
          onmousewheel="" 
          width="100%" 
          height="100%" 
          allow="microphone; camera"
          class="iframe-content"
        ></iframe>
      </v-card-text>
      </v-card>
    </v-dialog>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
      min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.externalHookButtonBlock.menuTitle') }}</v-card-title>
        <v-card-text>
          <TemplateResourceInput
            v-model="resource"
            :placeholder="$t('blocks.externalHookButtonBlock.inputLabel')"
            :block="block"
            v-bind="$attrs"
            v-on="$listeners"
            ref="resourcePicker"
            :loadItems="loadResources"
          />
        </v-card-text>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import { mapGetters } from 'vuex'
import MenuItem from '@/components/MenuItem.vue'
import resourceInputMixin from '@/mixins/resourceInputMixin'
import TemplateResourceInput from '@/components/flow/TemplateResourceInput.vue'
import blockProps from '@/mixins/blockProps'
import { debounce } from 'lodash'
import { COLORS } from '@/constants/blockAnnotations'
export default {
  mixins: [ resourceInputMixin, blockProps ],
  props: {
    spaceUri: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      showMenu: false,
      showDialog: false,
      showBackgroundColorPicker: false,
      showTextColorPicker: false,
      menuX: 0,
      menuY: 0,
      loading: false,
      errorMessage: null,
      localBackgroundColor: null,
      localTextColor: null
    }
  },
  computed: {
    ...mapGetters(['blockWithUri']),
    resource: {
      get() {
        return this.block.resourceBlock.resource
      },
      set(newVal) {
        this.handleItemSelected(newVal)
      }
    },
    block() {
      return this.blockWithUri(this.blockUri)
    },
    resourceLink() {
      return this.block?._links?.resources?.href
    },
    iframeUrl() {
      const redirectUri = this.block.resourceBlock.resource?.redirectUri
      return redirectUri ? `${window.location.origin}${redirectUri}` : ''
    },
    backgroundColor: {
      get() {
        return this.localBackgroundColor ?? this.block.backgroundColor ?? COLORS.DEFAULT_BUTTON_BACKGROUND
      },
      set(newVal) {
        this.localBackgroundColor = newVal
        this.debouncedUpdateBackgroundColor(newVal)
      }
    },
    color: {
      get() {
        return this.localTextColor ?? this.block.color ?? COLORS.DEFAULT_BUTTON_TEXT_COLOR
      },
      set(newVal) {
        this.localTextColor = newVal
        this.debouncedUpdateTextColor(newVal)
      }
    }
  },
  created() {
    // Create debounced update functions
    this.debouncedUpdateBackgroundColor = debounce(this.updateBackgroundColor, 300)
    this.debouncedUpdateTextColor = debounce(this.updateTextColor, 300)
  },
  components: {
    BaseBlock,
    MenuItem,
    TemplateResourceInput
  },
  methods: {
    updateBackgroundColor(newVal) {
      this.block.backgroundColor = newVal
    },
    updateTextColor(newVal) {
      this.block.color = newVal
    },
    openMenu(event) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.showMenu = true
    },
    async handleItemSelected(newVal) {
      // Implement this method to handle the selected item from the resource picker

      this.loading = true
      try {
        this.block.resourceUri = newVal
        await this.block.updateValue()
      } finally {
        this.loading = false
        this.showMenu = false
      }
    },
    buttonClicked() {
      if (this.iframeUrl) {
        this.showDialog = true
      }
    },
    handlePostMessage(event) {
      // Check if the message is from our iframe
      if (event.data && event.data.key === 'apptiveEmbedSubmitActionTriggered') {
        this.showDialog = false
      }
    }
  },
  mounted() {
    window.addEventListener('message', this.handlePostMessage)
  },
  beforeDestroy() {
    // Cancel any pending debounced calls
    if (this.debouncedUpdateBackgroundColor) {
      this.debouncedUpdateBackgroundColor.cancel()
    }
    if (this.debouncedUpdateTextColor) {
      this.debouncedUpdateTextColor.cancel()
    }
    window.removeEventListener('message', this.handlePostMessage)
  }
}
</script>

<style scoped>
.resource-empty {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 12px;
  max-width: 450px;
}

/* Apply these styles only to the dialog's v-card */
.v-dialog .v-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-dialog .v-card__text {
  flex-grow: 1;
  height: calc(100vh - 64px);
  overflow: hidden;
}

.iframe-content {
  height: 100%;
}
</style>
