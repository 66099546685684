<template>
  <div data-testid="resourceInput">
    <v-select
      v-model="selectModel"
      :items="items"
      item-value="_links.self.href"
      :item-text="displayValue"
      :loading="loading"
      hide-details solo dense
      clearable
    >
      <template v-slot:item="{item}">
        <ResourceItemDisplay 
          :resource="item" 
          :data-testid="`resourceInput-item-${items.indexOf(item)}`"
        />
      </template>
      <template v-slot:selection="{item}">
        <ResourceItemDisplay :resource="item" />
      </template>
    </v-select>
  </div>
</template>

<script>
import { createHolder } from '@/apptivescript/model'
import ResourceItemDisplay from '@/components/ResourceItemDisplay.vue'
import { columnTypes } from '@/constants/columnTypes'
import externalModel from '@/mixins/externalModel'
import ApiClient from '@/ApiClient'

export default {
  mixins: [externalModel],
  props: {
    block: null,
    loadItems: {
      type: Function,
      async default() {
        if (!this.block || !this.block._links.resources.href) return []
        try {
          const response = await ApiClient.getUri(this.block._links.resources.href)
          return response.data.items
        } catch (error) {
          console.error('Error loading items:', error)
          return []
        }
      }
    },
    onItemSelected: {
      type: Function,
      default(newVal) {
        this.externalModel = createHolder({ href: createHolder(newVal) })
        this.$emit('blur')
      }
    }
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  computed: {
    selectModel: {
      get() {
        if (!this.externalModel || !this.items?.length) return null

        const modelHref = 
          this.externalModel?._links?.self?.href?.value 
          || this.externalModel?._links?.self?.href
          || this.externalModel?.href?.value
        return this.items.find(item =>
          item._links?.self.href === modelHref
        ) || null
      },
      set(newVal) {
        this.onItemSelected(newVal)
      }
    }
  },
  watch: {
    async block(newBlock) {
      if (!newBlock || !newBlock._links.resources.href) return
      await this.reloadItems()
    },
    loadItems: {
      immediate: true,
      async handler() {
        await this.reloadItems()
      }
    }
  },
  methods: {
    async reloadItems() {
      if (!this.loadItems) return
      this.loading = true
      try {
        this.items = await this.loadItems()
      } catch (error) {
        console.error('Error reloading items:', error)
      } finally {
        this.loading = false
      }
    },
    displayValue(item) {
      return columnTypes.resource.displayFormat(item)
    }
  },
  components: {
    ResourceItemDisplay
  }
}
</script>

<style lang="scss" scoped>

</style>