<template>
  <div>

    <v-menu v-model="setupMenu" :close-on-content-click="false">
      <template v-slot:activator="{on}">
        <div
      class="block-empty d-flex flex-row gap-3 pa-3"
      @click="setupMenu = true"
      v-on="on"
    >
      <v-icon color="#75746F">{{ block.blockType.icon }}</v-icon>
      <div>{{ block.blockType.displaySubtitleString }}</div>
    </div>
      </template>
      <v-sheet class="pa-3">
        <component
          class="background"
          :is="stateComponent"
          :space="space"
          v-model="statefulTypeModel"
        />
        <div class="d-flex justify-end">
          <v-btn text color="primary" @click="submitType">{{$t('dialogs.okButton')}}</v-btn>
        </div>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    },
    space: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      setupMenu: false,
      statefulTypeModel: undefined
    }
  },
  computed: {
    stateComponent() {
      return this.block.columnType?.state?.component
    }
  },
  watch: {
    block: {
      immediate: true,
      handler(newVal) {
        if (newVal == null) {
          this.statefulTypeModel = undefined
        } else {
          this.statefulTypeModel = this.block.columnType?.state?.initialisation(this.block.valueType)
        }
      }
    }
  },
  methods: {
    submitType() {
      this.block.patchType(this.statefulTypeModel)
      this.setupMenu = false
    }
  }
}
</script>

<style scoped>
.block-empty {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}
</style> 