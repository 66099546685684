import FlowNode from '@/store/models/flow/nodes/FlowNode.js'
import { nodeTypes } from '@/constants/nodeTypes'
import TemplateFlowFormPicker from '@/components/flow/TemplateFlowFormPicker.vue'
import i18n from '@/plugins/i18n'
import ApiClient from '@/ApiClient'

export default class FlowFormNode extends FlowNode {
  constructor(data, eventType) {
    super(data, eventType)

    nodeTypes.flowForm
    this.typeName = nodeTypes.flowForm.typeName
    this.description = nodeTypes.flowForm.description
    this.icon = nodeTypes.flowForm.icon
    this.graphIcon = nodeTypes.flowForm.graphIcon
    this.color = nodeTypes.flowForm.color
    this.docuLink = nodeTypes.flowForm.docuLink
  }

  async loadSetupView(space) {
    return this.loadSetupBlock(space)
  }

  async updateProperties(properties) {
    const copy = JSON.parse(JSON.stringify(properties))
    // Check if form value is changing
    if ('form' in copy && copy.form?._value !== this._previousProperties.form?._value) {
      // Extract form and block for separate patch
      const patchProps = {
        form: copy.form,
        block: copy.block
      }
      
      await super.updateProperties(patchProps)

      // Reload setup block after form change
      await this.loadSetupBlock(this.space)
    } else {
      // No form change, proceed with normal update
      await super.updateProperties(copy)
    }
  }

  _component(key, label, componentType, options, space, jsonSchema, children, block) {
    if (componentType === 'resourceInput' && block.formKey === 'block') {
      return {
        component: TemplateFlowFormPicker,
        props: {
          label: i18n.t('flow.nodeProperties.flowFormPicker'),
          name: label,
          key,
          space,
          loadItems: async () => {
            const response = await ApiClient.getUri(block._links.resources.href)
            return response.data.items.filter(item => item.metaType === 'block')
          },
        }
      }
    }
    else {
      return super._component(key, label, componentType, options, space, jsonSchema, children, block)
    }
  }
}