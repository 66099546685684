<template>
  <div
    class="d-flex textCell align-center"
    @mouseenter="showOpenUri = true"
    @mouseleave="showOpenUri = false"
  >
    <template v-if="readonly && editEntityMode ">
      <v-btn
        block
        text
        color="primary"
        @click="openUri"
        class="text-none"

      >
        {{ field.name }}
      </v-btn>
    </template>
    <template v-else>
      <v-btn
        v-if="model"
        class="expand-button"
        icon dark x-small
        color="primary"
        v-show="showOpenUri"
        @click="openUri"
      >
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-menu
        :value="error"
        bottom offset-y
        nudge-bottom="8px"
        :close-on-click="false"
        :close-on-content-click="false"
        min-width="0"
        transition="none"
        content-class="counter-menu"
      >
        <template v-slot:activator="{}">
          <input
            ref="input"
            class="pl-2 grey--text text--darken-3"
            type="text"
            :readonly="readonly"
            v-model="model"
            @focus="$emit('focus')"
            @select="$emit('focus')"
            @keyup.exact.enter="nextLine"
            @blur="finishEditing"
            data-testid="cellInput"
          />
        </template>
        <div class="error-message pa-1 error--text">{{error}}</div>
      </v-menu>
    </template>
  </div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
import uriInputMixin from '@/mixins/uriInputMixin.js'

export default {
  mixins: [gridCells, uriInputMixin],
  data() {
    return {
      showOpenUri: false,
    }
  },
  computed: {
    error() {
      if (this.model != null && !this.correctedModel.match(/.+:.+/)) {
        return this.$t('validation.uri')
      }
      return undefined
    }
  },
  methods: {
    openUri() {
      const win = window.open(this.model, '_blank')
      win.focus()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textCell {
  position: relative;
  overflow: visible;
}

.expand-button {
  position: absolute;
  background: white;
  right: 4px;
  height: 24px;
  width: 24px;
  border-radius: 4px !important;
}

.error-message {
  font-size: 14px;
  background: white;
}
</style>
