<template>
  <div
    class="wrapper"
    @pointerenter="controlsVisible = true"
    @pointerleave="controlsVisible = false"
  >
    <!-- Container for controls -->
    <div class="left-controls" :class="{'nesting': nesting, controlsPositionClass}">
      <v-btn
        v-if="canAddBlock"
        class="control mr-n1"
        :class="{shown: shouldShowControls}"
        :tabindex="tabIndex"
        icon
        small
        @click="onAdd"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-menu v-if="canMove" v-model="menu" left offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="control"
            :class="{shown: shouldShowControls || menu, [grabbableClass]: grabbableClass}"
            :tabindex="tabIndex"
            v-bind="attrs"
            v-on="on"
          >mdi-drag-vertical</v-icon>
        </template>
        <v-list dense>
          <slot name="menu" />
          <MenuItem
            icon="mdi-delete"
            :text="$t('blocks.deleteBlock')"
            @click.stop="onDelete"
          />
        </v-list>
      </v-menu>
    </div>

    <!-- Slot for other content -->
    <div class="content">
      <slot :showControls="shouldShowControls" />
    </div>

    <!-- Slot for other content -->
    <div class="control right-controls"
    :class="{shown: shouldShowControls}">
      <slot name="right-controls" :showControls="shouldShowControls" ></slot>
    </div>


  </div>
</template>

<script>
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import MenuItem from '@/components/MenuItem.vue'
import blockProps from '@/mixins/blockProps'

export default {
  mixins: [ blockProps ],
  inject: ['canAddChild'],
  props: {
    nesting: {
      type: Boolean,
      default: () => false
    },
    controlsPosition: {
      type: String,
      default: () => 'center'
    }
  },

  data() {
    return {
      controlsVisible: false,
      menu: false
    }
  },
  computed: {
    canAddBlock() {
      return this.editorMode && this.canAddChild
    },
    canDelete() {
      return this.editorMode && hasPermission(this.block, [ PERMISSIONS.remove ])
    },
    canMove() {
      return this.editorMode && hasPermission(this.block, [ PERMISSIONS.patch ])
    },
    tabIndex() {
      return this.controlsVisible ? undefined : -1
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    shouldShowControls() {
      return this.controlsVisible || (this.$vuetify.breakpoint.mobile && this.editorMode)
    },
    controlsPositionClass() {
      return this.controlsPosition === 'top' ? 'controls-position-top' : 'controls-position-center'
    }
  },
  methods: {
    onAdd() {
      this.$emit('appendEmptyParagraph')
    },
    onDrag() {
      this.$emit('drag')
    },
    onDelete() {
      this.$emit('delete')
    }
  },
  components: {
    MenuItem
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: start;
  position: relative;
  min-width: 20px;
  height: 100%;
}

.right-controls {
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);
}
.left-controls.controls-position-top {
  top: 0;
  transform: translateY(0);
}
.left-controls.controls-position-center {
  top: 50%;
  transform: translateY(-50%);
}
.left-controls {
  position: absolute;
  left: -45px;
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* Spacing between controls and content */
}

.nesting {
  left: -90px;
}

.control {
  opacity: 0;
  transition: opacity 0.3s;
}

.shown {
  opacity: 1;
}

.content {
  flex: 1;
  max-width: 100%;
  height: 100%;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.wrapper:focus-within .control {
  opacity: 1;
}

</style>