<template>
  <div>
    <!-- Single toggle button with dynamic icon -->
    <v-btn
      v-if="hasChildPages && !isDrawerOpen"
      icon
      class="mt-2 navigation-drawer-open-button white"
      :class="{ 'ml-2': !isDrawerOpen, 'mr-2': isDrawerOpen }"
      @click="isDrawerOpen = !isDrawerOpen"
      color="accent"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-if="hasChildPages"
      v-model="isDrawerOpen"
      app
      clipped
      class="pt-4"
      width="300"
      floating
      :color="'grey lighten-4'"

    >

    <v-btn
      v-if="hasChildPages && isDrawerOpen && !$vuetify.breakpoint.mobile"
      icon
      class="mt-2 navigation-drawer-toggle-close-button"
      @click="isDrawerOpen = !isDrawerOpen"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <router-link :to="{ name: 'StandaloneBlockPageSearchParams'}" class="text-decoration-none">
      <div class="d-flex align-center">
       
          <SpaceIcon 
            class="ml-4"
            :backgroundColor="rootBlock?.icon?.color"
            :icon="rootBlock?.icon?.value"
            :iconset="rootBlock?.icon?.iconset"
      />

      <span 
        class="ml-2 mr-3 font-weight-bold accent--text"
      >
        {{ rootBlock?.name }}
      </span>
      </div>
      </router-link>
      <v-list dense nav class="pt-4">
        <template v-if="rootBlock">

          <v-list-item
            v-for="page in flattenedPages"
            :key="page.uri"
            :to="{ name: 'StandaloneBlockPage', params: { blockUri: page.uri }}"
            link
            :color="rootBlock?.icon?.color || 'primary'"

            :class="'pl-' +  (page.level) + ' ' + 'ml-' + (page.level - 1  * 1)"
          >
            <v-list-item-icon class="mr-2 ml-1">
              <BlockNavigationIcon
                :icon="page.icon?.value"
                :iconset="page.icon?.iconset"
                :color="page.icon?.color || rootBlock?.icon?.color"
                :smallIcon="page.level > 1"
                :standalone="true"
              />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2">
                {{ page.name || $t('blocks.emptyPageTitle') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-spacer></v-spacer>
      <template v-slot:append>
        <v-sheet v-if="user" rounded="lg" class="ma-3" elevation="4">
          <UserProfileInfo :showSettings="false" />
        </v-sheet>
      </template>
      
    </v-navigation-drawer>

    <PWAIconGenerator
      v-if="rootBlock"
      :title="rootBlock.name"
      :iconColor="rootBlock?.icon?.color"
      :mdiIcon="rootBlock?.icon?.value"
      :iconset="rootBlock?.icon?.iconset || 'mdi'"
    />
  </div>
</template>

<script>
import UserProfileInfo from '@/components/user/UserProfileInfo.vue'
import PWAIconGenerator from '@/components/PWAIconGenerator.vue'
import { darkColor } from '@/utils/color'
import SpaceIcon from '@/components/space/SpaceIcon.vue'
import BlockNavigationIcon from '@/components/block/BlockNavigationIcon.vue'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus'
export default {
  props: {
    blockUri: { 
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDrawerOpen: true,
      rootBlockUri: null,
      iconColor: '#1565C0',
    }
  },
  computed: {
    user() {
      return this.$store.state.user?.user
    },
    pageBlocks() {
      return this.rootBlock?.children?.filter(child => child.type === 'page') || []
    },
    rootBlock() {
      return this.$store.getters.blockWithUri(this.rootBlockUri)
    },
    flattenedPages() {
      const result = []
      
      const addPagesRecursively = (pages, level) => {
        pages.forEach(page => {
          result.push(Object.assign(page, { level }))
          
          const childPages = page.children?.filter(child => child.type === 'page') || []
          if (childPages.length > 0 && level < 3) {
            addPagesRecursively(childPages, level + 1)
          }
        })
      }
      
      addPagesRecursively(this.pageBlocks, 1)
      return result
    },
    hasChildPages() {
      return this.pageBlocks?.length > 0
    },
    isDarkBackground() {
      const color = this.rootBlock?.icon?.color || 'grey lighten-4'
      // For Vuetify color classes, default to false (light)
      if (color.includes(' ') || !color.startsWith('#')) {
        return false
      }
      return !darkColor(color)
    },
  },
  mounted() {
    this.isDrawerOpen = !this.$vuetify.breakpoint.mobile
    linkAuthorizationNeededBus.onLinkAuthorizationGranted(this.onLinkAuthorizationGranted)
    const urlParams = new URLSearchParams(window.location.search)
    this.rootBlockUri = urlParams.get('uri')
    this.loadBlock()
  },
  beforeDestroy() {
    linkAuthorizationNeededBus.linkAuthorizationGrantedOff(this.onLinkAuthorizationGranted)
  },
  methods: {
    isSelected(block) {
      return block.uri === this.blockUri || block.uri === this.rootBlockUri
    },
    onLinkAuthorizationGranted() {
      this.loadBlock()
    },
    async loadBlock() {
      if (this.rootBlock) return
      
      try {
        const block = await this.$store.dispatch('AGReadBlock', { 
          blockUri: this.rootBlockUri, 
          customErrorHandling: [400, 404]
        })

        this.rootBlockUri = block._links.self.href
        this.uri.uri = block._links.self.href
      } catch (error) { 
        if (error.response?.status === 404 || error.response?.status === 400) {
          this.errorMessage = this.$t('errorMessages.unaccessibleView')
        }
      }
    },
  },
  components: {
    UserProfileInfo,
    PWAIconGenerator,
    SpaceIcon,
    BlockNavigationIcon
  }
}
</script>

<style scoped>
.navigation-drawer-open-button { 
  position: absolute;
  top: 2px;
  left: 8px;
  z-index: 2;
}

.navigation-drawer-toggle-close-button { 
  position: absolute;
  top: 2px;
  right: 16px;
  z-index: 2;
}
</style> 