<template>
  <div class="node" :style="style" :data-testid="`node_${node.type}`">
    <div v-if="step" :class="`indicator ${color}`">
      <v-btn
        v-if="step"
        dark 
        :data-testid="`node_indicator_${node.type}_Button`"
        @mouseup.native="indicatorClicked"
        icon dense>
        <v-tooltip open-delay="200" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon dense v-bind="attrs" v-on="on">{{icon}}</v-icon>
          </template>
          <span>{{ tooltipText }}</span>
        </v-tooltip>
      </v-btn>
    </div>
    <div
      @contextmenu.prevent="rightClick"
      @mousedown="onMouseDown"
      @mouseup="nodeClicked"
      @mouseenter="onMouseenter"
      :id="node.id"
      class="label-container"
    >
      
      <div :style="iconStyle" class=" cursor-grab align-center justify-center d-flex">
        <img draggable="false" v-if="node.image != null" width="80%" :src="`${node.image}`">
        <v-icon
          v-else
          :size="size / 2.2"
          class="white--text"
        >
          {{ node.graphIcon }} 
        </v-icon>
      </div>
  
      <div class="node-label-container text-center" :style="nodeLabelStyle">
        <span class="font-weight-medium node-label pa-1 ">
          {{ node.name }}
        </span>
      </div>
      <FlowTriggerIndicator
        v-if="node.type == 'start'"
        :trigger="trigger"
        :color="node.color"
        :nodeSize="size"
      />
      <v-menu
        ref="contextMenu"
        v-model="showContextMenu"
        :position-x="menuX"
        :position-y="menuY"
        rounded
        :close-on-content-click="false"
      >
        <v-list dense>
          <MenuItem
            v-if="this.canShowConfig"
            icon="mdi-cog"
            :text="$t('flow.nodeMenu.configure')"
            @click="showConfig"
          />
          <MenuItem
            :disabled="!canRenameNode"
            icon="mdi-pencil-outline"
            :text="$t('flow.nodeMenu.rename')"
            @click="editNodeNameDialog = true"
          />
          <MenuItem
            :disabled="!canDeleteNode"
            icon="mdi-delete-outline"
            :text="$t('flow.nodeMenu.delete')"
            @click="deleteNode"
          />
          <MenuItem
            v-if="node.canAddOutgoing()"
            :disabled="!canAddTransition"
            icon="mdi-plus"
            :text="$t('flow.nodeMenu.addConnection')"
            @click="addOutgoing"
          />
        </v-list>
        <SingleInputDialog
          v-model="editNodeNameDialog"
          :title="'Rename Node'"
          :okButtonLabel="$t('dialogs.saveButton')"
          :okHandler="renameNode"
          :initialInputValue="node.name"
          max-width="350"
        />
      </v-menu>
  
    </div>
  </div>
</template>

<script>
import flowLayoutBus from '@/components/flow/flowLayoutBus.js'
import MenuItem from '@/components/MenuItem.vue'
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import FlowTriggerIndicator from '@/components/flow/FlowTriggerIndicator.vue'
import { stepStates } from '@/store/models/flow/Step.js'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'

export default {
  props: {
    node: null,
    flow: null,
    size: {
      type: Number
    },
    clickBlocked: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      selected: false,
      dragging: false,
      showContextMenu: false,
      menuX: 0,
      menuY: 0,
      listeners: {
        'flow-node-selected' : (data) => this.flowNodeSelected(data),
        'flow-node-deselect' : () => this.flowNodeDeselect(),
        'I am a transition looking for a node to connect' : data => this.responseIfConnectable(data),
        'It is me, I am a node you are the next node to me so lets connect' : data => this.addTransition(data)
      },
      editNodeNameDialog: false,
      leftClick: false,
      nodeLabelWidth: 180
    }
  },
  mounted() {
    this.setInitalPosition()
    Object.keys(this.listeners).forEach((key) => {
      flowLayoutBus.$on( key , this.listeners[key] )
    })
  },
  beforeDestroy(){
    Object.keys(this.listeners).forEach((key) => {
      flowLayoutBus.$off( key , this.listeners[key] )
    })
  },
  methods: {
    flowNodeSelected(data) {
      if (data.nodeId !== this.node.id) {
        this.selected = false
      }
    },
    flowNodeDeselect() {
      this.selected = false
    },
    responseIfConnectable(data) {
        this.selected = false
        if (
          this.flow.connectable({ source: data.node, target: this.node })
        ) {
          this.selected = true
          // anser with position
          flowLayoutBus.$emit('I am a node and be ready to connect with you', {
            node: this.node,
            transition: data.transition
          })
        }
    },
    addTransition (data) {
      if (data.me.id !== this.node.id && data.you.id === this.node.id) {
        // set connection I am a not connected node
        this.flow.addTransition(data.me, data.you, data.transition)
        // annaounce
        flowLayoutBus.$emit(
          'I am node thank you for asking me I connected us!',
          { me: this.node, you: data.me, transition: data.transition }
        )
      }
    },
    setInitalPosition() {
      flowLayoutBus.$emit('flow-draw-transition', { nodeId: this.node.id })
    },
    showConfig() {
      // show node editor only if something can be configured
      if(this.canShowConfig) {
        this.$emit('showMenu')
      }
    },
    rightClick(e) {
      e.preventDefault()
      this.showContextMenu = false
      this.menuX = e.clientX
      this.menuY = e.clientY
      this.$nextTick(() => {
        this.showContextMenu = true
      })
    },
    deleteNode() {
      this.$emit('deleteNode', this.node)
      this.showContextMenu = false
    },
    addOutgoing() {
      this.node.addOutgoing()
      this.showContextMenu = false
    },
    onMouseenter() {
      flowLayoutBus.$emit('animateTransition', { nodeId: this.node.id })
    },
    renameNode(newName) {
      this.node.name = newName
      this.node.patchName()
      this.showContextMenu = false
    },
    onMouseDown() {
      this.leftClick = true
    },
    nodeClicked(event) {
      if (!this.leftClick || event.button === 2) {
        return
      }
      this.leftClick = false
      if (this.clickBlocked) {
        return
      }
      this.selected = true
      flowLayoutBus.$emit('flow-node-selected', { nodeId: this.node.id })
      if(this.node.canAddOutgoing()){
        this.node.addOutgoing()
        this.$emit('onNodeIde')
      }
      else if (this.canShowConfig) {
        this.$emit('showMenu')
      }
      else {
        this.$emit('onNodeIde')
      }
    },
    indicatorClicked() {
      this.$emit('indicatorClicked')
    }
  },
  computed: {
    canShowConfig() {
      return this.node.canShowConfig() || this.node.type == 'start'
    },
    canPatchNode() {
      return hasPermission(this.node, [PERMISSIONS.patch])
    },
    canRenameNode() {
        return this.canPatchNode
    },
    canDeleteNode() {
      return hasPermission(this.node, [PERMISSIONS.remove])
    },
    canAddTransition() {
      return hasPermission(this.flow, [PERMISSIONS.addTransition]) 
    },
    style() {
      return {
        top: `${this.node.layout?.position.y ?? 0}px`,
        left: `${this.node.layout?.position.x ?? 0}px`,
        height: `${this.size}px`,
        width: `${this.size}px`,
        'background-color': `${this.node.color}`,
        'border-color': `color-mix(in srgb, ${this.node.color} ${this.selected ? 50 : 0}%, white)`,
      }
    },
    iconStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
      }
    },
    nodeLabelStyle() {
      return {
        width: `${this.nodeLabelWidth}px`,
        top: `${this.size + 5}px`,
        left: `${(this.size - this.nodeLabelWidth) / 2}px`
      }
    },
    step() {
      return this.flow?.testInstance?.steps.find( step => step.getLink('node') == this.node.uri)
    },
    state() {
      return this.step?.state
    },
    icon() {
      const iconMap = {
        [stepStates.created]: 'mdi-vanish',
        [stepStates.done]: 'mdi-checkbox-marked-circle-outline',
        [stepStates.error]: 'mdi-alert-circle-outline'
      }
      return iconMap[this.state] ?? 'mdi-vanish'
    },
    color() {
      const colorMap = {
        [stepStates.created]: 'orange',
        [stepStates.done]: 'green',
        [stepStates.error]: 'red'
      }
      return colorMap[this.state] ?? 'grey'
    },
    trigger() {
      return this.flow?.trigger
    },
    tooltipText() {
      const tooltipMap = {
        [stepStates.done]: this.$t('flow.nodeIndicator.showDetails'),
        [stepStates.error]: this.$t('flow.nodeIndicator.showErrorDetails'),
        [stepStates.created]: this.$t('flow.nodeIndicator.showDetails')
      }
      return tooltipMap[this.state] ?? this.$t('flow.nodeIndicator.showDetails')
    }
  },

  components: {
    MenuItem,
    SingleInputDialog,
    FlowTriggerIndicator
  }
}
</script>

<style lang="css" scoped>
.node {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 220px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  border: 4px solid;
  transition: width .2s, height .2s;
}


.cursor-grab {
  cursor: grab;
}

.label-container {
  position: relative;
}
.node-label-container {
  position: absolute;
}
.node-label{
  user-select: none;
}
.indicator {
  border-radius: 20px;
  position: absolute;
  top: -15px;
  right: -15px;
  width: 25px;
  height: 25px;
  background: #ffffff;
  border: 2px solid white;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
</style>
