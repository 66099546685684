<template>
  <div>
    <v-menu
      top
      offset-y
      max-width="400"
      :close-on-content-click="false"
      v-model="menuOpen"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          :color="instanceStateColor"
          outlined
          pill
        >
          <v-icon left size="18">
            {{ statusIcon }}
          </v-icon>
          <span>{{ instanceStatusText }}</span>
        </v-chip>
      </template>
      
      <v-card>
        <v-card-title class="subtitle-1">
          <v-icon class="mr-2" :color="instanceStateColor">
            {{ statusIcon }}
          </v-icon>
          {{ instance.isAutoLoaded ? $t('flow.editor.instanceInfo.autoLoadedTitle') : $t('flow.editor.instanceInfo.testInstanceTitle') }}
        </v-card-title>
        
        <v-card-text>
          <p v-if="instance.isAutoLoaded">
            {{ $t('flow.editor.instanceInfo.description') }}
          </p>
          <p v-if="instance.createdAt">
            <strong>{{ $t('flow.editor.instanceInfo.runAt') }}:</strong> {{ formatDateTime(instance.createdAt) }}
          </p>
          <p v-if="instance.loadedAt">
            <strong>{{ $t('flow.editor.instanceInfo.loadedAt') }}:</strong> {{ formatDateTime(instance.loadedAt) }}
          </p>
          <p>
            <strong>{{ $t('flow.editor.instanceInfo.status') }}:</strong> {{ instanceState }}
          </p>
          <p>
            <strong>{{ $t('flow.editor.instanceInfo.steps') }}:</strong> {{ instance.steps.length }}
          </p>
        </v-card-text>
        
        <v-divider></v-divider>
        
        <v-card-actions>
          <v-btn text color="primary" @click="openInstanceSelection">
            <v-icon left>mdi-history</v-icon>
            {{ $t('flow.editor.instanceInfo.selectOther') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="clearInstance">
            <v-icon left>mdi-close</v-icon>
            {{ $t('flow.editor.instanceInfo.clear') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    
    <!-- Instance Selection Menu -->
    <v-dialog scrollable v-model="selectInstanceDialog" max-width="700px">
      <v-card scrollable>
        <v-card-title class="headline">
          {{ $t('flow.editor.instanceSelection.title') }}
        </v-card-title>
        
        <v-divider></v-divider>
        
        <v-card-text class="instance-selection-content">
          <div v-if="loadingInstances" class="text-center py-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">{{ $t('flow.editor.instanceSelection.loading') }}</div>
          </div>
          
          <div v-else-if="flowInstances.length === 0" class="text-center py-4">
            <v-icon large color="grey">mdi-information-outline</v-icon>
            <div class="mt-2">{{ $t('flow.editor.instanceSelection.noInstances') }}</div>
          </div>
          
          <v-list v-else two-line>
            <v-list-item
              v-for="(instance, index) in flowInstances"
              :key="index"
              @click="selectFlowInstance(instance)"
              :class="{'selected-instance': isCurrentInstance(instance)}"
            >
              <v-list-item-avatar>
                <v-icon :color="getStatusColor(instance.state)">{{ getStatusIcon(instance.state) }}</v-icon>
              </v-list-item-avatar>
              
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('flow.editor.instanceSelection.instance') }} {{ index + 1 }}
                  <v-chip x-small outlined class="ml-2" v-if="isCurrentInstance(instance)">
                    {{ $t('flow.editor.instanceSelection.current') }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('flow.editor.instanceSelection.created') }}: {{ formatDateTime(instance.createdAt) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              
              <v-list-item-action>
                <v-chip small :color="getStatusColor(instance.state)" text-color="white">
                  {{ instance.state }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        
        <v-divider></v-divider>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="selectInstanceDialog = false">
            {{ $t('flow.editor.instanceSelection.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'FlowTestInstanceInfo',
  props: {
    formatDateTime: {
      type: Function,
      required: true
    },
    flowRunner: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menuOpen: false,
      selectInstanceDialog: false,
      loadingInstances: false,
      flowInstances: []
    }
  },
  computed: {
    instance() {
      return this.flowRunner.flow.testInstance
    },
    instanceStatusText() {
      if (!this.instance) return ''
      
      if (this.instance.isAutoLoaded) {
        return this.$t('flow.editor.instanceStatus.previousRun')
      } else if (this.instance.isSelectedInstance) {
        return this.$t('flow.editor.instanceStatus.selectedInstance')
      } else {
        return this.$t('flow.editor.instanceStatus.testInstance')
      }
    },
    instanceState() {
      const stateMap = {
        'done': this.$t('flow.editor.instanceStatus.completed'),
        'error': this.$t('flow.editor.instanceStatus.failed'),
        'waiting': this.$t('flow.editor.instanceStatus.inProgress'),
        'created': this.$t('flow.editor.instanceStatus.created')
      }
      return this.instance ? (stateMap[this.instance.state] || this.instance.state) : ''
    },
    instanceStateColor() {
      const colorMap = {
        'done': 'success',
        'error': 'error',
        'waiting': 'warning',
        'created': 'info',  
      }
      return colorMap[this.instance.state] || 'grey'
    },
    statusIcon() {
      return this.getStatusIcon(this.instance.state)
    }
  },
  methods: {
    clearInstance() {
      this.$emit('clear-instance')
      this.menuOpen = false
    },
    openInstanceSelection() {
      this.loadInstanceSelectionMenu()
      this.menuOpen = false
    },
    async loadInstanceSelectionMenu() {
      this.selectInstanceDialog = true
      this.loadingInstances = true
      
      try {
        this.flowInstances = await this.flowRunner.getAllFlowInstances(20)
      } catch (error) {
        console.error('Error loading flow instances:', error)
      } finally {
        this.loadingInstances = false
      }
    },
    async selectFlowInstance(instance) {

      const success = await this.flowRunner.loadFlowInstanceById(instance)
      if (success) {
        this.selectInstanceDialog = false
      }
    },
    getStatusColor(state) {
      const colorMap = {
        'done': 'success',
        'error': 'error',
        'waiting': 'warning',
        'created': 'info',
      }
      return colorMap[state] || 'grey'
    },
    getStatusIcon(state) {
      const iconMap = {
        'done': 'mdi-check-circle',
        'error': 'mdi-alert-circle',
        'waiting': 'mdi-clock-outline',
        'created': 'mdi-information-outline',
      }
      return iconMap[state] || 'mdi-help-circle-outline'
    },

    isCurrentInstance(instance) {
      if (!instance || !this.instance) return false
      
      if (instance.uri && this.instance.uri) {
        return instance.uri === this.instance.uri
      }

      // If no reliable comparison method is available, return false
      return false
    }
  }
}
</script>

<style scoped>
.selected-instance {
  background-color: rgba(0, 0, 0, 0.05);
}

</style> 