<template>
  <v-menu
    offset-y
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="accent"
        v-show="!hideButton"
        v-bind="attrs"
        icon
        x-small
        @click.prevent="on.click"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <MenuItem
        :disabled="!canPatch"
        @click.stop="showRenameDialog"
        icon="mdi-pencil"
        :text="$t('blocks.renamePage')"
      />
      <MenuItem
        :disabled="!canPatch"
        @click.stop="showHeaderImageDialog"
        icon="mdi-panorama-outline"
        :text="$t('blocks.setHeaderImage')"
      />
      <MenuItem
        :disabled="!canPatch"
        @click.stop="showIconDialog"
        icon="mdi-palette"
        :text="$t('blocks.setIcon')"
      />
      <SubMenu
        :disabled="!canPatch"
        :activator-icon="'mdi-view-grid-outline'"
        :activator-text="$t('blocks.setViewType')"
      >
        <MenuItem
          v-for="(value, key) in viewTypes"
          :key="key"
          :icon="getIconForViewType(value)"
          :text="$t(`blocks.viewTypes.${value}`)"
          @click="setViewType(value)"
          :class="{ 'v-item--active': isCurrentViewType(value) }"
          :appendIcon="isCurrentViewType(value) ? 'mdi-check' : null"
        >
         
        </MenuItem>
      </SubMenu>
      <MenuItem
        v-if="hasBlockInputs"
        :disabled="!canPatch"
        @click.stop="showKeysDialog"
        icon="mdi-pound"
        :text="$t('blocks.setKeys')"
      />
      <MenuItem
        :disabled="!canDelete"
        v-if="showDelete"
        @click.stop="deleteBlock"
        icon="mdi-delete"
        :text="$t('blocks.deletePage')"
      />
    </v-list>

    <DefaultDialog
      v-model="headerImageDialog"
      :title="$t('blocks.setHeaderImage')"
      
    >
      <FileUrlInput
        :label="$t('blocks.headerImage')"
        :fixedFileName="$t('blocks.headerImage')"
        v-model="headerImageUrl"
        data-testid="form-builder-logoImage"
      />
    </DefaultDialog>
    <DefaultDialog
      v-model="iconDialog"
      :title="$t('blocks.setIcon')"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="500"
    >
      <PageBlockIconPicker
        v-model="blockIcon"
      />
    </DefaultDialog>
    <SingleInputDialog
      v-model="renameDialog"
      :title="$t('blocks.renamePageTitle', {name: block.name})"
      :okHandler="newName => patchName(newName)"
      :initialInputValue="block.name"
      max-width="290"
    />
    <InputBlockKeysDialog
      v-model="keysDialog"
      :block="block"
    />
  </v-menu>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import DefaultDialog from '@/components/DefaultDialog.vue'
import FileUrlInput from '@/components/form/FileUrlInput.vue'
import InputBlockKeysDialog from '@/components/block/InputBlockKeysDialog.vue'
import PageBlockIconPicker from '@/components/pageBlock/PageBlockIconPicker.vue'
import SubMenu from '@/components/SubMenu.vue'
import PageBlock from '@/store/models/blocks/PageBlock'
export default {
  props: {
    blockUri: {
      type: String
    },
    parentUri: {
      type: String
    },
    hideButton: {
      type: Boolean,
      default: () => false
    },
    showDelete: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      menu: false,
      headerImageDialog: false,
      renameDialog: false,
      keysDialog: false,
      iconDialog: false,
      viewTypes: PageBlock.VIEW_TYPES
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    parentBlock() {
      return this.$store.getters.blockWithUri(this.parentUri)
    },
    canPatch() {
      return hasPermission(this.block, [PERMISSIONS.patch])
    },
    canDelete() {
      return hasPermission(this.block, [PERMISSIONS.remove])
    },
    headerImageUrl: {
      get() {
        return this.block?.headerImageUrl
      },
      set(url) {
        this.block.setHeaderImageUrl(url)
      }
    },
    hasBlockInputs() {
      return this.block?.hasBlockInputs
    },
    blockIcon: {
      get() {
        return this.block?.icon
      },
      set(newIcon) {
        this.block.setIcon(newIcon)
      }
    }
  },
  methods: {
    async patchName(newName) {
      await this.block.patchName(newName)
      this.block.name = newName
    },
    showHeaderImageDialog() {
      this.headerImageDialog = true
    },
    showRenameDialog() {
      this.renameDialog = true
    },
    showKeysDialog() {
      this.keysDialog = true
    },
    showIconDialog() {
      this.iconDialog = true
    },
    async deleteBlock() {
      this.$emit('deleteBlock')
    },
    isCurrentViewType(type) {
      return type === this.block?.viewType
    },
    getIconForViewType(type) {
      const icons = {
        [PageBlock.VIEW_TYPES.DEFAULT]: 'mdi-page-layout-body',
        [PageBlock.VIEW_TYPES.FULL]: 'mdi-fullscreen',
      }
      return icons[type]
    },
    async setViewType(type) {
      await this.block.setViewType(type)
    }
  },
  components: {
    MenuItem,
    SingleInputDialog,
    DefaultDialog,
    FileUrlInput,
    InputBlockKeysDialog,
    PageBlockIconPicker,
    SubMenu
  }
}
</script>

<style
  lang="scss"
  scoped
></style>