<template>
  <v-sheet class="d-flex flex-column" :style="constants" ref="pickerRoot">
    <div class="d-flex justify-space-between py-2" >
      <v-btn v-if="showReset" text color="accent" @click="reset">{{ $t('iconPicker.removeButton') }}</v-btn>

      <div class="d-flex align-center justify-end mx-3">
        <v-sheet 
          v-for="aColor in predefinedColors" 
          :key="aColor"
          width="20" 
          height="20" 
          @click="didSelectColor(aColor)"  
          class="mr-2 pointer" 
          rounded 
          :color="aColor"
        ></v-sheet>
        <template v-if="showColorPicker">
          <PaywallMenu :feature="$apptive.constants.features.SPACE_COLORS">
            <ColorPicker :swatches="swatches" v-model="selectedColor" @change="onColorChange"/>
          </PaywallMenu>
        </template>
        </div>
    </div>
    
    <v-divider v-if="showReset"></v-divider>
    <div class="d-flex align-center mx-3">
      <v-tabs v-model="activeTab" class="flex-grow-1">
        <v-tab>Icons</v-tab>
        <v-tab v-if="showEmojis">Emojis</v-tab>
        <v-tab v-if="showFileIcons">File Icons</v-tab>
      </v-tabs>
    </div>
   
    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <div class="d-flex align-center justify-end pa-2" >
        <v-text-field autofocus clearable solo flat hide-details v-model="filter"
        :placeholder="$t('iconPicker.searchIcons')" />
        </div>
        <v-virtual-scroll 
          class="align-self-center" 
          :items="lines" 
          :item-height="itemWidth" 
          height="400" 
          :width="scrollerWidth" 
          bench="2" 
          ref="scroller"
        >
          <template v-slot="{ index, item }">
            <div :key="index" class="d-flex">
              <v-tooltip v-for="icon in item" :key="icon.name" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    @click="() => selectIcon(icon)" 
                    v-on="on" 
                    v-bind="attrs" 
                    class="iconBackground ma-3" 
                    :style="iconStyle(icon)"
                  >{{ `mdi-${icon.name}` }}</v-icon>
                </template>
                <span>{{ icon.name }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-virtual-scroll>
      </v-tab-item>
      <v-tab-item>
        <div class="emoji-picker-container" v-if="showEmojis">
          <emoji-picker @emoji-click="selectEmoji" />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="file-icon-picker-container pa-4" v-if="showFileIcons">
          <div v-if="fileIconUrl" class="d-flex flex-column align-center mb-4">
            <div class="selected-file-icon-container">
              <img 
                :src="fileIconUrl" 
                class="selected-file-icon" 
                :style="{'background-color': selectedColor}"
              />
            </div>
            <v-btn 
              text 
              color="error" 
              class="mt-2" 
              @click="clearFileIcon"
            >
              {{ $t('iconPicker.removeFileIcon') }}
            </v-btn>
          </div>
          <FilePond
            class="pointer"
            :unauthenticated="true"
            :label-idle="$t('iconPicker.uploadFileIconPrompt')"
            :allow-multiple="false"
            :disableRename="true"
            :existingFiles="fileIconFiles"
            @fileUploaded="onFileIconUploaded"
            @fileDeleted="clearFileIcon"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-sheet>
</template>

<script>
import ColorPicker from '../space/ColorPicker.vue'
import { darkColor } from '@/utils/color'
import { ICON_SIZE } from '@/components/space/SpaceIcon.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'
import externalModel from '@/mixins/externalModel'
import FilePond from '@/components/attachments/FilePond.vue'
import 'emoji-picker-element'
const ICON_PADDING_PX = 12

export default {
  name: 'IconPicker',
  mixins: [externalModel],
  props: {
    value: {
      type: Object,
      default: () => ({
        iconset: 'mdi',
        value: 'table',
        color: '#1664c0'
      })
    },
    showColorPicker: {
      type: Boolean,
      default: true
    },
    showReset: {
      type: Boolean,
      default: true
    },
    showEmojis: {
      type: Boolean,
      default: false
    },
    showFileIcons: {
      type: Boolean,
      default: false
    },
    predefinedColors: {
      type: Array,
      default: () => ['#ff453aff', '#007affff', '#5856D6', '#30d158ff', '#ffd60aff']
    },
    defaultIcon: {
      type: Object,
      default: () => ({
        iconset: 'mdi',
        value: 'table',
        color: '#1664c0'
      })
    },
  },
  data() {
    return {
      meta: [],
      itemWidth: ICON_SIZE + (ICON_PADDING_PX * 2),
      columns: 12,
      filter: undefined,
      selectedColor: this.value?.color || '#1664c0',
      selectedIcon: undefined,
      resizeObserver: undefined,
      activeTab: 0,
      fileIconUrl: null,
      fileUploadMenu: false,
      swatches: [
        ['#ff453aff', '#30B2C7'],
        ['#F89700', '#32ADE6'],
        ['#ffd60aff', '#007affff'],
        ['#30d158ff', '#5856D6'],
        ['#00C7BE', '#af52deff'],
      ]
    }
  },
  async mounted() {
    const metaModule = await import('@mdi/svg/meta.json')
    this.meta = metaModule.default
    
    // If we have an initial value, scroll to it after icons are loaded
    if (this.value?.value) {
      if (this.value.iconset === 'file') {
        this.fileIconUrl = this.value.value
        this.activeTab = 2 // Switch to file tab
      } else if (this.value.iconset === 'emoji') {
        this.activeTab = 1 // Switch to emoji tab
      } else {
        this.scrollToIcon(this.value.value)
      }
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if(entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize
          if (contentBoxSize.inlineSize > 0) {
            this.columns = Math.floor(contentBoxSize.inlineSize / this.itemWidth)
          }
        }
      }
    })
    this.resizeObserver.observe(this.$refs.pickerRoot.$el)
  },
  beforeDestroy() {
    this.resizeObserver?.unobserve(this.$refs.pickerRoot.$el)
  },
  computed: {
    filteredItems() {
      if (!this.filter) return this.meta
      return this.meta.filter(item => 
        item.name.includes(this.filter.toLowerCase()) || 
        item.tags.some(tag => tag.toLowerCase().includes(this.filter.toLowerCase())) || 
        item.aliases.some(alias => alias.toLowerCase().includes(this.filter.toLowerCase()))
      )
    },
    scrollerWidth() {
      return this.itemWidth * this.columns
    },
    lines() {
      return this.filteredItems.reduce((acc, icon, index) => {
        if (index % this.columns === 0) {
          acc.push([])
        }
        acc[acc.length - 1].push(icon)
        return acc
      }, [])
    },
    constants() {
      return {
        '--icon-size': ICON_SIZE + 'px'
      }
    },
    fileIconFiles() {
      return this.fileIconUrl ? [{url: this.fileIconUrl, name: 'icon'}] : []
    }
  },
  methods: {
    selectIcon(icon) {
      this.selectedIcon = icon
      this.emitChange(icon.name, 'mdi', this.selectedColor)
    },
    scrollToIcon(iconName) {
      if (!iconName || !this.meta.length) return
      
      const iconIndex = this.meta.findIndex(icon => icon.name === iconName)
      if (iconIndex !== -1) {
        this.selectedIcon = this.meta[iconIndex]
        // Scroll selected icon into view
        this.$nextTick(() => {
          this.$refs.scroller.$el.scrollTop = Math.floor(iconIndex / this.columns) * this.itemWidth
        })
      }
    },
    iconStyle(icon) {
      if (icon.name !== this.selectedIcon?.name) return
      return {
        'background-color': this.selectedColor,
        color: darkColor(this.selectedColor) ? 'black' : 'white' 
      }
    },
    reset() {
      this.selectedIcon = this.meta.find(icon => icon.name === this.defaultIcon.value)
      this.selectedColor = this.defaultIcon.color
      this.fileIconUrl = null
      this.emitChange(this.selectedIcon.name, this.defaultIcon.iconset, this.selectedColor)
    },
    onColorChange(color) {
      this.selectedColor = color
      if (this.activeTab === 0 && this.selectedIcon) {
        // Icon mode
        this.emitChange(this.selectedIcon.name, 'mdi', color)
      } else if (this.activeTab === 1 && this.value?.value) {
        // Emoji mode
        this.emitChange(this.value.value, 'emoji', color)
      } else if (this.activeTab === 2 && this.fileIconUrl) {
        // File icon mode
        this.emitChange(this.fileIconUrl, 'file', color)
      }
    },
    didSelectColor(color) {
      this.onColorChange(color)
    },
    emitChange(iconName, iconset, color) {
      this.$emit('input', {
        iconset: iconset,
        value: iconName,
        color: color,
      })
    },
    selectEmoji(event) {
      const emoji = event.detail.unicode
      this.selectedIcon = emoji
      this.emitChange(emoji, 'emoji', this.selectedColor)
    },
    onFileIconUploaded(files) {
      if (files && files.length > 0) {
        this.fileIconUrl = files[0].url
        this.emitChange(this.fileIconUrl, 'file', this.selectedColor)
        this.fileUploadMenu = false
      }
    },
    clearFileIcon() {
      this.fileIconUrl = null
      // Fallback to default icon
      this.reset()
      this.fileUploadMenu = false
    }
  },
  components: {
    ColorPicker,
    PaywallMenu,
    FilePond
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue?.color && newValue.color !== this.selectedColor) {
          this.selectedColor = newValue.color
        }
        
        // Handle different icon types
        if (newValue?.iconset === 'file' && newValue?.value) {
          this.fileIconUrl = newValue.value
        } else if (newValue?.value && this.meta.length > 0 && newValue?.iconset === 'mdi') {
          this.scrollToIcon(newValue.value)
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.iconBackground {
  margin-top: 0px;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 20%;
  font-size: calc(var(--icon-size) - 6px);
}

.pointer {
  cursor: pointer;
}

.emoji-picker-container {
  padding: 0px;
  display: flex;
  width: 100%;
  height: 464px;
}

.emoji-picker-container emoji-picker {
  width: 100%;
  height: 464px;
}

.file-icon-picker-container {
  width: 100%;
  height: 464px;
  display: flex;
  flex-direction: column;
}

.file-icon-picker-container .filepond--root {
  margin-bottom: 0;
  min-height: 150px;
}

.selected-file-icon-container {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20%;
  overflow: hidden;
}

.selected-file-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style> 