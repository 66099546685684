<template>
    <span>
          <v-btn class="caption font-weight-medium text-decoration-underline mt-n1 ml-n2" :class="dark ? 'white--text' : 'grey--text'" x-small plain text @click="openDialog">{{$t('forms.abuse.actionTitle')}}</v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <div class="d-flex justify-space-between pt-5 px-6 mb-2">
                    <span class="flex-shrink-1 text-h5 font-weight-medium">{{$t('forms.abuse.title')}}</span>
                    <v-btn class="" icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
              <v-card-subtitle class="mt-1">{{$t('forms.abuse.subtitle')}}</v-card-subtitle>
              <v-card-text>
                <v-textarea outlined multiline v-model="textFieldValue" :placeholder="$t('forms.abuse.placeholder')"></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="accent" @click="dialog = false">{{$t('forms.abuse.cancel')}}</v-btn>
                <v-btn text color="primary" @click="sendText">{{$t('forms.abuse.button')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="waring" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="">{{$t('forms.abuse.warning.title')}}</span>
              </v-card-title>
              <v-card-subtitle style="white-space: pre-line;" class="mt-1">{{$t('forms.abuse.warning.subtitle')}}</v-card-subtitle>
              <v-card-text>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="waring = false">{{$t('forms.abuse.warning.button')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog overlay-color="white" overlay-opacity="0.9" persistent v-model="blocked" max-width="500px">
            <v-card class="px-8 py-4">
              <v-card-title>
                <span class="">{{$t('forms.abuse.blocked.title')}}</span>
              </v-card-title>
              <v-card-subtitle style="white-space: pre-line;" class="mt-1">{{$t('forms.abuse.blocked.subtitle')}}</v-card-subtitle>
            </v-card>
          </v-dialog>
        </span>
  </template>
  
  <script>
  export default {
    props: ['form', 'dark'],
    data() {
      return {
        dialog: false,
        waring:false,
        blocked: false,
        textFieldValue: '',
        config: null
      }
    },
    async mounted() {
        this.config = this.$settings.abuse 
        if(!this.config.filter) {
            this.config = 
            { 
                form: '/api/a/63c177466558e302ecae4b1f/2MMNWn8WIzLUDI8q1rihV9O5pETUrVM7djbwC3iYAX6bcmwUSz8E1YGa3EKs4qyw0dTNIAyTTm9Sa6UrxRrlssMa3oFxOW8aq5Wd', 
                description: '645a22ee0b5e820bd0b79829',
                link:  '645a23130b5e820bd0b79832',
                id: '645b5fed5296ba61e3b7b4a7',
                filter: '645b5fed5296ba61e3b7b4a7',
                view: 'api/users/63c177466558e302ecae4b1f/spaces/645a22ea0b5e820bd0b79826/grids/645b70c15296baf9b4b7b599/entities?',
            }
        }
        try {
            this.check()
        } catch (error) {
            console.log(error)
        }
    },
    methods: {
      openDialog() {
        this.dialog = true
      },
      async check() {
        const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
        }
        let response = await fetch(this.config.view + new URLSearchParams({
                filter: `{"${this.config.filter}":"${this.form.id}"}`,
                layout: 'key'
            }) , options)
        if(response.status == 200) {
             let responseJson = await response.json()
             if(responseJson.size > 0) {
                if(responseJson.items[0]?.status == 'Blocked') {
                  this.blocked = true
                }
                else {
                  this.waring = true
                }
             }
            
         }
      },
      async sendText() {
        // Perform the desired action with the text
        this.dialog = false
        const payload = {}
        payload[this.config.description] = this.textFieldValue || ''
        payload[this.config.link] = this.form.uri || ''
        payload[this.config.id] = this.form.id || ''
        const options = {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
              'Content-Type': 'application/json'
          }
        }
        await fetch(this.config.form , options)
        this.waring = true
      }
    }
  }
  </script>
  