import ApiClient from '@/ApiClient'
import { createBlock } from '@/store/models/blocks'
import Space from '@/store/models/Space'

import { PERMISSIONS } from '@/utils/halUtils'
import Vue from 'vue'
import { blockTypes } from '@/constants/blockTypes'
import { HEADER_LEVELS } from '@/store/models/blocks/HeaderBlock'

// Recursive function to process blocks and their children
export async function processBlocks(blocks, context) {
  const blockList = blocks.map(item => createBlock(item))

  for (let block of blockList) {
    context.commit('addBlock', {block: block, key: block.uri })

    if (block.children && block.children.length > 0) {
      const childBlocks = await processBlocks(block.children, context)
      block.children = childBlocks // Recursively set the processed child blocks
    }
  }

  return blockList
}

export default {
  state() {
    return {
      spaceBlocks: {},
      blocks: {}
    }
  },
  mutations: {
    addBlock(state, {block: block , key: key }) {
      Vue.set(state.blocks, key, block)
    },
    setSpaceBlocks(state, { spaceUri, blocks }) {
      Vue.set(state.spaceBlocks, spaceUri, blocks)
    },
    removeBlock(state, block) {
      delete state.blocks[block.uri]
    }
  },
  actions: {
    async AGListSpaceBlocks(context, {space}) {
      const link = space.getLink('blocks')
      const response = await ApiClient.getUri(link)
      const blockList = await processBlocks(response.data.items, context)
      context.commit('setSpaceBlocks', { spaceUri: space.uri, blocks: blockList })
      return blockList
    },
    async AGAddPageToBlockOrSpace(context, parent) {
      let link = null
      if(parent instanceof Space) {
        link = parent.getLink(PERMISSIONS.addBlock)
      }
      else {
        link = parent.getLink(PERMISSIONS.addChild)
      }
      return context.dispatch('AGAddPageBlock', link)
    },
    async AGAddPageBlock(context, link) {
      
      const response = await ApiClient.postUri(link, {
        type: 'page'
      })
      const newBlock = await context.dispatch('AGReadBlock', { blockUri: response.headers.location })
      await context.dispatch('AGAddBlockChild', {
        block: newBlock,
        type: blockTypes.header1.type,
        position: 1,
        blockProps: {
          header: {
            level: HEADER_LEVELS[1].backendName
          }
        }
      })
      return newBlock
    },
    async AGReadBlock(context,{ blockUri , customErrorHandling }) {
      const response = await ApiClient.getUri(blockUri, undefined, {customErrorHandling})
      // get exissting block 
      const blockList = await processBlocks([response.data], context)
      return blockList[0]
    },
    async AGDeleteBlock(context, {block: block, parent: parent }) {
      const link = block.getLink(PERMISSIONS.remove)
        const response = await ApiClient.deleteUri(link)
        await context.commit('removeBlock' , block)
        // is block is root Block parent is space 
        if(parent instanceof Space) {
          await context.dispatch('AGListSpaceBlocks', { space: parent })
        }
        else {
          parent.childRemoved(block)
          parent.reload()
        }
        return response
    },
    AGPatchBlock(context, {block, payload}) {
      const link = block.getLink(PERMISSIONS.patch)
      return ApiClient.patchUri(link, payload)
    },
    async AGUpdateBlock(context, {block, payload}) {
      const link = block.getLink(PERMISSIONS.update)
      await ApiClient.putUri(link, payload)
      return block
    },
    async AGAddBlockChild(context, {block, type, position, blockProps }) {
      const link = block.getLink(PERMISSIONS.addChild)
      const response = await ApiClient.postUri(link, {
        type: type,
        position: position,
        ...blockProps
      })
      let newBlock = await context.dispatch('AGReadBlock', { blockUri: response.headers.location })
      block.addChildAtPosition( position - 1 ,newBlock )
      return newBlock
    },
    async AGProcessBlocks(context, { blocks, parent }) {
      return processBlocks(blocks, context, parent)
    },
  },
  getters: {
    blocksOfSpace: state => spaceUri => {
      return state.spaceBlocks[spaceUri]?.map(rootBlock => state.blocks[rootBlock.uri]) || []
    },
    blockWithUri: state => uri => {
      return state.blocks[uri]
    }
  }
}