<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass">
    <template v-slot:menu>
      <MenuItem
        icon="mdi-code-tags"
        :text="$t('blocks.htmlBlock.editHtml')"
        @click="showEditor = true"
      />
    </template>
    <div>
      <div
        v-if="!content"
        class="html-empty d-flex flex-row gap-3 pa-3"
        @click="showEditor = true"
      >
        <v-icon color="#75746F">mdi-language-html5</v-icon>
        <div>{{ $t('blockTypes.html.subtitle') }}</div>
      </div>
      <div v-else v-html="renderedContent" class="html-content" :class="{'fullscreen-content': fullScreen}"></div>
      <v-dialog v-model="showEditor" fullscreen>
        <v-card class="d-flex flex-column" style="min-height: 100vh;">
          <v-card-title class="flex-grow-0 d-flex align-center">
            <span>{{ $t('blocks.htmlBlock.editHtml') }}</span>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="editorLayout"
              dense
              class="mr-4"
            >
              <v-btn value="horizontal" small >
                <v-icon :color="editorLayout === 'horizontal' ? 'primary' : 'accent lighten-3'">mdi-view-split-horizontal</v-icon>
              </v-btn>
              <v-btn value="vertical" small>
                <v-icon :color="editorLayout === 'vertical' ? 'primary' : 'accent lighten-3'">mdi-view-split-vertical</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-btn text color="accent" @click="cancelEdit">{{ $t('dialogs.cancelButton') }}</v-btn>
            <v-btn color="primary" text @click="saveContent" :loading="isSaving">{{ $t('dialogs.saveButton') }}</v-btn>
          </v-card-title>
          <v-card-text class="flex-grow-1 pa-0 d-flex flex-column">
            <div class="flex-grow-1 d-flex" :class="{ 'flex-column': editorLayout === 'horizontal' }" style="min-height: 0;">
              <v-col 
                :style="{
                  minHeight: 0,
                  height: editorLayout === 'horizontal' ? '50%' : '100%',
                  width: editorLayout === 'horizontal' ? '100%' : '50%',
                  overflow: 'hidden'
                }"
                class="pa-4 d-flex flex-column" 
                :class="{ 'order-2': editorLayout === 'horizontal' }" 
              >
                <div class="d-flex align-center mb-2 flex-grow-0">
                  <span class="text-subtitle-2">{{ $t('blocks.htmlBlock.content') }}</span>
                  <v-spacer></v-spacer>
                  <div v-if="gridContext" class="shortcut-hint d-none d-sm-flex align-center">
                    {{ $t('codeMirror.shortcutHint.prefix') }} <kbd>{{ $t('codeMirror.shortcutHint.ctrlKey') }}</kbd> + <kbd>{{ $t('codeMirror.shortcutHint.spaceKey') }}</kbd> {{ $t('codeMirror.shortcutHint.suffix') }}
                  </div>
                </div>
                <div class="flex-grow-1 d-flex flex-column" style="min-height: 0; overflow: hidden;">
                  <CodeMirrorEditor
                    v-model="editableContent"
                    class="flex-grow-1 html-editor"
                    :readonly="false"
                    @input="updatePreview"
                    :gridContext="gridContext"
                  />
                </div>
              </v-col>
              <v-col 
                :style="{
                  minHeight: 0,
                  height: editorLayout === 'horizontal' ? '50%' : '100%',
                  width: editorLayout === 'horizontal' ? '100%' : '50%'
                }"
                class="pa-4 d-flex flex-column" 
                :class="{ 'order-1': editorLayout === 'horizontal' }" 
              >
                <div class="d-flex align-center mb-2">
                  <span class="text-subtitle-2">{{ $t('blocks.htmlBlock.preview') }}</span>
                </div>
                <v-sheet
                  outlined
                  rounded="lg"
                  class="preview-container pa-4 flex-grow-1"
                  :class="{'preview-error': hasPreviewError}"
                >
                  <iframe
                    v-if="!hasPreviewError"
                    :srcdoc="previewHtml"
                    sandbox="allow-scripts"
                    class="html-preview-frame"
                    style="width: 100%; height: 100%; border: none;"
                  ></iframe>
                  <div v-else class="text-caption text-error">
                    {{ $t('blocks.htmlBlock.previewError') }}
                  </div>
                </v-sheet>
              </v-col>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4">
            <v-text-field
              v-model="description"
              :placeholder="$t('blocks.htmlBlock.description')"
              outlined
              dense
              hide-details
              class="flex-grow-1"
              :disabled="isGenerating"
              @keyup.enter="generateHtml"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  @click="generateHtml"
                  :loading="isGenerating"
                  :disabled="!description"
                  class="d-flex align-center justify-center"
                  style="margin: -6px"
                >
                  <v-icon class="ai-gradient-text">mdi-creation</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import MenuItem from '@/components/MenuItem.vue'
import CodeMirrorEditor from '@/components/CodeMirrorEditor.vue'
import blockProps from '@/mixins/blockProps'

export default {
  mixins: [blockProps],
  props: {
    sandboxInIframe: {
      type: Boolean,
      default: true
    },
    openEditorOnMount: {
      type: Boolean,
      default: false
    },
    gridContext: {
      type: Object,
      default: null
    },
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseBlock,
    MenuItem,
    CodeMirrorEditor
  },
  data() {
    return {
      showEditor: false,
      editableContent: '',
      description: '',
      isGenerating: false,
      previewContent: '',
      hasPreviewError: false,
      resizeObserver: null,
      editorLayout: 'horizontal',
      iframeId: `html-block-${Math.random().toString(36).substr(2, 9)}`,
      isSaving: false
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    content() {
      return this.block?.content
    },
    evaluatedContent() {
      return this.block?.evaluatedContent
    },
    renderedContent() {
      /* eslint-disable no-useless-escape */
      const content = this.evaluatedContent || ''
      if(!this.sandboxInIframe) {
        return content
      }

      // Add CSP meta tag
      const cspMeta = `
        <meta http-equiv="Content-Security-Policy" content="
          default-src 'none';
          script-src 'unsafe-inline' 'unsafe-eval' https:;
          style-src 'unsafe-inline' https:;
          img-src https: data:;
          font-src https:;
          connect-src https:;
          frame-src https:;
          base-uri 'none';
          form-action 'none';">
      `

      // Add a script to expose the fullScreen property
      const fullScreenScript = `
        <script>
          window.isFullScreen = ${this.fullScreen};
        <\/script>
      `

     

      // Check if content already contains html/body structure
      const hasHtmlStructure = /<html[^>]*>/.test(content)
      const hasBodyTag = /<body[^>]*>/.test(content)
      
      // If content already has HTML structure, parse and modify it properly
      if (hasHtmlStructure) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(content, 'text/html')
        
        // Add our script to head if it exists, or create head if it doesn't
        let head = doc.querySelector('head')
        if (!head) {
          head = doc.createElement('head')
          doc.documentElement.insertBefore(head, doc.documentElement.firstChild)
        }
        
        // Add meta charset if it doesn't exist
        if (!head.querySelector('meta[charset]')) {
          const meta = doc.createElement('meta')
          meta.setAttribute('charset', 'utf-8')
          head.insertBefore(meta, head.firstChild)
        }
        
        // Add CSP meta tag if it doesn't exist
        if (!head.querySelector('meta[http-equiv="Content-Security-Policy"]')) {
          head.insertAdjacentHTML('beforeend', cspMeta)
        }
        
        head.insertAdjacentHTML('beforeend', fullScreenScript)

        const finalHtml = doc.documentElement.outerHTML
        
        return `<iframe
          srcdoc="${finalHtml.replace(/"/g, '&quot;')}"
          sandbox="allow-scripts"
          class="html-content-frame ${this.fullScreen ? 'fullscreen-frame' : ''}"
          style="width: 100%; border: none;"
          data-iframe-id="${this.iframeId}"
        ></iframe>`.trim()
      }

      // If content has just a body tag, wrap it with html structure
      if (hasBodyTag) {
        const finalHtml = `<!DOCTYPE html><html><head><meta charset='utf-8'>${cspMeta}${fullScreenScript}</head>${content}</html>`
        return `<iframe
          srcdoc="${finalHtml.replace(/"/g, '&quot;')}"
          sandbox="allow-scripts"
          class="html-content-frame ${this.fullScreen ? 'fullscreen-frame' : ''}"
          style="width: 100%; border: none;"
          data-iframe-id="${this.iframeId}"
        ></iframe>`.trim()
      }

      // If content is just HTML fragments or empty, wrap it completely
      const finalHtml = `<!DOCTYPE html><html><head><meta charset='utf-8'>${cspMeta}${fullScreenScript}</head><body>${content || ''}</body></html>`
      
      return `<iframe
        srcdoc="${finalHtml.replace(/"/g, '&quot;')}"
        sandbox="allow-scripts"
        class="html-content-frame ${this.fullScreen ? 'fullscreen-frame' : ''}"
        style="width: 100%; border: none;"
        data-iframe-id="${this.iframeId}"
      ></iframe>`.trim()
      /* eslint-enable no-useless-escape */
    },
    previewHtml() {
      const sandboxHtml = `<!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
            <meta http-equiv="Content-Security-Policy" content="
              default-src 'none';
              script-src 'unsafe-inline' 'unsafe-eval' https:;
              style-src 'unsafe-inline' https:;
              img-src https: data:;
              font-src https:;
              connect-src https:;
              frame-src https:;
              base-uri 'none';
              form-action 'none';">
            <style>
              body { margin: 0; font-family: sans-serif; }
              * { max-width: 100%; }
            </style>
          </head>
          <body>
            ${this.editableContent || ''}
          </body>
        </html>`.trim()

      return sandboxHtml
    }
  },
  watch: {
    showEditor(val) {
      if (val) {
        this.editableContent = this.content
        this.description = ''
        this.updatePreview()
      }
    }
  },
  mounted() {
    if (this.openEditorOnMount) {
      this.$nextTick(() => {
        this.showEditor = true
      })
    }
  },
  methods: {
    updatePreview() {
      try {
        // Basic validation of HTML and JavaScript
        const parser = new DOMParser()
        const doc = parser.parseFromString(this.editableContent, 'text/html')
        // Check for parser errors
        if (doc.querySelector('parsererror')) {
          this.hasPreviewError = true
          return
        }

        // Validate any JavaScript in script tags
        const scripts = doc.getElementsByTagName('script')
        for (const script of scripts) {
          // Basic JavaScript syntax validation
          new Function(script.textContent)
        }
        
        this.hasPreviewError = false
      } catch (error) {
        this.hasPreviewError = true
      }
    },
    async generateHtml() {
      if (!this.description) return

      this.isGenerating = true
      try {
        const response = await fetch('https://irpxjumiq7.execute-api.eu-central-1.amazonaws.com/completion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'client_fingerprint': 'writing-tools-0_1_3f5tg32sed56tzg78ijk'
          },
          body: JSON.stringify({
            model: 'gpt-4o',
            messages: [
              {
                role: 'system',
                content: 'You are an expert HTML developer. Create or modify HTML code based on the user\'s description. Include appropriate CSS classes for styling. Reply only with the complete HTML code, no explanations. Do not wrap the HTML in markdown code blocks.'
              },
              ...(this.editableContent ? [{
                role: 'user',
                content: `Here is my current HTML:\n${this.editableContent}`
              }] : []),
              {
                role: 'user',
                content: this.editableContent 
                  ? `Modify the HTML above according to this request: ${this.description}`
                  : this.description
              }
            ]
          })
        })

        if (!response.ok) {
          throw new Error('Failed to generate HTML')
        }

        const data = await response.json()
        let content = data.choices[0].message.content
        // Remove markdown code block markers if present
        content = content.replace(/^```html\n?/, '').replace(/\n?```$/, '')
        this.editableContent = content
        this.updatePreview()
      } catch (error) {
        this.$store.dispatch('showError', this.$t('blocks.htmlBlock.generationError'))
      } finally {
        this.isGenerating = false
      }
    },
    cancelEdit() {
      this.showEditor = false
      this.$emit('editorClosed')
    },
    async saveContent() {
      this.isSaving = true
      try {
        await this.block.updateContent(this.editableContent)
        this.showEditor = false
        this.$emit('editorClosed')
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>

<style scoped>
.html-content {
  width: 100%;
  min-height: 50px;
}

.fullscreen-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 48px); /* Account for toolbar height */
}

.fullscreen-content :deep(.html-content-frame) {
  flex: 1;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  overflow: auto !important;
}

.fullscreen-frame {
  min-height: 100% !important;
  height: 100% !important;
  overflow: auto !important;
}

/* Ensure content is scrollable in fullscreen mode */
:deep(.1fullscreen-frame) html,
:deep(.1fullscreen-frame) body {
  height: auto !important;
  overflow: visible !important;
}

.html-content :deep(*) {
  max-width: 100%;
  border: none;
}

.preview-container {
  position: relative;
  height: 100%;
  background-color: white;
  display: flex;
  border-color: #75746F;
}

.preview-container iframe {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
}

.preview-content {
  height: 100%;
}

.preview-error {
  background-color: #fff5f5;
}

.font-monospace {
  font-family: monospace !important;
}

.html-empty {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 4px;
  width: 100%;
}

.html-editor {
  height: 100%;
  display: flex;
  overflow: hidden;
}

/* Remove v-textarea specific styles since we're using CodeMirror now */
.html-editor :deep(.cm-editor) {
  height: 100%;
  font-size: 14px;
  background-color: white;
}

.html-editor :deep(.cm-scroller) {
  overflow: auto;
}

.ai-gradient-text {
  background: linear-gradient(90deg, #dd00ff 0%, #00c3ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
}

.v-btn:disabled .ai-gradient-text {
  background: #e0e0e0;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
}

.shortcut-hint {
  font-size: 0.9rem;
  color: #666;
}

.shortcut-hint kbd {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 0.9em;
  font-family: inherit;
  color: #333;
  font-weight: 500;
  margin: 0 2px;
}
</style> 