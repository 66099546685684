import ApiClient from '@/ApiClient'

export default {
  data() {
    return {
      items: [],
      loading: false,
      filteredTypes: ['flowInstance']
    }
  },
  computed: {
    resourceLink() {
      return this.field?._links.resources?.href
    }
  },
  methods: {
    async loadResources() {
      if (!this.resourceLink) return
      this.loading = true
      try {
        const response = await ApiClient.getUri(this.resourceLink)
        const allItems = response.data.items
        const itemsByType = {}
        for (let item of allItems) {
          if (itemsByType[item.metaType] != null) {
            itemsByType[item.metaType].push(item)
          } else {
            itemsByType[item.metaType] = [item]
          }
        }
        var itemsWithHeaders = []
        for (let metaType of Object.keys(itemsByType)) {
          itemsWithHeaders.push({header: this.$t(`resourceTypes.${metaType}`)})
          for (let item of itemsByType[metaType]) {
            itemsWithHeaders.push(item)
          }
        }
        itemsWithHeaders = itemsWithHeaders.filter(item => {
          if (item.type) {
            return !this.filteredTypes.includes(item.type)
          }
          return true // Include headers regardless of filtering
        })
        this.items = itemsWithHeaders
        return itemsWithHeaders
      } finally {
        this.loading = false
      }
    }
  }
}