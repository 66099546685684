<template>
  <div
    ref="contenteditable"
    :contenteditable="contenteditable"
    class="paragraph-text-field"
    :placeholder="placeholder"
    @blur="onBlur"
    @keydown.enter.prevent="enterKeydown"
    @keydown.backspace="deleteBlock"
    @input="onInput"
  ></div>
</template>

<script>
import externalModel from '@/mixins/externalModel'

export default {
  name: 'ParagraphTextField',
  mixins: [externalModel],
  props: {
    placeholder: {
      type: String,
      required: true
    },
    editorMode: {
      type: Boolean,
      required: true,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    contenteditable() {
      return this.editorMode ? 'plaintext-only' : 'false'
    }
  },
  watch: {
    externalModel: {
      immediate: true,
      async handler(newVal) {
        if(newVal !== this.getContent()) {  
          await this.$nextTick()
          this.setContent(newVal)
        }
      }
    },
    autofocus: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.$nextTick()
          this.focus()
        }
      }
    }
  },
  methods: {
    focus() {
      const el = this.$refs.contenteditable
      const selection = window.getSelection()
      const range = document.createRange()
      if(range && el) {
        selection.removeAllRanges()
        range.selectNodeContents(el)
        range.collapse(false)
        selection.addRange(range)
        el.focus()
      }
    },
    getCursorPosition() {
      let position = 0
      const selection = window.getSelection()
      
      if (selection.rangeCount !== 0) {
        const range = selection.getRangeAt(0)
        const preCaretRange = range.cloneRange()
        preCaretRange.selectNodeContents(this.$refs.contenteditable)
        preCaretRange.setEnd(range.endContainer, range.endOffset)
        position = preCaretRange.toString().length
      }
      return position
    },
    getContent() {
      return this.$refs.contenteditable?.textContent
    },
    setContent(text) {
      if (this.$refs.contenteditable) {
        if (!text) {
          this.$refs.contenteditable.innerHTML = ''
        } else {
          this.$refs.contenteditable.textContent = text
        }
      }
    },
    onBlur() {
      this.$emit('input', this.getContent())
      this.$emit('onBlur')
    },
    enterKeydown(event) {
      if (!this.externalModel?.trim()) return

      if (event.shiftKey) {
        const selection = window.getSelection()
        const range = selection.getRangeAt(0)
        const text = this.getContent()
        const position = this.getCursorPosition()
        
        // Check for consecutive newlines
        const beforeCursor = text.slice(0, position)
        const afterCursor = text.slice(position)
        if (beforeCursor.endsWith('\n') || afterCursor.startsWith('\n')) {
          return
        }
        
        range.deleteContents()
        range.insertNode(document.createTextNode('\n'))
        range.collapse(false)
        return
      }

      const position = this.getCursorPosition()
      const text = this.getContent()

      if (position === text.length) {
        this.$emit('appendEmptyParagraph')
      } else {
        this.$emit('appendParagraphWithText', text.slice(position))
        this.$emit('input', text.slice(0, position))
      }
    },
    deleteBlock() {
      if (!this.getContent() || this.getContent().trim() === '') {
        this.$emit('delete')
      } else {
        const position = this.getCursorPosition()
        if (position === 0) {
          this.$emit('mergeParagraphs')
        }
      }
    },
    onInput() {
      const content = this.getContent()
      // Open block picker only if content is exactly a space (or non-breaking space)
      if (content.replace(/\u00A0/g, ' ').trim() === '') {
        this.$emit('openBlockPicker')
        this.setContent('')
        this.$emit('input', '')
      }
      // Handle empty content
      else if (!content || content.trim() === '') {
        this.setContent('')
        this.$emit('input', '')
      }
      else {
        this.$emit('input', content)
      }
    }
  },
  emits: ['onBlur', 'appendEmptyParagraph', 'appendParagraphWithText', 'delete', 'mergeParagraphs', 'openBlockPicker']
}
</script>

<style lang="scss" scoped>
.paragraph-text-field {
  outline: unset;
  width: 100%;
  overflow: hidden;
  height: fit-content;
  white-space: pre-line;
}

[contenteditable=plaintext-only]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  color: lightgray;
  display: block; /* For Firefox */
}
</style> 