<template>
  <div style="display: none">
    <canvas ref="iconCanvas" width="512" height="512"></canvas>
    <div ref="iconWrapper" class="icon-wrapper">
      <v-icon ref="mdiIcon" :size="512" color="white">mdi-{{ mdiIcon }}</v-icon>
    </div>
    <img v-if="iconset === 'file'" ref="fileIcon" :src="mdiIcon" crossorigin="anonymous" style="display: none;" />
  </div>
</template>

<script>
export default {
  name: 'PWAIconGenerator',
  props: {
    title: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: '#1565C0'
    },
    mdiIcon: {
      type: String,
      default: ''
    },
    iconset: {
      type: String,
      default: 'mdi'
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(newVal) {
        this.$nextTick(() => {
          this.generateIcon(newVal)
        })
      }
    },
    mdiIcon: {
      handler() {
        this.$nextTick(() => {
          this.generateIcon(this.title)
        })
      }
    }
  },
  mounted() {
    this.addPWAMetaTags()
  },
  methods: {
    async generateIcon(text) {
      const canvas = this.$refs.iconCanvas
      if (!canvas) return

      const ctx = canvas.getContext('2d')
      const size = canvas.width

      // Clear canvas
      ctx.clearRect(0, 0, size, size)

      // Draw background
      ctx.fillStyle = this.iconColor
      ctx.fillRect(0, 0, size, size)

      if (this.iconset === 'file' && this.mdiIcon) {
        // Handle file icon (image)
        try {
          if (!this.$refs.fileIcon) {
            // If the file icon reference isn't available yet, try again shortly
            setTimeout(() => this.generateIcon(text), 100)
            return
          }
          
          // Create a new Image object for better handling of load events
          const img = new Image()
          img.crossOrigin = 'anonymous'
          
          // Wait for the image to load before drawing it
          await new Promise((resolve, reject) => {
            img.onload = resolve
            img.onerror = reject
            img.src = this.mdiIcon
          })
          
          // Draw the image centered in the canvas with padding
          const padding = size * 0 // 20% padding
          const drawSize = size - (padding * 2)
          ctx.drawImage(img, padding, padding, drawSize, drawSize)
        } catch (error) {
          console.error('Error loading file icon:', error)
          // Fallback to text if image fails to load
          this.drawTextFallback(ctx, text, size)
        }
      } else if (this.iconset === 'emoji' && this.mdiIcon) {
        // Draw emoji
        ctx.font = '384px sans-serif'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillStyle = 'white'
        ctx.fillText(this.mdiIcon, size/2, size/2)
      } else if (this.mdiIcon) {
         // If MDI icon is provided, wait for font to load first
         await this.$nextTick()
        await document.fonts.ready // Wait for all fonts to load
        
        const iconEl = this.$refs.mdiIcon.$el
        
        // Function to check if MDI font is available
        const isMDIFontLoaded = () => {
          const testString = 'abc' // Any test string
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          ctx.font = '16px "Material Design Icons"'
          return ctx.measureText(testString).width !== ctx.measureText(testString).width
        }
        
        // Wait for MDI font with retries
        let retries = 0
        while (!isMDIFontLoaded() && retries < 5) {
          await new Promise(resolve => setTimeout(resolve, 100))
          retries++
        }
        
        // Get the actual icon content from the element
        const beforeContent = window.getComputedStyle(iconEl, ':before').content
        const iconContent = beforeContent.replace(/['"]/g, '')
        
        ctx.fillStyle = 'white'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        
        // Draw the icon character using MDI font
        const iconSize = size * 0.6 // Scale icon to 60% of canvas
        ctx.font = `${iconSize}px "Material Design Icons"`
        ctx.fillText(iconContent, size/2, size/2)

      } else {
        this.drawTextFallback(ctx, text, size)
      }

      // Convert to data URL
      const iconUrl = canvas.toDataURL('image/png')
      // Update PWA meta tags with the new icon
      this.updatePWAIcons(iconUrl)
    },
    drawTextFallback(ctx, text, size) {
      // Get first letter or first two letters of the title
      const iconText = text ? (text.length > 1 ? text.substring(0, 2) : text[0]) : 'P'
      // Adjust font size based on text length
      const fontSize = iconText.length > 1 ? size/3 : size/2
      ctx.font = `bold ${fontSize}px Arial`
      ctx.fillStyle = 'white'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(iconText.toUpperCase(), size/2, size/2)
    },
    updatePWAIcons(iconUrl) {
      // Remove existing icon links
      const existingIcons = document.querySelectorAll('link[rel*="icon"], link[rel*="apple-touch-icon"]')
      existingIcons.forEach(icon => icon.remove())

      // Add new icon links
      const iconSizes = ['192x192', '512x512']
      iconSizes.forEach(size => {
        const link = document.createElement('link')
        link.rel = 'apple-touch-icon'
        link.sizes = size
        link.href = iconUrl
        document.head.appendChild(link)

        const pwaLink = document.createElement('link')
        pwaLink.rel = 'icon'
        pwaLink.sizes = size
        pwaLink.href = iconUrl
        document.head.appendChild(pwaLink)
      })
    },
    addPWAMetaTags() {
      const metaTags = [
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-title', content: this.title || 'Page' },
        { name: 'application-name', content: this.title || 'Page' }
      ]
      
      // Remove existing meta tags
      metaTags.forEach(tag => {
        const existing = document.querySelector(`meta[name="${tag.name}"]`)
        if (existing) {
          existing.remove()
        }
      })

      // Add new meta tags
      metaTags.forEach(tag => {
        const meta = document.createElement('meta')
        meta.name = tag.name
        meta.content = tag.content
        document.head.appendChild(meta)
      })

      // Generate initial icon
      this.generateIcon(this.title)
    }
  }
}
</script>

<style>
.icon-wrapper {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style> 