import ApiClient from '@/ApiClient'

const paywallDowngradPlugin = store => {
  store.subscribeAction({
    after: (action) => {
      if (action.type === 'AGReadFormOperation') {
        // remove some paid features if space is on start plan 
        try {
          const form = store.getters.formWithUri(action.payload)
          const grid = store.getters.gridContainingFormUri(form.uri)
          const space = store.getters.spaceIncludesGridWithUri(grid.uri)
          if( space.plan == 'start' ) {
            //reset some form feature 
            form.downgrade()
          } 
        } catch (error) {
          console.log(error)
        }
      }
    }
  })
}

const paywallModule = {
  state() {
    return {
      featureLimits: undefined
    }
  },
  mutations: {
    setFeatureLimits(state, featureLimits) {
      state.featureLimits = featureLimits
    }
  },
  actions: {
    async AGGetFeatureLimits(context, spaceUri) {
      
      // we transport the availableSeats in the space object
      // so we need to fetch the space to update the availableSeats 
      let fetchAvailableSeats = async () => {
        const response = await ApiClient.getUri(spaceUri)
        return response.data?.availableSeats
      }
      // const response = await ApiClient.getUri('/api/featureLimits')
      const response = await Promise.resolve({
        data: {
          start: {
            webhooksPerSpace: 2,
            flows: 1,
            saveFormProgress: 0,
            formSuccessRedirect: 0,
            autoSubmitForms: 0,
            formI18n: 0,
            formHideAd: 0,
            formReadOnlyFields: 0,
            formHiddenFields: 0,
            formDefaultValues: 0,
            writersPerSpace: async () => await fetchAvailableSeats(),
            entitiesPerGrid: 500,
            spaces: 'unlimited',
            downloadQRCodeSVG: 0,
            dontShowUpgradeBanner: 0,
            formsPerSpace: 2,
            logoOnForms: 0,
            spaceColors: 0,
            addExternalLinks: 1,
            proNodes: 0
          },
          plus: {
            webhooksPerSpace: 5,
            flows: 5,
            saveFormProgress: 1,
            formSuccessRedirect: 1,
            autoSubmitForms: 0,
            formI18n: 0,
            formHideAd: 1,
            formReadOnlyFields: 1,
            formHiddenFields: 1,
            formDefaultValues: 1,
            writersPerSpace: async () => await fetchAvailableSeats(),
            entitiesPerGrid: 5000,
            spaces: 'unlimited',
            downloadQRCodeSVG: 1,
            dontShowUpgradeBanner: 1,
            formsPerSpace: 'unlimited',
            logoOnForms: 0,
            spaceColors: 1,
            addExternalLinks: 1,
            proNodes: 0
          },
          pro: {
            webhooksPerSpace: 10,
            flows: 'unlimited',
            saveFormProgress: 1,
            formSuccessRedirect: 1,
            autoSubmitForms: 1,
            formI18n: 1,
            formHideAd: 1,
            formReadOnlyFields: 1,
            formHiddenFields: 1,
            formDefaultValues: 1,
            writersPerSpace: async () => await fetchAvailableSeats(),
            entitiesPerGrid: 50000,
            spaces: 'unlimited',
            downloadQRCodeSVG: 1,
            dontShowUpgradeBanner: 1,
            formsPerSpace: 'unlimited',
            logoOnForms: 1,
            spaceColors: 1,
            addExternalLinks: 'unlimited',
            proNodes: 1
          },
        }
      })
      context.commit('setFeatureLimits', response.data)
      return response.data
    }
  }
}

export { paywallModule, paywallDowngradPlugin }