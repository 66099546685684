var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseBlock',_vm._g({attrs:{"blockUri":_vm.blockUri,"editorMode":_vm.editorMode,"grabbableClass":_vm.grabbableClass},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('v-list',[_c('MenuItem',{attrs:{"icon":"mdi-link","text":_vm.$t('blocks.externalHookButtonBlock.menuButton')},on:{"click":_vm.openMenu}}),_c('v-list-item',[_c('v-list-item-content',{staticClass:"accent--text"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("mdi-format-color-fill")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('blocks.backgroundColor')))]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"ml-2 rounded elevation-2",style:({
                    width: '22px !important',
                    height: '22px !important',
                    backgroundColor: _vm.backgroundColor,
                    cursor: 'pointer'
                  })},'div',attrs,false),on))]}}]),model:{value:(_vm.showBackgroundColorPicker),callback:function ($$v) {_vm.showBackgroundColorPicker=$$v},expression:"showBackgroundColorPicker"}},[_c('v-card',[_c('v-color-picker',{attrs:{"mode":"hexa","show-swatches":""},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}})],1)],1)],1)])],1),_c('v-list-item',[_c('v-list-item-content',{staticClass:"accent--text"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("mdi-format-color-text")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('blocks.textColor')))]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"ml-2 rounded elevation-2",style:({
                    width: '22px !important',
                    height: '22px !important',
                    backgroundColor: _vm.color,
                    cursor: 'pointer'
                  })},'div',attrs,false),on))]}}]),model:{value:(_vm.showTextColorPicker),callback:function ($$v) {_vm.showTextColorPicker=$$v},expression:"showTextColorPicker"}},[_c('v-card',[_c('v-color-picker',{attrs:{"mode":"hexa","show-swatches":""},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1)],1)])],1)],1)]},proxy:true}])},_vm.$listeners),[(!_vm.resource)?_c('div',{staticClass:"resource-empty d-flex flex-row gap-3 pa-3",on:{"click":_vm.openMenu}},[_c('v-icon',{attrs:{"color":"#75746F"}},[_vm._v("mdi-button-cursor")]),_c('div',[_vm._v(_vm._s(_vm.$t('blockTypes.externalHookButtonBlock.subtitle')))])],1):_c('div',[_c('v-btn',{style:({
        color: _vm.color
      }),attrs:{"rounded":"","color":_vm.backgroundColor},on:{"click":_vm.buttonClicked}},[_vm._v(" "+_vm._s(_vm.resource.name)+" ")])],1),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.resource)?_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(this.block.resourceBlock.resource.name))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0 fill-height"},[(_vm.showDialog)?_c('iframe',{ref:"iframe",staticClass:"iframe-content",attrs:{"src":_vm.iframeUrl,"frameborder":"0","onmousewheel":"","width":"100%","height":"100%","allow":"microphone; camera"}}):_vm._e()])],1):_vm._e()],1),_c('v-menu',{attrs:{"close-on-content-click":false,"position-x":_vm.menuX,"position-y":_vm.menuY,"min-width":"320px"},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('blocks.externalHookButtonBlock.menuTitle')))]),_c('v-card-text',[_c('TemplateResourceInput',_vm._g(_vm._b({ref:"resourcePicker",attrs:{"placeholder":_vm.$t('blocks.externalHookButtonBlock.inputLabel'),"block":_vm.block,"loadItems":_vm.loadResources},model:{value:(_vm.resource),callback:function ($$v) {_vm.resource=$$v},expression:"resource"}},'TemplateResourceInput',_vm.$attrs,false),_vm.$listeners))],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }