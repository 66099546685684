<template>
  <v-list-item-title class="d-flex align-center gap-2">
    <template v-if="isExternalHook && clickable">
      <a 
        :href="resource.redirectUri" 
        target="_blank" 
        rel="noopener noreferrer"
        class="d-flex align-center gap-2 external-link"
      >
        <ViewIcon :type="viewIconType" />
        <span class="link-text">{{ computedDisplayText }}</span>
        <v-icon
          size="small"
          class="share-icon"
          icon="mdi-open-in-new"
        />
      </a>
    </template>
    <template v-else>
      <ViewIcon :type="viewIconType" />
      <span>{{ computedDisplayText }}</span>
    </template>
    <slot></slot>
  </v-list-item-title>
</template>

<script>
import ViewIcon from '@/components/ViewIcon.vue'
import { ViewIconTypes } from '@/constants/viewIconTypes'
import { nodeTypeFor } from '@/constants/nodeTypes'
import { columnTypes } from '@/constants/columnTypes'

export default {
  name: 'ResourceItemDisplay',
  components: {
    ViewIcon
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    displayText: {
      type: String,
      default: null
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedDisplayText() {
      return this.displayText || columnTypes.resource.displayFormat(this.resource)
    },
    viewIconType() {
      const iconMap = {
        // Grid view types
        spreadsheet: ViewIconTypes.GRID,
        kanban: ViewIconTypes.KANBAN,
        calendar: ViewIconTypes.CALENDAR,
        map: ViewIconTypes.MAP,
        gallery: ViewIconTypes.GALLERY,
        list: ViewIconTypes.LIST,
        
        // Other resource types
        form: ViewIconTypes.FORM,
        externalFlowTrigger: ViewIconTypes.FLOW_FORM,
        addEntity: ViewIconTypes.FORM,
        view: ViewIconTypes.GRID,
        grid: ViewIconTypes.GRID,
        block: ViewIconTypes.BLOCK,
      }

      // Handle flowNode type resources
      if (this.resource.metaType === 'flowNode') {
        return nodeTypeFor(this.resource.type) ?? ViewIconTypes.UNKNOWN
      }

      // For all other resources, check type first, then metaType if available
      return iconMap[this.resource.type] ?? 
             (this.resource.metaType && iconMap[this.resource.metaType]) ?? 
             ViewIconTypes.UNKNOWN
    },
    isExternalHook() {
      return this.resource.metaType === 'externalHook'
    }
  }
}
</script> 

<style scoped>
.external-link {
  color: rgb(var(--v-theme-accent));
  text-decoration: none;
}

.external-link .link-text {
  text-decoration: none;
}

.external-link:hover .link-text {
  text-decoration: underline;
}

.external-link:hover {
  opacity: 0.8;
}
</style> 