<template>
  <div class="formContainer" :style="styles">
    <v-container class="pa-0" align="center" fluid ref="form">
        <FullHeightLoader v-if="loading" :value="loading" :style="{height: '100vh', width: '100vw'}"/>
        <FormView class="mb-14" v-if="!loading && form" :form="form" :standalone="standalone" @reload="loadForm" 
          v-on:didSubmit="didSubmit" 
          v-on:pageChanged="postMessage"
          v-on:submitActionTriggered="submitActionTriggered"  />
    </v-container>
    <LoginDialog
      @credentialsProvided="loadForm"
      v-model="loginDialog"
      :resource="formUri"
    />
  </div>
</template>

<script>
import FormView from '../components/form/FormView.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import ApiClient from '@/ApiClient'
import errorBus from '@/utils/errorBus.js'
import LoginDialog from '@/components/LoginDialog.vue'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus'

export default {
  name: 'ExternalForm',
  props: {
    uri: null
  },
  data() {
    return {
      loading: false,
      eventListenter: null,
      loginDialog: false,
      user: null
    }
  },
  watch: {
    form: {
      async handler(newVal) {
        if (newVal) {
          const metaDescription = document.querySelector('meta[name="theme-color"]')
          if( metaDescription ) {
            metaDescription.setAttribute('content', this.form?.headerColor)
          }
          // load user
          if (ApiClient.bearerAuthSet()) {
            await this.$store.dispatch('loadUser')
          }
        }
      },
    }
  },
  mounted() {
    linkAuthorizationNeededBus.onLinkAuthorizationNeeded(this.onLinkAuthorizationNeeded)
    this.loadForm()
    this.addListener()
    if (this.$route.query) {
      try {
        let token = this.$route.query.authtoken
        if(token){
          ApiClient.setAuth(token)
        }
      } catch(error) {
        console.error('form prefill with query token failed')
        console.error(error)
      }  
    }
  },
  beforeDestroy() {
    linkAuthorizationNeededBus.linkAuthorizationNeededOff(this.onLinkAuthorizationNeeded)
    this.removeListener()
  },
  updated() {
    this.postMessage()
  },
  computed: {
    formUri() {
      return this.uri || this.$route.query.uri
    },
    form() {
      return this.$store.state.form
    },
    standalone() {
      return window.navigator.standalone
    },
    backgroundColor() {
      return this.form?.backgroundColor || '#f7f7f7'
    },
    styles() {
      return {
        backgroundColor: this.backgroundColor
      }
    },
  },
  methods: {
    async loadForm() {
      this.loading = true
      try {
        await this.$store.dispatch( 'loadForm', {
           formUri: this.formUri,
           customErrorHandling: [403]
        })
      } catch (error) {
        // in case of 
        if (error.response.status === 403) {
          errorBus.$emit('ERROR', {
            type: 'formNotAccessable',
            okHandler: () => { this.loadForm() }
          })
        }
        this.$apptiveErrorReporter.captureException(error)

      } finally {
        this.loading = false
      }
      // set meta header to allow standalone mode on mobile homescreen 
      const mobileWebCapable = document.querySelector('meta[name="apple-mobile-web-app-capable"]')
      if( mobileWebCapable ) {
        mobileWebCapable.setAttribute('content', 'yes')
      }
      // set form name as App name
      const mobileWebTitle = document.querySelector('meta[name="apple-mobile-web-app-title"]')
      if( mobileWebTitle ) {
        mobileWebTitle.setAttribute('content', this.form?.name)
      }
    },
    onLinkAuthorizationNeeded() {
      this.loginDialog = true
    },
    addListener() {
      // listen to method apptiveLoaded, send from an apptive optionally after an apptive is loaded
      this.eventListenter = (event) => {
        var message = event.data
        if (message && message.key === 'apptiveDisableScrollbar') {
          document.getElementsByTagName('html')[0].style.overflow = 'hidden'
        }
      }
      window.addEventListener('message', this.eventListenter)
    },
    removeListener() {
      window.removeEventListener('message', this.eventListenter)
    },
    postMessage() {
      // trigger resizing of the iframe. that is done in apptive-embedded.js
      setTimeout( () => {
        window.parent.postMessage({
        key: 'apptiveEmbedContentDidResize',
        height: this.$refs.form.scrollHeight
      }, '*')
      }, 100)
    },
    didSubmit() {
      window.parent.postMessage({
        key: 'apptiveEmbedDidSubmitForm'
      }, '*')
    },
    submitActionTriggered(){
      window.parent.postMessage({
        key: 'apptiveEmbedSubmitActionTriggered'
      }, '*')
    }
  },
  components: {
    FormView,
    FullHeightLoader,
    LoginDialog
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.formContainer {
  background-color: #f7f7f7;
  min-height: 100%;
}
.hideScollBars {
  overflow: hidden;
}
</style>
