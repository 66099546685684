import FormInputBlock from '@/store/models/blocks/FormInputBlock'
import store from '@/store/store'

export default class ImageBlock extends FormInputBlock {
  constructor(data) {
    super(data)
    this.image = data.image || {}
    this.isDirty = false
  }

  validate() {
    return this.imageUrl && this.imageUrl.trim().length > 0
  }

  get imageUrl() {
    return this.image.url
  }

  get width() {
    return this.image.width || ''
  }

  get height() {
    return this.image.height || ''
  }

  setImageUrl(newVal) {
    if (newVal === this.image.url) {
      return
    }
    this.image.url = newVal
    this.isDirty = true
  }

  setWidth(newVal) {
    if (newVal === this.image.width) {
      return
    }
    this.image.width = newVal
    this.isDirty = true
  }

  setHeight(newVal) {
    if (newVal === this.image.height) {
      return
    }
    this.image.height = newVal
    this.isDirty = true
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        image: {
          url: this.imageUrl,
          width: this.width,
          height: this.height
        }
      }
    })
    this.isDirty = false
  }
} 