<template>
  <div class="d-flex align-center" :style="style">
    <ShareSpaceDialog
      :disabled="!canAddShare"
      v-model="shareDialog"
      :spaceUri="space.uri"
      :spaceName="space.name"
    />
    <v-avatar
      v-if="canAddShare && !$vuetify.breakpoint.mobile"
      :size="AVATAR_SIZE"
      class="mr-3"
      @click="openShareDialog"
      style="cursor: pointer;"
    >
      <v-icon dense>mdi-account-plus-outline</v-icon>
    </v-avatar>
    <v-menu v-model="menu" offset-y bottom nudge-bottom="8px">
      <template v-slot:activator="{on, attrs}">
        <div class="d-flex" v-on="on" v-bind="attrs">
          <div
            v-if="users.length > shownUsersCount + 1"
            class="overlapping-avatar more-indicator"
          >
            {{ `+${users.length - shownUsersCount - 1 }` }}
          </div>
          <Avatar
            v-for="(user, i) in shownUsers"
            class="overlapping-avatar"
            :size="AVATAR_SIZE"
            :key="i"
            :userId="user.id"
            :initials="user.email"
          />
        </div>
      </template>
      
      <v-card>
        <v-list dense two-line>
          <v-list-item
            v-for="user in users"
            :key="user.id"
          >
          <Avatar
            class="mr-3"
            :size="MENU_AVATAR_SIZE"
            :userId="user.id"
            :initials="user.email"
          />
          <v-list-item-content>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.role }}</v-list-item-subtitle>
          </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Avatar from '@/components/user/Avatar.vue'
import ShareSpaceDialog from '@/components/space/ShareSpaceDialog.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import { darkColor } from '../utils/color'

const SHOWN_USERS_COUNT_MOBILE = 0
const SHOWN_USERS_COUNT_DESKTOP = 2
const AVATAR_SIZE = 32
const MENU_AVATAR_SIZE = 32

export default {
  props: {
    space: null
  },
  data() {
    return {
      AVATAR_SIZE,
      MENU_AVATAR_SIZE,
      menu: false,
      shareDialog: false
    }
  },
  watch: {
    'space.uri': {
      immediate: true,
      handler(newVal) {
        if(this.canReadShares){
          this.$store.dispatch('loadShareUris', newVal)
        }
      }
    }
  },
  computed: {
    canAddShare() {
      return hasPermission(this.space, [PERMISSIONS.addShare])
    },
    canReadShares() {
      return hasPermission(this.space, [PERMISSIONS.shares])
    },
    color() {
      return this.space?.color ?? 'space'
    },
    borderColor() {
      return darkColor(this.color) ? 'rgba(0, 0, 0, 0.54)' : 'white'
    },
    style() {
      return {
        '--border-color': this.borderColor
      }
    },
    spaceOwner() {
      return {
        id: this.space.owner.id,
        email: this.space.owner.email,
        role: this.$t('roles.owner'),
      }
    },
    currentUser() {
      return {
        id: this.$store.getters.userId,
        email: this.$store.getters.userMail,
      }
    },
    collaborators() {
      const shares = this.$store.getters.fullSharesOfSpace(this.space.uri) || []
      const collaborators = []
      shares.forEach(share => {
        for (let i = 0; i < share.users.length; i++) {
          collaborators.push({
            id: share.users[i],
            email: share.emails[i],
            role: this.$te(`roles.${share.role}`) ? this.$t(`roles.${share.role}`) : share.role
          })
        }
      })
      return collaborators
    },
    users() {
      const users = [this.spaceOwner]
      this.collaborators
        .forEach(user => users.push(user))
      return users
    },
    shownUsers() {
      const otherUsers = this.users
        .filter(user => user.id !== this.currentUser.id)
        .slice(0, this.shownUsersCount)
      otherUsers.reverse()
      return otherUsers
    },
    shownUsersCount() {
      return this.$vuetify.breakpoint.mobile ? SHOWN_USERS_COUNT_MOBILE : SHOWN_USERS_COUNT_DESKTOP
    }
  },
  methods: {
    openShareDialog() {
      this.shareDialog = true
    }
  },
  components: {
    Avatar,
    ShareSpaceDialog
  }
}
</script>

<style scoped>

.overlapping-avatar {
  margin-left: -6px;
  color: var(--border-color);
}

.more-indicator {
  overflow: hidden;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-color) solid 1px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
</style>