<template>
  <IconPicker
    v-model="iconConfig"
    @input="handleIconChange"
  />
</template>

<script>
import IconPicker from '../common/IconPicker.vue'

export default {
  props: {
    spaceUri: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      space: undefined,
      realSpace: undefined,
      loading: false,
      iconConfig: {
        iconset: 'mdi',
        value: 'table',
        color: '#1664c0'
      }
    }
  },
  watch: {
    active: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) {
          return
        }
        this.loading = true
        try {
          this.space = await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: this.spaceUri,
            cached: true
          })
          if (this.realSpaceUri) {
            this.realSpace = await this.$store.dispatch('AGReadSpaceOperation', {
              spaceUri: this.realSpaceUri,
              cached: true
            })
          }
          this.iconConfig = {
            iconset: 'mdi',
            value: this.space.icon || 'table',
            color: this.space.color || '#1664c0'
          }
        } finally {
          this.loading = false
        }
      }
    }
  },
  methods: {
    handleIconChange(newConfig) {
      if (newConfig.value !== this.space.icon) {
        this.$store.dispatch('AGSetSpaceIcon', {
          spaceUri: this.space.uri, 
          icon: newConfig.value
        })
      }
      if (newConfig.color !== this.space.color) {
        this.$store.dispatch('AGSetSpaceColor', {
          spaceUri: this.space.uri, 
          color: newConfig.color,
          push: true
        })
      }
    }
  },
  components: {
    IconPicker
  }
}
</script>