<template>
  <v-dialog
    v-model="externalModel"
    :max-width="'90%'"
    scrollable
  >
    <v-card height="90vh">
      <v-card-title>
        <v-toolbar-title class="mr-2">
         
        <EditableText
            v-if="block"
            :readonly="!canRenameBlock"
            class="textOverflow pa-1 font-weight-medium title"
            :text="block.name"
            @submit="val => patchName(block, val)"
            :maxLength="$apptive.constants.nameLengths.grid"
            :showPencil="canRenameBlock"
          />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <PageBlockMenu
            v-if="canRenameBlock"
            :blockUri="block.uri"
            class="ml-2"
            :showDelete="false"
          />
        <v-btn text  @click="externalModel = false">
          {{ $t('dialogs.doneButton') }}
        </v-btn>
      </v-card-title>
      <BlockPage
          :blockUri="blockUri"
          editorMode
        />
    </v-card>
  </v-dialog>
</template>

<script>
import BlockPage from '@/components/block/BlockPage.vue'
import externalModel from '@/mixins/externalModel'
import EditableText from '@/components/EditableText.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import PageBlockMenu from '@/components/block/PageBlockMenu.vue'

export default {
  mixins: [ externalModel ],
  props: {
    blockUri: null,
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    canRenameBlock() {
      return hasPermission(this.block, [ PERMISSIONS.patch ])
    }
  },
  methods: {
    async patchName(block, newName) {
      await block.patchName(newName)
      block.name = newName
    },
  },
  components: {
    BlockPage,
    EditableText,
    PageBlockMenu
  }
}
</script>

<style
  lang="scss"
  scoped
></style>