<template functional>
        <img
            v-if="$options.thumbnailImage(props)"
            :src="$options.thumbnailImage(props)"
            :class="$options.classesFromData(data) + ' ' + $options.thumbnailClass(props)"
            :style="data.style"
            v-on="data.on"
            v-bind="data.attrs"
          />
        <!-- Actual SVG paths, shapes, etc. here -->
        <svg
            v-else
            viewBox="0 0 255 325" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            :class="data.class || data.staticClass || ''"
            :style="data.style"
            v-on="data.on"
            v-bind="{...data.attrs, ...$options.otherTypeDimensions(props)}"
        >
            <!-- Generator: Sketch 56.3 (81716) - https://sketch.com -->
            <defs>
                <linearGradient x1="58.154283%" y1="0%" x2="42.6660388%" y2="100%" id="linearGradient-1">
                    <stop stop-color="#EFEFEF" offset="0%"></stop>
                    <stop stop-color="#FFFFFF" offset="100%"></stop>
                </linearGradient>
                <path d="M27,7 L164.615783,7 C169.347264,7 173.888485,8.86296447 177.256828,12.1857899 L240.641045,74.7134634 C244.069802,78.0958853 246,82.7113316 246,87.5276734 L246,300 C246,309.941125 237.941125,318 228,318 L27,318 C17.0588745,318 9,309.941125 9,300 L9,25 C9,15.0588745 17.0588745,7 27,7 Z" id="path-2"></path>
                <filter x="-4.2%" y="-2.6%" width="108.4%" height="106.4%" filterUnits="objectBoundingBox" id="filter-3">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.827699888 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
                <linearGradient x1="50%" y1="50%" x2="0%" y2="98.3194484%" id="linearGradient-4">
                    <stop stop-color="#E6E6E6" offset="0%"></stop>
                    <stop stop-color="#FFFFFF" offset="100%"></stop>
                </linearGradient>
                <path d="M168.827586,5.63559322 L248.172414,83.6355932 L176.827586,83.6355932 C172.409308,83.6355932 168.827586,80.0538712 168.827586,75.6355932 L168.827586,5.63559322 Z" id="path-5"></path>
                <filter x="-35.3%" y="-21.4%" width="161.4%" height="162.4%" filterUnits="objectBoundingBox" id="filter-6">
                    <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                    <feOffset dx="-4" dy="8" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="6.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.262571473   0 0 0 0 0.262571473   0 0 0 0 0.262571473  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g id="file" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Rectangle">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <path stroke="#C9C9C9" stroke-width="5" d="M27,9.5 C18.4395864,9.5 11.5,16.4395864 11.5,25 L11.5,300 C11.5,308.560414 18.4395864,315.5 27,315.5 L228,315.5 C236.560414,315.5 243.5,308.560414 243.5,300 L243.5,87.5276734 C243.5,83.380268 241.837885,79.4058559 238.885344,76.4932148 L175.501127,13.9655413 C172.600609,11.1042194 168.690114,9.5 164.615783,9.5 L27,9.5 Z" stroke-linejoin="square" fill="url(#linearGradient-1)" fill-rule="evenodd"></path>
                </g>
                <g id="Triangle">
                    <use fill="black" fill-opacity="1" filter="url(#filter-6)" xlink:href="#path-5"></use>
                    <use stroke="#DDDDDD" stroke-width="1" fill="url(#linearGradient-4)" fill-rule="evenodd" xlink:href="#path-5"></use>
                </g>
                <text id="PNG" font-family="Arial" font-size="74" font-weight="bold" fill="#8A8A8A" text-anchor="middle">
                    <tspan x="127.5" y="287">{{$options.fileExtension(props.file)}}</tspan>
                </text>
            </g>
    </svg>
</template>

<script>
import mapping from '@/constants/mimetypeMapping'
import { classesFromData } from '@/utils/vueUtils.js'

export default {
  props: ['file', 'large'],
  fileExtension(file) {
    return mapping[file?.type]?.toUpperCase()
  },
  thumbnailClass(props) {
    return props.large ? 'filethumbnail_thumbnail_large' : 'filethumbnail_thumbnail'
  },
  otherTypeDimensions(props) {
      return props.large
        ? { width: '36px', height: '48px'}
        : { width: '20px', height: '30px'}
  },
  thumbnailImage(props) {
    let thumbnailImage = props.large ? props.file.largeThumbnail : props.file.smallThumbnail
    if (!thumbnailImage && props.file?.type?.includes('svg')) {
      thumbnailImage = props.file.url
    }
    return thumbnailImage
    
  },
  classesFromData
}
</script>

<style>
.filethumbnail_thumbnail {
  max-height: 24px;
  max-width: 48px;
  object-fit: contain;
  border-radius: 2px;
  border: solid #e4e5e4 2px;
}

.filethumbnail_thumbnail_large {
  max-height: 48px;
  max-width: 100px;
  object-fit: contain;
  border-radius: 2px;
  border: solid #e4e5e4 2px;
}
</style>
