<template>
  <EnumOptions
    v-bind="$attrs"
    v-on="$listeners"
    @validation-state="$emit('validation-state', $event)"
    hideExtend
  />
</template>

<script>
import EnumOptions from './EnumOptions.vue'
export default {
  components: {
    EnumOptions
  }
}
</script>