<template>
  <div v-if="form">
    <div v-if="!embedded" class="header" :style="styles">
      <v-progress-linear v-if="this.form.pages.length > 1" :color="primaryColor" :value="progress"></v-progress-linear>
      <v-img
        v-if="form.headerImage"
        :src="form.headerImage"
        class="headerImage"
      />
      <div class="d-flex justify-space-between language-select px-2">
        <UserProfile
            v-if="user && !designer"
            :user="user"
            :showSettings="false"
          />
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <LanguageSelect
            v-if="i18nFeature"
            v-model="currentLanguage"
            :dark="cardHeaderTheme"
            :items="form.languages"
          />
        </div>
      </div>
    </div>

    <v-container  :fluid="embedded" :class="embedded ? 'pa-0' : '' ">

      <v-card
        :max-width="embedded ? '100%': 550"
        :class="embedded ? 'mx-auto pa-6' : 'move-up mx-auto pa-6' "
        :color="cardColor"
        :elevation="(flatCard || embedded) ? 0 : 4"
        v-if="loadingState != loadingStates.Redirect"
        v-show="!this.form.autoSubmit || (loadingState == loadingStates.Error || loadingState == loadingStates.Success) "
        :dark="cardDarkTheme"
      >
      <div v-if="loadingState == loadingStates.Initial || loadingState == loadingStates.Loading">

        <v-form
          v-if="showForm"
          class="ma-2"
          ref="form"
          @submit.prevent="submit"
        >
          <!-- <TransitionGroup name="title"> -->
          <v-img
            v-if="showForm && form.logoImage && hasFeatureShowLogo"
            :src="form.logoImage"
            class="mb-3"
            :width="`${form.logoWidth}%`"
            :max-height=" form.logoWidth ? null : 90"
            position="left center"
            contain
            key="image"
          />
          <div v-if="user && !designer" class="d-flex align-center">
            {{ welcomeMessage }}
          </div>
            <editable-text
              v-if="designer || (!designer && formTitle)"
              textClass="text-h4 font-weight-bold"
              :maxLength="200"
              :multiline="true"
              :readonly="!designer"
              :text="formTitle"
              :singleClick="true"
              @submit="saveFormTitle"
              :required="false"
              :placeholder="$t('forms.builder.formTitlePlaceholder')"
            ></editable-text>
            <editable-text
              v-if="designer || (!designer && formDescription)"
              :textClass="cardDarkTheme ? 'text-h7 mt-3 font-weight-medium white--text' : 'text-h7 mt-3 font-weight-medium accent--text'"
              :maxLength="400"
              :multiline="true"
              :readonly="!designer"
              :text="formDescription"
              :singleClick="true"
              @submit="saveFormDescription"
              :required="false"
              :placeholder="$t('forms.builder.descriptionPlaceholder')"
            ></editable-text>
          <v-btn v-if="!isFirstPage" plain class="ml-n2 mt-2 pl-2 pr-2" elevation="0" small  @click="previousPage"><v-icon small class="mr-2">mdi-arrow-left</v-icon>{{$t('forms.view.previousPageButton')}}</v-btn>


        <!-- </TransitionGroup> -->
          <!-- <TransitionGroup name="fields"> -->
            <draggable
              handle=".handle"
              @end="(event) => onEnd(event)"
              :animation="200"
              :disabled="!designer"
              ghost-class="ghost-card">
              <template v-for="(component, index) in componentsOnPage" >
              
                <div class="d-flex flex-row align-stretch draggable-item" :key="component.fieldId">
                  <div v-if="designer" class="ml-n7 d-flex align-center ">
                    <v-icon class="handle">mdi-drag-vertical</v-icon>
                  </div>
                  <div class="flex-grow-1" style="max-width: 100%;">
                  <form-text-block
                    v-if="component.type === 'textBlock'" :key="`textBlock-${index}`"
                    :textBlock="component"
                    :readonly="!designer"
                    :language="currentLanguage"
                    :form="form">
                  </form-text-block>
                  
                  <FormFieldsFactory
                    v-else
                    class="mt-5"
                    :key="component.fieldId"
                    :component="component"
                    :form="form"
                    v-model="component.value"
                    :language="currentLanguage"
                    :data-testid="`input-${index}`"
                    :designer="designer"  
                  ></FormFieldsFactory>
                </div>
                </div>
              </template>
            </draggable>
          <!-- </TransitionGroup> -->

          <div> 
            {{ this.title }}
          </div>
        </v-form>

        <div 
          class="d-flex mt-8"
          :class="this.$vuetify.breakpoint.mobile ? 'flex-column-reverse align-end' : 'justify-end'">

          <SaveFormProgress 
            :class="this.$vuetify.breakpoint.mobile ? 'mt-4' : ''"
            v-if="form.saveProgress && hasFeatureSaveFormProgress" 
            :form="form"/>

          <v-btn
            v-if="showForm && isLastPage && !form.hideSubmitButton"
            :block="this.$vuetify.breakpoint.mobile"
            :disabled="!canSubmitForm"
            :loading="loadingState == loadingStates.Loading"
            :color="buttonColor"
            @click="submit"
            :dark="buttonDarkTheme"
            :light="!buttonDarkTheme"
            data-testid="formSubmitButton"
            :rounded="this.$vuetify.breakpoint.mobile"
          >
            {{ submitButtonLabel }}
          </v-btn>
            <v-btn
              :dark="buttonDarkTheme"
              :light="!buttonDarkTheme"
              data-testid="formNextButton"
              v-else-if="!isLastPage"
              :color="buttonColor"
              @click="nextPage">
                {{$t('forms.view.nextPageButton')}}
              <v-icon class="ml-2" small>mdi-arrow-right</v-icon>
            </v-btn>
            
      </div>
      <div v-if="!standalone && !embedded && !form.hideSubmitButton" class="caption grey--text font-weight-medium text-right mt-3 d-flex align-end justify-end ">
        <span :class="cardDarkTheme ? 'white--text' : 'grey--text'" >
          {{ $t('forms.securityHintPassword') }}  
          <ReportAbuse 
            :form="form" 
            :dark="cardDarkTheme"/> 
        </span>
      </div>
    </div >


      <div
        v-if="loadingState == loadingStates.Success || loadingState == loadingStates.Error"
        class="mt-6 mb-6"
      >
        <div v-if="loadingState == loadingStates.Success" class="text-center">
          <lottie
            :options="successAnimationOptions"
            :height="100"
            :width="100"
            v-on:animCreated="handleSuccessAnimation"
          />
          <div>
            <div data-testid="form-success-title" class="text-h4">
              {{ successTitle }}
            </div>
            <div class="mt-2 multiLines">
              {{successMessage}}
            </div>
          </div>
          <div>
            <v-btn v-if="afterSubmitTrigger == 'button' && afterSubmitAction != 'none'" :dark="buttonDarkTheme" :light="!buttonDarkTheme" class="mt-4 mb-4" :color="buttonColor" @click="performAfterSubmitAction">
              {{afterSubmitActionButtonTitle}}
            </v-btn>
          </div>
          <div v-if="!hideAdvertisement || !hasFeatureHideAd" class="mt-3 text-center">
            {{ $t('forms.advertisement') }}
            <a
              href="https://www.apptivegrid.de?utm_source=app-apptivegrid&utm_medium=shared-link&utm_campaign=apptive-form-success-page"
              class="font-weight-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              ApptiveGrid</a
            >
          </div>
        </div>

        <div v-if="loadingState == loadingStates.Error" class="text-h4 text-center">
          <lottie
            :options="errorAnimationOptions"
            :height="100"
            :width="100"
            v-on:animCreated="handleErrorAnimation"
          />
          <div>
            {{ this.error }}
          </div>
          <v-btn text small class="mt-4" color="primary" @click="reset">
            {{ $t('forms.view.backButton') }}
          </v-btn>
        </div>
      </div>
      <div class="d-flex justify-center mt-4">
        <FormBadge v-if="!hideAdvertisement && hasFeatureHideAd"></FormBadge>
      </div>
    </v-card>
    </v-container>

  </div>
</template>

<script>
import FormFieldsFactory from './FormFieldsFactory.vue'
import Lottie from 'vue-lottie'
import * as successAnimationData from '@/assets/success.json'
import * as errorAnimationData from '@/assets/error.json'
import { LoadingStates } from '@/utils/loadingStates.js'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import { base64DecodeUrl } from '@/utils/base64Url.js'
import formPageBus from '@/utils/formPageBus.js'
import {darkColor} from '@/utils/color.js'
import { actions, triggers } from '@/constants/afterSubmitTypes.js'

import SaveFormProgress from '@/components/form/SaveFormProgress.vue'
import ReportAbuse from '@/components/form/ReportAbuse.vue'
import FormBadge from '@/components/form/FormBadge.vue'
import EditableText from '../EditableText.vue'
import { checkFeatureLimit } from '@/mixins/paywall.js'
import LanguageSelect from '@/components/form/formbuilder/LanguageSelect.vue'
import draggable from 'vuedraggable'
import FormTextBlock from '@/components/form/FormTextBlock.vue'
import UserProfile from '@/components/user/UserProfile.vue'
import welcomeMessage from '@/mixins/welcomeMessage.js'

export default {
  name: 'FormView',
  mixins: [welcomeMessage],
  props: {
    form: null,
    designer: {
      type: Boolean,
      default: false
    },
    standalone: {
      type: Boolean,
      default: false
    },
  },
  async mounted() {
    formPageBus.onPageRequested(this.onPageRequested)
    formPageBus.onSuccessPageRequested(this.onSuccessPageRequested)
    
    // check paywall features
    this.hasFeatureShowLogo = await this.checkFeature( this.$apptive.constants.features.FORM_LOGO )
    this.hasFeatureHideAd = await this.checkFeature( this.$apptive.constants.features.FORM_HIDE_AD )
    this.hasFeatureSaveFormProgress = await this.checkFeature( this.$apptive.constants.features.SAVE_FORM_PROGRESS )
    this.hasFeatureDefaultValues = await this.checkFeature( this.$apptive.constants.features.FORM_DEFAULT_VALUES )
    this.hasFeatureSuccessRedirect = await this.checkFeature( this.$apptive.constants.features.FORM_SUCCESS_REDIRECT )
  
    try {
      if(this.hasFeatureDefaultValues) {
        this.form.components.forEach(component => {
          const fieldProperty = this.form.fieldProperties[component.fieldId]
          if('defaultValue' in fieldProperty) {
            component.value = fieldProperty.defaultValue
          }
        })
      }
    } catch(error) {
      console.error('form prefill with defaultValue failed')
      console.error(error)
    }

    if (this.$route.query.data) {
      try {
        const decodedData = base64DecodeUrl(this.$route.query.data)
        const prefill = JSON.parse(decodedData)
        this.form.components.forEach(component => {
          if (component.fieldId in prefill) {
            component.value = prefill[component.fieldId]
          }
        })
      } catch(error) {
        console.error('form prefill with query data failed')
        console.error(error)
      }  
    }
    // auto submit 
    if(this.form.autoSubmit && !this.designer) {
      this.submit()
    }
  },
  beforeDestroy() {
    formPageBus.pageRequestedOff(this.onPageRequested)
    formPageBus.successPageRequestedOff(this.onSuccessPageRequested)
  },
  data() {
    return {
      title: null,
      loadingState: LoadingStates.Initial,
      loadingStates: LoadingStates,
      errorTitle: null,
      errorMessage: null,
      hideForm: false,
      successAnimationOptions: {
        animationData: successAnimationData.default,
        loop: false,
        color: 'red'
      },
      errorAnimationOptions: {
        animationData: errorAnimationData.default,
        loop: false
      },
      visiblePage: 0,
      actionsMethods: {
        [actions.ADDITIONAL_ANSWER]: this.reset,
        [actions.REDIRECT]: this.redirect,
        [actions.NONE]: this.showSuccessState
      },
      hasFeatureShowLogo: false,
      hasFeatureHideAd: false,
      hasFeatureSaveFormProgress: false,
      hasFeatureDefaultValues:false,
      hasFeatureSuccessRedirect: false,
      currentLanguage: undefined
    }
  },
  computed: {
    i18nFeature() {
      return this.form.i18nFeature
    },
    showForm() {
      return (
        this.loadingState == LoadingStates.Initial ||
        this.loadingState == LoadingStates.Loading
      )
    },
    user() {
      return this.$store.state.user?.user
    },
    canSubmitForm() {
      return hasPermission(this.form, [PERMISSIONS.submit])
    },
    successTitle(){
      return this.form.getI18nResource(this.currentLanguage, 'successTitle') || this.$t('forms.view.successTitle')
    },
    successMessage(){
      return this.form.getI18nResource(this.currentLanguage, 'successMessage') || this.$t('forms.view.successMessage')
    },
    afterSubmitActionButtonTitle() {
       return this.form.getI18nResource(this.currentLanguage, 'afterSubmitActionButtonTitle') || this.$t('forms.view.submitMoreButton')
    },
    afterSubmitTrigger() {
      return this.form.afterSubmitTrigger
    },
    afterSubmitAction() {
      return this.form.afterSubmitAction
    },
    submitButtonLabel() {
      return this.form.getI18nResource(this.currentLanguage, 'buttonTitle') || this.$t('forms.view.submitButton')
    },
    buttonColor() {
      return this.form.buttonColor
    },
    buttonDarkTheme() {
      return !darkColor(this.buttonColor)
    },
    cardDarkTheme() {
      return !darkColor(this.cardColor)
    },
    cardHeaderTheme() {
      return !darkColor(this.headerColor)
    },
    primaryColor() {
      return this.form.primaryColor
    },
    cardColor() {
      return this.form.cardColor
    },
    headerColor() {
      return this.form.headerColor
    },
    flatCard() {
      return this.form.flatCard || false
    },
    hideAdvertisement() {
      return this.form.adsHidden || false
    },
    isFirstPage() {
      return this.visiblePage == 0
    },
    isLastPage() {
      return this.visiblePage == this.form.pages.length - 1
    },
    embedded() {
      return this.$route.query.embedded == 'true'
    },
    styles() {
      return {
        backgroundColor: this.headerColor
      }
    },
    progress() {
      if ( this.loadingState === LoadingStates.Success ) return 100
      if(this.isLastPage) return 95
      let factor = 100 / this.form.pages.length
      return (factor * (this.visiblePage + 1))
    },
    formTitle() {
      return this.form.getI18nResource(this.currentLanguage, 'title')
    },
    formDescription() {
      return this.form.getI18nResource(this.currentLanguage, 'description')
    },
    componentsOnPage() {
      return this.form.componentsOnVisiblePage(this.visiblePage)
    },
  },
  watch: {
    form: {
      immediate: true,
      handler(newVal) {
        let navigatorLanguage = newVal.languages.find(language => navigator.language.includes(language))
        this.currentLanguage = navigatorLanguage ?? newVal.defaultLanguage
      }
    }
  },
  methods: {
    async nextPage() {
      if (this.$refs.form.validate() == false) {
        return
      }
      this.visiblePage = this.visiblePage + 1
      await this.$nextTick()
      this.$emit('pageChanged')
    },
    async previousPage() {
      this.visiblePage = this.visiblePage - 1
      await this.$nextTick()
      this.$emit('pageChanged')    
    },
    replaceNewLines(text) {
      return text ? text.replaceAll('\n', '<br>') : null
    },
    handleSuccessAnimation: function(anim) {
      this.successAnimation = anim
    },
    handleErrorAnimation: function(anim) {
      this.errorAnimation = anim
    },
    performAfterSubmitAction() {
      let action = this.actionsMethods[this.form.afterSubmitAction]
      let delay = this.form.afterSubmitTriggerDelay || 0
      if(action) {
        if (delay > 0) {
          setTimeout(() => {
            this.$emit('submitActionTriggered')
            action()
          }, delay * 1000)
        } else {
          this.$emit('submitActionTriggered')
          action()
        }
      }
      if(delay > 0) {
        this.showSuccessState()
      }
    },
    redirect() {
      if(this.hasFeatureSuccessRedirect){
        this.loadingState = LoadingStates.Redirect
        window.location = this.form.afterSubmitTargetUrl
      }
      else {
        this.showSuccessState()
      }
    },
    reset() {
      this.loadingState = LoadingStates.Initial
      this.text = null
      this.title = null
      this.$emit('reload')
    },
    submit() {
      if (this.$refs.form.validate() == false || !this.canSubmitForm) {
        return
      }
      try {
        this.submitForm()
      } catch (e) {
        this.reset()
        this.title = e
      }
    },
    async showSuccessState() {
      this.$emit('didSubmit')
      this.loadingState = LoadingStates.Success
      this.visiblePage = 0
      await this.$nextTick()
      this.$emit('pageChanged')      
      this.successAnimation.play()
    },
    submitForm() {
      this.loadingState = LoadingStates.Loading
      this.form.submit()
        .then( () => {
          let trigger = this.form.afterSubmitTrigger
          if(trigger == triggers.AUTO) {
            this.performAfterSubmitAction()
          }
          else {
            this.showSuccessState()
          }
        })
        .catch(error => {
          if (error.response) {
            this.errorTitle = this.$t('forms.view.errorTitle'),
            this.errorMessage = error.response.data
            this.loadingState = LoadingStates.Error
            this.errorAnimation.play()
          } else if (error.request) {
            this.errorTitle = this.$t('forms.view.errorTitle'),
            this.errorMessage = error.request.toString()
            this.loadingState = LoadingStates.Error
            this.errorAnimation.play()
          }
        })
    },
    prefillFormWithLocalData() {
      this.form.prefillWithLocalData()
    },
    onPageRequested(index) {
      this.loadingState = LoadingStates.Initial
      this.visiblePage = index
    },
    onSuccessPageRequested() {
      this.loadingState = LoadingStates.Success
    },
    saveFormTitle(newTitle) {
      this.form.setI18nResource(this.currentLanguage, 'title', newTitle)
      this.form.updateComponents()
    },
    saveFormDescription(newDescription) {
      this.form.setI18nResource(this.currentLanguage, 'description', newDescription)
      this.form.updateComponents()
    },
    async checkFeature(feature) {
      const isLimitReached = await checkFeatureLimit(
          this.$store,
          this.form.plan,
          feature,
          0
        )
      return !isLimitReached
    },
    onEnd(event){
      console.log(`moved ${event.oldIndex} to ${event.newIndex}`)
      let page = this.form.pages[this.visiblePage]
      console.log(`page ${page}`)

      page.items.splice(event.newIndex, 0, page.items.splice(event.oldIndex, 1)[0])
      this.form.componentMovedOnPage()
    },
  },
  components: {
    FormFieldsFactory,
    lottie: Lottie,
    SaveFormProgress,
    ReportAbuse,
    FormBadge,
    EditableText,
    LanguageSelect,
    draggable,
    FormTextBlock,
    UserProfile
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headerImage {
  object-fit: cover;
  max-height: 240px;
  min-height: 220px;
}
.header {
  max-height: 240px;
  min-height: 220px;
  position: relative;
}

.move-up {
  margin-top: -120px;
}

.language-select {
  position: absolute;
  top: 12px;
  width: 100%;
}
.handle {
  cursor: grab;
  margin-right: 8px;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease; /* Smooth transition for the opacity change */
}

.draggable-item:hover .handle {
  opacity: 1; /* Show the handle when the item is hovered */
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
  border-radius: 4px;

}

</style>
