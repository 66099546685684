<template>
  <div  :style="style" class="d-flex align-center justify-center imageIcon">
    <img v-if="image" draggable="false" :width="imageSize" :src="`${image}`">
    <v-icon class="imageIcon" :size="iconSize" :style="style" v-else color="white">
    {{ icon }}
    </v-icon>
  </div>
 
</template>

<script>

export default {
    props: {
      nodeType: {
        default: null
      },
      size: {
        default: 60
      },
      opacity: {
        default: 1
      }
    },
    computed: {
      image() {
        return this.nodeType?.image
      },
      icon() {
        return this.nodeType?.icon
      },
      color() {
        return this.nodeType?.color
      },
      iconSize() {
        return this.size * 0.65
      },
      imageSize() {
        return this.size * 0.85
      },
      style() {
        return {
          height: `${this.size}px`,
          width: `${this.size}px`,
          'background-color': `${this.color}`,
          opacity: this.opacity
        }
    },
    }
}
</script>

<style scoped>
.imageIcon {
  border-radius: 200px;
}
</style>