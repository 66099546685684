import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class HtmlBlock extends Block {
  constructor(data) {
    super(data)
  }

  get content() {
    return this.html?.text?.value || ''
  }

  set content(newContent) {
    if (!this.html) {
      this.html = { text: {} }
    }
    if (!this.html.text) {
      this.html.text = {}
    }
    this.html.text.value = newContent
  }

  get evaluatedContent() {
    return this.html?.text?.evaluated || ''
  }

  async updateContent(newContent) {

    await store().dispatch('AGPatchBlock', {

      block: this,
      payload: {
        html: {
          text: {
            value: newContent
            }
          }
        }
      })
      this.content = newContent
      await this.reload()
    
  }

  validate() {
    return true
  }
} 