<template>
  <v-dialog v-model="dialogModel" :persistent="isCreateEntityMode" width="800" scrollable :fullscreen="$vuetify.breakpoint.mobile" :transition="$vuetify.breakpoint.mobile ? 'dialog-bottom-transition' : 'dialog-bottom-transition'">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    <v-card
      v-if="entity && dialogModel"
      class="d-flex flex-column background-color edit-entity-dialog"
      rounded="sm"
      height="85vh"
      :class="{'no-round-borders': $vuetify.breakpoint.mobile}"
      :style="`margin-top: ${topMargin}px`"
    >
      <v-card-title class="font-weight-medium title-bar">

        <slot name="titleBarLeft" />
        <div v-if="!isCreateEntityMode" class="ml-2" :class="{'flex-last': $vuetify.breakpoint.mobile && !isCreateEntityMode}">
          <span class="accent--text">{{ $t('dialogs.editEntityTitle')}}&nbsp;</span>
          <span class="font-weight-medium">{{ virtualGrid.name }}</span>
        </div>
        <div v-else>
          <span class="accent--text">{{ $t('dialogs.createEntityTitle')}}&nbsp;</span>
          <span class="font-weight-medium">{{ virtualGrid.name }}</span>
        </div>
        <v-spacer/>
        <v-btn
          v-if="!isCreateEntityMode"
          @click="print"
          icon
          data-testid="printEntityButton"
        >
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="!shouldDelete && !isCreateEntityMode"
          :disabled="!canDeleteEntity"
          @click="shouldDelete = true"
          icon
          data-testid="deleteEntityButton"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        <v-btn
          v-else-if="!isCreateEntityMode"
          :disabled="!canDeleteEntity"
          @click="deleteEntity"
          icon
          :loading="deletingEntity"
          color="destructive"
          data-testid="deleteEntityConfirmButton"
        >
          <v-icon>mdi-delete-empty-outline</v-icon>
        </v-btn>
        <EntityShareMenu
          v-if="!isCreateEntityMode"
          class="flex-grow-0"
          :virtualGrid="virtualGrid"
          :entity="entity"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="!canShareEntity"
              v-on="on"
              icon
              data-testid="entityShareMenuButton"
            >
              <v-icon>mdi-share</v-icon>
            </v-btn>
          </template>
        </EntityShareMenu>
        <v-btn v-if="!isCreateEntityMode" icon @click="close">
            <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <div
        v-show="!entityMatches"
        :class="{'d-flex': !entityMatches}"
        class="pa-1 justify-center text-body-2 red white--text filter-warning"
        data-testid="rowFilteredHint"
      >
        <v-icon small class="white--text pl-2 pr-2">mdi-filter-variant</v-icon>
        <span class="pr-2">{{ $t("virtualGrid.rowFiltered") }}</span>
      </div>
      <div :id="`entity-edit-${entity._id}`" class="fields-container position-relative background-color d-flex flex-column">
        <div class="overflow-auto px-3 pt-6">
          <v-row no-gutters v-for="(item, i) in items" :key="i" class="mt-2" :class="!$vuetify.breakpoint.mobile ? 'mx-8' : ''">
            <v-col
              cols="12"
              sm="4"
              class=" mb-3"
              @mouseenter="showColumnSettingsIndex = i"
              @mouseleave="showColumnSettingsIndex = -1"
              >
              <div
                class="d-flex align-start mr-2"
                data-testid="editEntityFieldHeader"
                >
              <v-icon class="mt-1" small>{{item.field.columnType.typeIcon}}</v-icon>
              <div
                class="ml-2 textOverflow text-subtitle-2 grey--text text--darken-2"
                data-testid="editEntityFieldName"
              >{{item.field.name}}</div>
              <ColumnSettingsMenu
                :virtualGrid="virtualGrid"
                :field="item.field"
                :attach="`#entity-edit-${entity._id}`"
                v-if="canAddField"
              >
              <template v-slot:customActivator="{ on, attrs }">
                <v-btn
                  v-show="showColumnSettingsIndex === i"
                  :data-testid="`showColumnSettingsButton-${i}`"
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              </ColumnSettingsMenu>
                </div>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              class=" mb-4"
            >
            <GridCellFactory
              v-if="!gridRef(item)"
              :readonly="isReadonly(item)"
              class="field-cell"
              :class="cellClasses(item)"
              solo
              roundedBorders
              :editEntityMode="true"
              :value="item.value"
              :displayValue="displayValues[i]"
              :field="item.field"
              :type="item.field.columnType"
              fontSize="1rem"
              v-on:input="(value) => entityChanged(value, item.field)"
              v-on:blur="updateEntity"
            />
            <EntityPicker
              v-else
              :gridUri="gridRef(item)"
              :value="item.value"
              :fieldName="item.field.name"
              :readonly="isReadonly(item)"
              v-on:input="(value) => entityUpdated(value, item.field)"
              :isCollection="item.field.isCollection"
            />
            </v-col>
          </v-row>
          <CreateColumnDialog v-if="canAddField" :virtualGrid="virtualGrid" createOnSave>
            <template v-slot:customActivator="slotProps">
              <v-btn
                v-bind="slotProps.attrs"
                v-on="slotProps.on"
                text
                data-testid="createColumnDialogButton"
                class="ml-2 mb-6"
              >
                <v-icon small class="mr-3" color="grey">mdi-plus</v-icon>
                <div class="text-subtitle-2 grey--text text--darken-2">{{$t('forms.builder.createFieldButton')}}</div>
            </v-btn>
            </template>
          </CreateColumnDialog>
        </div>
        <v-spacer/>
      <div v-if="isCreateEntityMode" class="d-flex flex-row justify-end pt-5 mr-5 mb-3 gap-2">
        <v-btn @click="close" color="accent" text>{{$t('dialogs.cancelButton')}}</v-btn>
        <v-btn :loading="isCreatingEntity" @click="createEntity" data-testid="createEntityButton" color="primary">{{$t('dialogs.createButton')}}</v-btn>
      </div>
    </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ColumnSettingsMenu from './ColumnSettingsMenu.vue'
import GridCellFactory from '@/components/gridView/GridCellFactory.vue'
import { columnTypes } from '@/constants/columnTypes.js'
import CreateColumnDialog from '@/components/gridView/CreateColumnDialog.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import reloadVirtualGrid from '@/mixins/reloadVirtualGrid.js'
import EntityPicker from './EntityPicker.vue'

export default {
  mixins: [reloadVirtualGrid],
  props: {
    value: null,
    virtualGrid: null,
    entity: null,
    createEntityMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      shouldDelete: false,
      deletingEntity: false,
      showColumnSettingsIndex: -1,
      topMargin: 0,
      isCreatingEntity: false
    }
  },
  computed: {
    dialogModel: {
      get() {
        return this.value
      },
      set(newVal) {
        if (!newVal) {
          if (!this.entity.provisional && !this.virtualGrid.entityMatches(this.entity)) {
            // the entity needs to be removed in a filtered view if it doesn't fit to the filter
            this.virtualGrid.entities.splice(this.virtualGrid.entities.findIndex(aEntitiy => aEntitiy._id == this.entity._id), 1)
          }
        }

        this.$emit('input', newVal)
      }
    },
    items() {
      if (!this.virtualGrid || !this.entity) {
        return []
      }
      return this.virtualGrid.fields.map((field, index) => {
        return {
          field,
          value: this.entity.fields[index]
        }
      })
    },
    displayValues() {
      return this.virtualGrid.fields.map((field, index) => {
        const entityValue = this.entity.fields[index]
        return field.columnType.displayFormat
          ? field.columnType.displayFormat(entityValue)
          : entityValue
      })
    },
    entityMatches() {
      return this.virtualGrid.entityMatches(this.entity)
    },
    canAddField() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.addField])
    },
    canDeleteEntity() {
      return hasPermission(this.entity, [PERMISSIONS.remove])
    },
    canEditEntity() {
      return hasPermission(this.entity, [PERMISSIONS.update])
    },
    canShareEntity() {
      return hasPermission(this.entity, [PERMISSIONS.addEditionLink])
    },
    isCreateEntityMode() {
      return this.entity?._id === 'draft' || this.createEntityMode
    },
  },
  watch: {
    dialogModel(newVal) {
      if (!newVal) return
      // For each already open EditEntityDialog, apply a margin top of 24px
      const dialogs = document.querySelectorAll('.edit-entity-dialog').length
      this.topMargin = dialogs * 24
    }
  },
  methods: {
    close() {
      this.dialogModel = false
      this.shouldDelete = false
    },
    entityChanged(newValue , field) {
      this.virtualGrid.entityChanged(this.entity, newValue , field)
    },
    entityUpdated(newValue , field) {
      this.entityChanged(newValue , field)
      this.updateEntity()
    },
    async updateEntity() {
      if (this.isCreateEntityMode) return
      await this.virtualGrid.updateEntity(this.entity)
      this.$emit('entity-updated', this.entity)
    },
    async deleteEntity() {
      this.deletingEntity = true
      await this.virtualGrid.deleteEntity(this.entity).finally(() => {
        this.deletingEntity = false
        this.shouldDelete = false
      })
      this.close()
    },
    isTextCell(item) {
      return item.field.columnType === columnTypes.string
    },
    isRichTextCell(item) {
      return item.field.columnType === columnTypes.richText
    },
    gridRef(item) {
      return item.field.type.gridUri
    },
    isReadonly(item) {
      if (item.field.columnType.readonly) {
        return true
      }
      return !this.canEditEntity && !this.isCreateEntityMode
    },
    print() {
      const routeData = this.$router.resolve({name: 'PrintEntity', params: {virtualGridUri: this.virtualGrid.uri, entityUri: this.entity.uri}})
      window.open(routeData.href, '_blank')
    },
    async createEntity() {
      this.isCreatingEntity = true
      try {
        const createdEntity = await this.virtualGrid.addEntity(this.entity)
        this.$emit('entityCreated', createdEntity)
      } finally {
        this.isCreatingEntity = false
      }
      this.close()
    },
    cellClasses(item) {
      return {
        'fit-content': !this.isTextCell(item),
        'pa-2': !this.isRichTextCell(item)
      }
    }
  },
  components: {
    GridCellFactory,
    ColumnSettingsMenu,
    EntityShareMenu: () => import('@/components/gridView/EntityShareMenu.vue'),
    CreateColumnDialog,
    EntityPicker
}
}
</script>

<style scoped>
.title-bar {
  border-bottom: solid 1px rgba(0,0,0,0.1);
}
.fields-container {
  height: 100%;
  overflow: hidden;
}
.background-color {
  background-color: #FFFFFF;
}
.position-relative {
  position: relative;
}
.fit-content {
  width: fit-content;
}
.field-cell {
  min-height: 20px;
  width: 100%
}
.no-round-borders {
  border-radius: 0;
}

.filter-warning {
  width: 100%
}

.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-last {
  order: 1;
  flex-basis: 100%;
}
</style>
