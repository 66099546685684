<template>
  <div>
    <BaseLinkView 
      v-if="link"
      :key="link.redirectUri"
      :uri="link.redirectUri"
      :buttons="buttons"
      :moreItems="moreMenuItems"
    />
    <QrCodeDialog
      v-model="qrCodeDialog"
      :uri="sharedUrl()"
      :showMobileApp="false"
    />
    <LinkRestrictionsDialog
      v-model="restrictionDialog"
      :link="link"
    />
  </div>
</template>

<script>
import { sharedLinksMethods } from '@/mixins/sharedLinksMethods.js'
import BaseLinkView from '@/components/BaseLinkView.vue'
import QrCodeDialog from '@/components/QrCodeDialog.vue'
import LinkRestrictionsDialog from '@/components/LinkRestrictionsDialog.vue'

export default {
  mixins: [sharedLinksMethods],
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      qrCodeDialog: false,
      restrictionDialog: false
    }
  },
  computed: {
    uri() {
      return this.link.redirectUri
    },
    absoluteUri() {
      if (this.uri == null) {
        return
      }
      return `${window.location.origin}${this.uri}`
    },
    buttons() {
      return [
        {
          icon: 'mdi-share',
          color: 'primary',
          tooltip: this.$t('blocks.shareDialog.buttons.open'),
          onClick: this.open,
          testId: 'openSharedBlockButton'
        },
        {
          icon: 'mdi-content-copy',
          color: 'primary',
          onClick: this.copyLink,
          tooltip: this.$t('blocks.shareDialog.buttons.copy'),
          testId: 'copySharedBlockLinkButton'
        },
        {
          icon: 'mdi-qrcode',
          color: 'primary',
          onClick: () => { this.qrCodeDialog = true },
          tooltip: this.$t('blocks.shareDialog.buttons.qrCode'),
          testId: 'showBlockLinkQrCodeButton'
        },
        {
          icon: this.link.requiresPassword ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline',
          color: 'primary',
          onClick: () => { this.restrictionDialog = true },
          tooltip: this.$t('blocks.shareDialog.buttons.setRestrictions'),
          testId: 'showBlockLinkRestrictionsDialogButton'
        }
      ]
    },
    moreMenuItems() {
      return [
        {
          icon: 'mdi-delete-outline',
          text: this.$t('blocks.shareDialog.buttons.remove'),
          color: 'red',
          onClick: this.deleteLink,
          testId: 'deleteLinkButton'
        }
      ]
    }
  },
  methods: {
    showRestrictionsDialog() {
      this.restrictionDialog = true
    }
  },
  components: {
    BaseLinkView,
    QrCodeDialog,
    LinkRestrictionsDialog
  }
}
</script> 