export default {
  props: {
    blockUri: {
      type: String,
      default: () => null
    },
    editorMode: {
      type: Boolean,
      default: () => false
    },
    grabbableClass: {
      type: String,
      default: () => 'grabbable'
    },
    showControls: {
      type: Boolean,
      default: () => false
    },
  }
} 