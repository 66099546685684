import { render, staticRenderFns } from "./BlockNavigation.vue?vue&type=template&id=a01b3606&scoped=true"
import script from "./BlockNavigation.vue?vue&type=script&lang=js"
export * from "./BlockNavigation.vue?vue&type=script&lang=js"
import style0 from "./BlockNavigation.vue?vue&type=style&index=0&id=a01b3606&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a01b3606",
  null
  
)

export default component.exports