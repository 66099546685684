<template>
  <div>
    <v-autocomplete
      v-model="autocompleteModel"
      item-text="displayValue"
      item-value="_links.self.href"
      :items="items"
      @input="$emit('patchNodeFlowForm')"
      hide-details solo dense
    >
      <template v-slot:append-item>
        <v-divider v-if="items.length > 0"></v-divider>
        <v-list-item @click="addPage" class="mt-2">
          <v-list-item-icon class="mr-0">
            <v-icon small color="black">mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('flow.flowFormPicker.addPage') }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append-outer>
        <v-btn class="mt-n1" icon text @click="editPage" :disabled="autocompleteModel == null">
          <v-icon>mdi-file-document-edit-outline</v-icon>
        </v-btn>
      </template>
    </v-autocomplete>
    <EditPageDialog v-if="autocompleteModel != null" v-model="editPageDialog" :blockUri="autocompleteModel._links.self.href"/>
  </div>
</template>

<script>
import { createHolder } from '@/apptivescript/model'
import EditPageDialog from '@/components/block/EditPageDialog.vue'
import externalModel from '@/mixins/externalModel'

  export default {
    mixins: [externalModel],
    props: {
      space: null,
      loadItems: null,
    },
    data() {
      return {
        items: [],
        loading: false,
        editPageDialog: false
      }
    },
    computed: {
    autocompleteModel: {
        get() {
          if (!this.externalModel || !this.items.length) return null

          const modelHref = this.externalModel?._links?.self?.href?.value || this.externalModel?.href?.value
          return this.items.find(item =>
            item._links.self.href === modelHref
          ) || null
        },
        set(newVal) {
          this.externalModel = createHolder({ href: createHolder(newVal) })
        }
      }
    },
    watch: {
      loadItems: {
        immediate: true,
        async handler(newValue) {
          if (newValue == null) {
            return
          }
          this.loading = true
          try {
            this.items = await newValue()
          } finally {
            this.loading = false
          }
        }
      },
      editPageDialog(newVal) {
        if (!newVal) {
          this.$emit('pageEdited')
        }
      }
    },
    methods: {
      async addPage() {
        const newPage = await this.$store.dispatch('AGAddPageToBlockOrSpace', this.space)
        this.items = await this.loadItems()
        this.autocompleteModel = newPage.uri
        this.editPageDialog = true
      },
      editPage() {
        this.editPageDialog = true
      }
    },
    components: {
      EditPageDialog
    }
  }
</script>

<style lang="scss" scoped>

</style>