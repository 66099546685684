<template>
  <BaseKanbanAttribute v-bind="{...$props, ...$attrs}" :height="height" :titleHeight="titleHeight">
    <template v-slot:default>

      <Avatar
            class="overlapping-avatar mr-2"
            size="26"
            :key="userId"
            :userId="userId"
            :initials="userEmail"
          />
        <span class="font-weight-medium text-caption">{{attribute.value}}</span>
    </template>
  </BaseKanbanAttribute>
</template>

<script>
import BaseKanbanAttribute from './BaseKanbanAttribute.vue'
import Avatar from '@/components/user/Avatar.vue'

export default {
  props: ['attribute'],
  data() {
    return {
      height: 55,
      titleHeight: 18
    }
  },
  computed: {
    userId() {
      let id = this.attribute.fieldValue?.uri.split('/').pop()
      return id
    },
    userEmail() {
      return this.attribute.fieldValue?.email
    },

  },
  components: {
    BaseKanbanAttribute,
    Avatar
  }
}
</script>
