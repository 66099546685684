import Vue from 'vue'
const bus = new Vue()

const _LINK_AUTH_NEEDED = 'linkAuthorizationNeeded'
const _LINK_AUTH_GRANTED = 'linkAuthorizationGranted'

export default {
  requestAuthorization() {
    bus.$emit(_LINK_AUTH_NEEDED)
  },
  onLinkAuthorizationNeeded(handler) {
    bus.$on(_LINK_AUTH_NEEDED, handler)
  },
  linkAuthorizationNeededOff(handler) {
    bus.$off(_LINK_AUTH_NEEDED, handler)
  },
  notifyAuthorizationGranted() {
    bus.$emit(_LINK_AUTH_GRANTED)
  },
  onLinkAuthorizationGranted(handler) {
    bus.$on(_LINK_AUTH_GRANTED, handler)
  },
  linkAuthorizationGrantedOff(handler) {
    bus.$off(_LINK_AUTH_GRANTED, handler)
  }
}