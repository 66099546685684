export const COLORS = {
  DEFAULT_TEXT: '#000000',
  DEFAULT_BUTTON_BACKGROUND: '#1565c0',
  DEFAULT_BUTTON_TEXT_COLOR: '#FFFFFF',
}

export const TEXT_ALIGNMENTS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
}