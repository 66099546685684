<template>
  <v-sheet
    v-if="space && space.isPaidTemplate() && space.isInTrialPeriod() && !isDismissed"
    elevation="0"
    class="mx-3 mb-3 paywall-trial position-relative"
  >
    <div class="gradient-border">
      <div class="pa-3">
        <div class="caption">
          <v-icon color="yellow darken-1" small class="mr-2">mdi-creation</v-icon>   
          <strong class="white--text">{{ $t('paywall.trial.title') }}</strong>
          <div class="mt-1 white--text">
            {{ $t('paywall.trial.daysRemaining', { days: space.getRemainingTrialDays() }) }}
          </div>
          
          <v-btn
            small
            rounded
            color="white"
            class="mt-2 cta-button font-weight-medium"
            @click="openIntercomChat"
            data-testid="upgradeToPaidTemplateButton"
          >
            {{ $t('paywall.upgradeNow') || 'Get full access' }}
          </v-btn>
          
          <div class="mt-2 d-flex align-center justify-space-between">
            <span 
              class="white--text text-caption help-link" 
              @click="openIntercomChat"
              data-testid="openIntercomChatLink"
            >
              {{ $t('helpMenu.chatWithUs') || 'Need help?' }}
            </span>
          </div>
          
          <v-btn
            icon
            x-small
            class="close-button"
            color="white"
            @click="dismiss"
            data-testid="dismissPaidTemplateSheet"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'PaidTemplateSheet',
  props: {
    space: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDismissed: false
    }
  },
  methods: {
    dismiss() {
      this.isDismissed = true
      this.$emit('dismissed')
    },
    openIntercomChat() {

      if (this.$intercom) {
        this.$intercom?.trackEvent( 'User clicked chat with us paid template' )
        this.$intercom.show()
      }
    },
  }
}
</script>

<style lang="css" scoped>
.paywall-trial {
  border-radius: 16px;
  padding: 4px;
  background: linear-gradient(to right, #1664c0, #9801fc);
  position: relative;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

.cta-button {
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: bold !important;
  width: 100%;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.help-link {
  font-size: 11px;
  opacity: 0.8;
  text-decoration: underline;
  cursor: pointer;
}

.help-link:hover {
  opacity: 1;
}
</style> 