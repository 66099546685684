<template>
  <v-col>
    <div class="text-subtitle-2">{{ $t("virtualGrid.enumOptionsTitle") }}</div>
    <v-list color="background" dense>
      <Draggable :disabled="disabled" :value="attributes" v-on:input="onElementDragged">
        <v-list-item v-for="(option, index) in attributes" :key="index" class="enum-option pa-0">
          <v-list-item-icon :class="{invisible: disabled}">
            <v-icon small>mdi-drag-vertical</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!field">
            <EditableText
              :text="option"
              :readonly="disabled"
              :singleClick="true"
              :showPencil="!disabled"
              :multiline="true"
              @submit="value => updateEnumOption(index, value)"
              :maxLength="50"
              data-testid="enum-option-text"
            />
          </v-list-item-content>
          <v-list-item-content v-else>{{ option }}</v-list-item-content>
          <v-list-item-action>
            <v-btn
              :disabled="disabled"
              icon
              @click="removeEnumOption(option)"
              :data-testid="`remove-enum-option-${option}`"
            >
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </Draggable>
    </v-list>
    <v-form v-if="!disabled" v-model="valid" ref="addOptionForm" @submit.prevent>
      <emoji-text-field
        v-model="newOptionInput"
        ref="optionInput"
        outlined
        class="textField"
        dense
        autofocus
        hide-details="auto"
        :rules="enumAddOptionRules"
        @keyup.enter.native="addEnumOption(newOptionInput)"
        :placeholder="$t('virtualGrid.enumOptionsInputPlaceholder')"
        data-testid="add-enum-option-input"
      >
        <template v-slot:append-outer>
          <v-btn
            icon
            small
            color="primary"
            :disabled="addButtonDisabled"
            @click="addEnumOption(newOptionInput)"
            data-testid="add-enum-option-button"
          >
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
        </template>
      </emoji-text-field>
      <v-switch
        v-if="!hideExtend"
        v-model="extended"
        @change="emit"
        class="mt-3"
        hide-details
        dense
        data-testid="enum-extended-switch"
      >
      <template slot="label">
        <span class="text-body-2">{{$t('virtualGrid.enumOptionsExtendedLabel')}}</span>
        </template>
      </v-switch>
    </v-form>
  </v-col>
</template>

<script>
import Draggable from 'vuedraggable'
import EditableText from '@/components/EditableText'
import EmojiTextField from '@/components/EmojiTextField'

export default {
  props: {
    value: null,
    field: null,
    hideExtend: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      valid: true,
      newOptionInput: '',
      attributes: undefined,
      extended: undefined
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal == null) {
          this.attributes = []
          this.extended = undefined
          return
        }
        this.attributes = [...newVal.attributes]
        this.extended = newVal.extended
      }
    },
    addButtonDisabled: {
      immediate: true,
      handler(newVal) {
        this.$emit('validation-state', newVal)
      }
    }
  },
  methods: {
    removeEnumOption(option) {
      let index = this.attributes.indexOf(option)
      if (index >= 0) {
        this.attributes.splice(index, 1)
        this.emit()
      }
    },
    addEnumOption(newOption) {
      if (this.newOptionIsEmpty) return

      if (this.$refs.addOptionForm.validate()) {
        this.attributes.push(newOption.trim())
        this.emit()
        this.$refs.addOptionForm.reset()
        this.newOptionInput = ''
      }
      this.$refs.optionInput.focus()
    },
    onElementDragged(newAttributes) {
      this.attributes = newAttributes
      this.emit()
    },
    emit() {
      this.$emit('input', {
        attributes: this.attributes,
        extended: this.extended
      })
    },
    updateEnumOption(index, newValue) {
      if (newValue && 
          newValue !== this.attributes[index] && 
          !this.attributes.includes(newValue)) {
        this.attributes.splice(index, 1, newValue)
        this.emit()
      }
    }
  },
  computed: {
    addButtonDisabled() {
      return this.newOptionIsEmpty || !this.$refs.addOptionForm.validate()
    },
    newOptionIsEmpty() {
      return !this.newOptionInput.trim()
    },
    enumAddOptionRules() {
      return [
        value =>
          this.attributes.indexOf(value.trim()) === -1 || this.$t('validation.noDuplicate'),
        value =>
          value == null || value.length === 0 || ( value && value.trim().length > 0 ) || this.$t('validation.notEmpty')
      ]
    }
  },
  components: {
    Draggable,
    EditableText,
    EmojiTextField,
  }
}
</script>

<style scoped>
.enum-option .v-list-item__action {
  margin: 0;
}
.textField >>> .v-input__slot {
  background-color: white !important;
}
.invisible {
  opacity: 0;
}
</style>
