<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass" >
    <template v-slot:menu>
      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.resourceBlock.menuButton')"
        @click="openMenu"
      />
      <MenuItem
        icon="mdi-arrow-expand-horizontal"
        :text="$t('blocks.resourceBlock.switchToFullsize')"
        showSwitch
        v-model="isFullWidth"
      />

    </template>
    <div>
      <div
      v-if="!resource"
      class="resource-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-grid</v-icon>
      <div>{{ $t('blockTypes.resourceBlock.subtitle') }}</div>
    </div>
    <div v-else >
      <h3>{{ block.resourceBlock.resource.displayValue }}</h3>
      <!-- Add more fields as necessary -->
      <div class="resizable-container" :style="resizableContainerSizeStyle" ref="resizableContainer">
        <component v-if="statefulView" :is="viewComponent" :embedded="true" :showToolbar="false" :toolbarLabels="false" :statefulViewUri="statefulViewUri" />
      </div>
    </div>
    </div>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
      min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.resourceBlock.menuTitle') }}</v-card-title>
        <v-card-text>

      <TemplateResourceInput
        v-model="resource"
        :block="block"
        v-bind="$attrs"
        v-on="$listeners"
        ref="resourcePicker"
        :loadItems="loadResources"

      />
      </v-card-text>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import { mapGetters } from 'vuex'
import MenuItem from '@/components/MenuItem.vue'
import resourceInputMixin from '@/mixins/resourceInputMixin'
import resizableMixin from '@/mixins/resizableMixin'
import StandaloneView from '@/components/StandaloneView.vue'
import VirtualGrid from '@/components/gridView/GridView.vue'
import KanbanView from '@/components/kanbanView/KanbanView.vue'
import CalendarView from '@/components/calendar/CalendarView.vue'
import ListView from '@/components/listView/ListView.vue'
import MapView from '@/components/mapView/MapView.vue'
import GalleryView from '@/components/galleryView/GalleryView.vue'
import TemplateResourceInput from '@/components/flow/TemplateResourceInput.vue'
import blockProps from '@/mixins/blockProps'
export default {
  mixins: [ resourceInputMixin, resizableMixin, blockProps ],
  props: {
    spaceUri: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showMenu: false,
      menuX: 0,
      menuY: 0,
      loading: false,
      errorMessage: null,
      statefulView: null,
      components: {
        kanban: KanbanView,
        spreadsheet: VirtualGrid,
        calendar: CalendarView,
        map: MapView,
        gallery: GalleryView,
        list: ListView
      }
    }
  },
  watch: {
    blockUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.loadView()
        }
      }
    },
    statefulView: {
      immediate: true,
      async handler(newVal) {
        if (!newVal || this.resizeObserver != null) {
          return
        }
        await this.$nextTick()
        this.initResizeObserver('resizableContainer') // Initialize the observer
      }
    },
  },
  computed: {
    isFullWidth: {
      get() {
        return this.block.width === 'fullwidth'
      },
      set(newVal) {
        this.block.setWidth(newVal ? 'fullwidth' : null)
        this.block.updateValue()
      }
    },
    ...mapGetters(['blockWithUri']),
    resource: {
      get() {
        return this.block.resourceBlock.resource
      },
      set(newVal) {
        this.handleItemSelected(newVal)
      }
    },
    block() {
      return this.blockWithUri(this.blockUri)
    },
    resourceLink() {
      return this.block?._links?.resources?.href
    },
    viewComponent() {
      return this.components[this.statefulView?.type]
    },
    statefulViewUri() {
      return this.statefulView?.uri
    }
  },
  components: {
    BaseBlock,
    MenuItem,
    StandaloneView,
    TemplateResourceInput
  },
  methods: {
    openMenu(event) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.showMenu = true
    },
    async handleItemSelected(newVal) {
      // Implement this method to handle the selected item from the resource picker

      this.loading = true
      try {
        this.block.resourceUri = newVal
        await this.block.updateValue()
        await this.loadView()
      } finally {
        this.loading = false
        this.showMenu = false
      }
    },
    async loadView() {
      if(!this.resource) {
        return
      }
        this.loading = true
        const resourceRef = this.resource._links.self.href
        try {
          this.statefulView = await this.$store.dispatch(
            'AGReadStatefulViewOperation',
            resourceRef,
            { customErrorHandling: [404]}
          )
        } catch (error) { 
          if (error.response.status === 404) {
            this.errorMessage = this.$t('errorMessages.unaccessibleView')
          }
        }
        finally {
          this.loading = false
        }
    },
  }
}
</script>

<style  lang="scss"  scoped>
.resizable-container {
  resize: vertical;
  min-height: 200px;
  overflow: auto;
}

/* Add styles for the resource block */
.resource-empty {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}
</style>
