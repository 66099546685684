<template>
  <v-sheet
    color="white"
    rounded="lg"
    class="mb-2 mx-1 list-item"
    :class="{
      'list-item--checked': checked,
      'list-item--no-drag': !canPatchView,
      'list-item--clickable': !$vuetify.breakpoint.mobile
    }"
    :elevation="checked ? 0 : 2"
    @click="!$vuetify.breakpoint.mobile && $emit('edit', item.entity)"
  >
    <div class="d-flex align-center py-1 px-2">
      <v-icon
        v-if="canPatchView"
        class="mr-2"
        small
        :class="{ 'drag-handle': canPatchView }"
      >mdi-drag-vertical</v-icon>
      <v-checkbox
        v-if="hasBooleanField"
        :input-value="checked"
        @change="$emit('update-boolean', $event)"
        class="mt-0 pt-0 mr-2"
        hide-details
        dense
        :disabled="!canPatchView"
        :readonly="!canPatchView"
        :color="canPatchView ? 'primary' : 'grey'"
        @click.stop
      ></v-checkbox>
      <div class="flex-grow-1">
        <div
          class="subtitle-1 font-weight-medium title-text"
          :class="{'black--text': !checked, 'grey--text': checked}"
        >
          {{ title }}
        </div>
        <div
          v-if="item.description"
          :class="{'grey--text': checked}"
          class="description-text"
        >
          {{ description }}
        </div>
      </div>

      <Avatar
        v-if="userValue"
        :size="32"
        :initials="userValue.email || userValue.name"
        class="mr-3"
      />
      <div class="actions" v-if="$vuetify.breakpoint.mobile">
        <slot name="item-actions" :item="item">
          <v-icon
            class="mr-2"
            small
            @click.stop="$emit('edit', item.entity)"
            :color="canPatchView ? undefined : 'grey'"
          >{{ canPatchView ? 'mdi-pencil' : 'mdi-eye' }}</v-icon>
        </slot>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import Avatar from '@/components/user/Avatar.vue'

export default {
  name: 'ListViewItem',
  components: {
    Avatar
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    canPatchView: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    hasBooleanField: {
      type: Boolean,
      default: false
    },
    userValue: {
      type: Object,
      default: null
    }
  },
  computed: {
    title() {
        return this.item.title
    },
    description() {
      return this.item.description || ''
    }
  },
}
</script>

<style scoped>
.drag-handle {
  cursor: move;
  color: #9e9e9e;
}

.list-item--checked {
  opacity: 0.75;
}

.title-text,
.description-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style> 