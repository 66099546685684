<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode">
    <template v-slot:menu>
      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.resourceBlock.menuButton')"
        @click="openMenu"
      />
    </template>
    <div
      v-if="!resource"
      class="resource-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-counter</v-icon>
      <div>{{ $t('blockTypes.gridCountCardBlock.subtitle') }}</div>
    </div>
  <v-card v-else class="grid-count-card pa-1" elevation="0" color="grey lighten-4" :loading="loading">
    <v-card-title class="text-h3 pt-2">{{ count }}</v-card-title>
    <v-card-subtitle class="text-h6 text-left py-1 accent--text">{{ gridName }}</v-card-subtitle>
  </v-card>
  <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
      min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.resourceBlock.menuTitle') }}</v-card-title>
        <v-card-text>
          <TemplateResourceInput
            v-model="resource"
            :placeholder="$t('blocks.resourceBlock.inputLabel')"
            :block="block"
            v-bind="$attrs"
            v-on="$listeners"
            ref="resourcePicker"
            :loadItems="loadResources"
          />
        </v-card-text>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import resourceInputMixin from '@/mixins/resourceInputMixin'
import MenuItem from '@/components/MenuItem.vue'
import TemplateResourceInput from '@/components/flow/TemplateResourceInput.vue'

export default {
  name: 'GridCountCardBlock',
  mixins: [ resourceInputMixin ],
  data() {
    return {
      statefulView: null,
      loading: true,
      count: 0,
      showMenu: false,
      menuX: 0,
      menuY: 0
    }
  },

  props: {
    blockUri: {
      type: String,
      required: true
    },
    editorMode: {
      type: Boolean,
      default: false
    },
    spaceUri: {
      type: String,
      required: false
    }
  },

  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    resource: {
      get() {
        return this.block.resourceBlock.resource
      },
      set(newVal) {
        this.handleItemSelected(newVal)
      }
    },
    gridName() {
      return this.statefulView?.name
    },
    resourceLink() {
      return this.block?._links?.resources?.href
    },
  },

  mounted() {
    this.loadView()
  },

  methods: {
    async loadView() {
      if(!this.resource) {
        return
      }
        this.loading = true
        const resourceRef = this.resource._links.self.href
        try {
          this.statefulView = await this.$store.dispatch(
            'AGReadStatefulViewOperation',
            resourceRef,
            { customErrorHandling: [404]}
          )
          await this.loadCount()
        } catch (error) { 
          if (error.response.status === 404) {
            this.errorMessage = this.$t('errorMessages.unaccessibleView')
          }
        }
        finally {
          this.loading = false
        }
    },
    openMenu(event) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.showMenu = true
    },
    async handleItemSelected(newVal) {
      // Implement this method to handle the selected item from the resource picker

      this.loading = true
      try {
        this.block.resourceUri = newVal
        await this.block.updateValue()
        this.loadView()
      } finally {
        this.loading = false
        this.showMenu = false
      }
    },
    async loadCount() {
      if(!this.statefulView) {
        return
      }
      const response = await this.$store.dispatch('AGListViewEntitiesOperation', { statefulView: this.statefulView, pageIndex: 1, pageSize: 1 })
      this.count = response.data.numberOfItems
    }
  },
  components: {
    BaseBlock,
    MenuItem,
    TemplateResourceInput
  }
}
</script>

<style scoped>
.grid-count-card {
  max-width: 300px;
  height: 100%;
}
.resource-empty {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 12px;
  max-width: 450px;
}

</style> 