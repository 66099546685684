<template>
  <BaseInputBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass">
    <template v-slot:menu>
      <MenuItem
        icon="mdi-asterisk"
        :text="$t('blocks.inputBlock.required')"
        showSwitch
        v-model="isRequired"
      />
      <MenuItem
        icon="mdi-lock-outline"
        :text="$t('blocks.inputBlock.readonly')"
        showSwitch
        v-model="isReadonly"
      />
    </template>
    <div class="voice-recorder-block">
      <div class="block-header" v-if="title">
        <h3>{{ title }}</h3>
      </div>
      
      <voice-recorder
        v-model="audioData"
        :disabled="disabled || isUploading"
        @recording-start="handleRecordingStart"
        @recording-stop="handleRecordingStop"
        @recording-complete="handleRecordingComplete"
        @recording-deleted="handleRecordingDelete"
      />

      <div v-if="isUploading" class="upload-progress">
        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-2"
        ></v-progress-linear>
        <div class="upload-status text-center">
          {{ $t('uploading') }}...
        </div>
      </div>
    </div>
  </BaseInputBlock>
</template>

<script>
import VoiceRecorder from '../VoiceRecorder.vue'
import { v4 as uuidv4 } from 'uuid'
import { uploadFileToS3 } from '@/utils/s3Utils.js'
import BaseInputBlock from '@/components/block/BaseInputBlock.vue'
import MenuItem from '@/components/MenuItem.vue'
import blockProps from '@/mixins/blockProps'
export default {
  name: 'VoiceRecorderBlock',
  mixins: [ blockProps ],
  components: {
    VoiceRecorder,
    BaseInputBlock,
    MenuItem
  },
  
  data() {
    return {
      isUploading: false
    }
  },

  props: {
    title: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
  },

  computed: {
    audioData: {
      get() {
        return this.value?.url || null
      },
      set() {
        // This will be called with base64 data from VoiceRecorder
        // We don't set the value here as we'll do it after S3 upload
      }
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    isRequired: {
      get() {
        return this.block?.required
      },
      set(value) {
        this.block.setRequired(value)
      }
    },
    isReadonly: {
      get() {
        return this.block?.readonly
      },
      set(value) {
        this.block.setReadonly(value)
      }
    }
  },

  methods: {
    handleRecordingStart() {
      this.$emit('recording-start')
    },

    handleRecordingStop() {
      this.$emit('recording-stop')
    },

    async handleRecordingComplete(blob) {
      this.isUploading = true
      try {
        const fileId = uuidv4()
        const fileName = `voice_${fileId}.wav`
        const s3Url = await uploadFileToS3(fileId, 'audio/wav', blob)
        
        const attachment = {
          url: s3Url,
          type: 'audio/wav',
          name: fileName
        }
        
        // Update the block's attachmentInput value
        if (this.block) {
          this.block.attachmentInput.attachment = attachment
          await this.block.updateValue()
        }
        
        this.$emit('input', attachment)
        this.$emit('recording-complete', attachment)
      } catch (error) {
        console.error('Failed to upload voice recording:', error)
      } finally {
        this.isUploading = false
      }
    },

    handleRecordingDelete() {
      // Clear the block's attachmentInput value
      if (this.block) {
        this.block.attachmentInput.attachment = null
        this.block.updateValue()
      }
      
      this.$emit('input', null)
      this.$emit('recording-deleted')
    }
  }
}
</script>

<style scoped>
.voice-recorder-block {
  width: 100%;
  background: var(--v-surface-base);
  border-radius: 8px;
  overflow: hidden;
}

.block-header {
  padding: 16px 16px 0;
}

.block-header h3 {
  margin: 0;
  font-size: 1.1em;
  font-weight: 500;
  color: var(--v-text-primary);
}

.upload-progress {
  padding: 8px 16px 16px;
}

.upload-status {
  font-size: 0.9em;
  color: var(--v-text-secondary);
}
</style> 