<template>
<v-dialog
  v-model="externalModel"
  max-width="550"
  :disabled="disabled"
  :fullscreen="$vuetify.breakpoint.mobile"
>
  <template v-slot:activator="{ on, attrs }">
    <slot name ="activator" :on="on" :attrs="attrs">
    </slot>
  </template>
  <v-card
    class="dialog-content pb-3"
    :rounded="$vuetify.breakpoint.mobile ? '0' : undefined"
  >
    <v-btn
      v-if="$vuetify.breakpoint.mobile"
      icon
      text
      class="ml-3 mt-3"
      @click="close"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title
      class="text-h6 text-center pb-0"
      :class="{'pt-0': $vuetify.breakpoint.mobile}"
      v-html="headline"
    >
    </v-card-title>
    <v-card-text>
      <div class="subtitle-1 pb-3">{{$t("dialogs.shareSpaceText")}}</div>

      <v-form ref="form">
        <v-text-field
          ref="input"
          v-model="inputModel"
          class="mt-2"
          outlined
          dense
          @focus="$event.target.select()"
          clearable
          :rules="[...rules.registrationEmail, noDuplicateRule, notSelfRule]"
          @keyup.enter.native.prevent
          @input="clearError"
          :error-messages="error"
          autocomplete="email"
          data-testid="shareSpaceInput"
          :placeholder="$t('dialogs.shareSpaceEmailPlaceHolder')"
        >
        <template v-slot:append>
          <v-menu
            :label="$t('roles.selectLabel')"
            item-text="description"
            :rules="rules.required"
            offset-y left
            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
              v-bind="attrs"
              small 
              class="mt-n1"
              text
              v-on="on"
              data-testid="shareSpaceRoleSelect"
              color="primay">
              {{selectedRoleLabel}}
              <v-icon>mdi-chevron-down</v-icon></v-btn>
            </template>
          <v-list>
            <v-list-item  
              two-line
              v-for="role in roles"
              dense
              @click="selectedRole = role.value"
              :key="role.key"
              :data-testid="`shareSpaceRoleOption:${role.value}`"
            >
              <v-list-item-content>
                <v-list-item-title>{{role.text}}</v-list-item-title>
                <v-list-item-subtitle>{{role.description}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        </template>

        <template v-slot:append-outer>
          <PaywallMenu
            :feature="$apptive.constants.features.WRITERS_PER_SPACE"
            :count="selectedRole === 'reader' ? -1 : writerUserCount"
          >
            <v-btn
            class=""
            color="primary"
            @click="shareSpace"
            :loading="loading"
            data-testid="shareSpaceSubmitButton"
          >
            {{ $t("dialogs.shareSpaceSubmit") }}
          </v-btn>
          </PaywallMenu>
        </template>
        </v-text-field>
       
      </v-form>

    </v-card-text>
    <v-subheader v-if="invites.length" class="list-header">{{$t('spaceInvitesList.header')}}</v-subheader>
    <v-list class="share-list">
       <SpaceInvite
        v-for="(invite, index) in invites"
        :key="index"
        :spaceUri="spaceUri"
        :invite="invite"
      />
    </v-list>


    <v-subheader v-if="shares.length" class="list-header">{{$t('spaceShareList.header')}}</v-subheader>
    <v-list class="share-list">
        <v-list-item
          v-for="(share, index) in shares"
          :key="index"
          dense
          data-testid="shareItem"
        >
          <v-list-item-avatar>
            <Avatar :userId="share.users[0]" :initials="share.emails[0]" size="36" />
          </v-list-item-avatar>
          <v-list-item-content class="share-item-content d-flex flex-row justify-space-between">
            <v-list-item-title class="shrink-item" v-text="share.emails[0]"/>
            <ChipSelect
              class="shrink-item ml-1"
              :value="share.role"
              @input="(value) => updateShareRole(share, value)"
              :items="roles"
              :loading="updateLoading[share.uri]"
              data-testid="shareItemRoleSelect"
            >
              <template v-slot:item="{ item, onClick }">
                <PaywallMenu
                  :feature="$apptive.constants.features.WRITERS_PER_SPACE"
                  :count="shareRoleWriterCount(share, item)"
                >
                  <v-list-item
                    @click="onClick"
                    class="white"
                    two-line
                    dense
                    :key="index"
                    :data-testid="`selectOption:${item.value}`">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  </PaywallMenu>
              </template>
            </ChipSelect>
          </v-list-item-content>
           <v-list-item-action>
            <v-btn
              icon
              @click="deleteShare(share)"
              :loading="deleteLoading"
              data-testid="shareItemDeleteButton"
            >
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
    </v-list>
  </v-card>
</v-dialog>
</template>

<script>
import {textFieldRules} from '@/mixins/textFieldRules.js'
import Avatar from '@/components/user/Avatar.vue'
import ChipSelect from '@/components/ChipSelect.vue'
import externalModel from '@/mixins/externalModel.js'
import SpaceInvite from '@/components/space/SpaceInvite.vue'
import PaywallMenu from '../paywall/PaywallMenu.vue'

export default {
  mixins: [externalModel, textFieldRules],
  props: {
    spaceUri: null,
    spaceName: null,
    disabled: null
  },
  data() {
    return {
      inputModel: undefined,
      selectedRole: 'reader',
      loading: false,
      error: undefined,
      deleteLoading: false,
      updateLoading: {}
    }
  },
  provide() {
    return {
      plan: () => this.plan,
    }
  },
  watch: {
    externalModel: {
      immediate: true,
      handler(newVal) {
        if (!this.disabled && newVal) {
          this.loading = true
          Promise.all([
            this.$store.dispatch('loadInvitesUris', this.spaceUri),
            this.$store.dispatch('loadShareUris', this.spaceUri)
          ]).finally( this.loading = false )
        }
      }
    },
  },
  computed: {
    roles() {
      return [
        {
          text: this.$t('roles.reader'),
          value: 'reader',
          label: this.$t('roles.readerLabel'),
          description: this.$t('roles.readerDescription')
        },
        {
          text: this.$t('roles.writer'),
          value: 'writer',
          label: this.$t('roles.writerLabel'),
          description: this.$t('roles.writerDescription')
        },
        {
          text: this.$t('roles.admin'),
          value: 'admin',
          label: this.$t('roles.adminLabel'),
          description: this.$t('roles.adminDescription')
        }
      ]
    },
    selectedRoleLabel() {
      return this.roles.find( aRole => aRole['value'] == this.selectedRole).label
    },
    shares() {
      return this.$store.getters.fullSharesOfSpace(this.spaceUri) || []
    },
    invites() {
      return this.$store.getters.fullInvitesOfSpace(this.spaceUri) || []
    },
    headline() {
      return this.$t('dialogs.shareSpaceTitle', { spaceTitle: this.spaceName })
    },
    sharedUsersMails(){
      if(!this.shares) return []
      return this.shares.flatMap(aShare => aShare.emails)
    },
    noDuplicateRule() {
      return value => !this.sharedUsersMails.includes(value) || this.$t('validation.spaceAlreadyShared')
    },
    userEmail() {
      return this.$store.getters.userMail
    },
    notSelfRule() {
      return value => this.userEmail !== value || this.$t('validation.cannotShareToSelf')
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    plan() {
      return this.space?.plan
    },
    writerUserCount() {
      const writerRoles = ['writer', 'admin']
      const writerShareCount = this.shares.filter(share => writerRoles.includes(share.role)).length
      const writerInvitesCount = this.invites.filter(invite => writerRoles.includes(invite.role)).length
      return writerShareCount + writerInvitesCount
    }
  },
  methods: {
    shareSpace() {
      if(this.inputModel && this.$refs.form.validate()) {
        this.loading = true
        this.$store.dispatch('AGInviteSpaceOperation', {
          spaceUri: this.spaceUri,
          email: this.inputModel.toLowerCase(),
          role: this.selectedRole
        })
        .then(() => {
          this.inputModel = undefined
        })
        .catch(error => {
          this.error = error.response?.data?.description
          // HACK : assuming the error because of the lack of a precise error identifier
          if (error.response?.data?.error === 'constraints error') {
            this.error = this.$t('errorMessages.userWithEmailNotFound', {email: this.inputModel})
          }
        })
        .finally(() => {
          this.loading = false
          this.$intercom?.trackEvent( 'User shared a space' )
        })
      }
    },
    async deleteShare(share) {
      this.deleteLoading = true
      try {
        const fullShare = await this.$store.dispatch('AGReadShareOperation', share._links.self.href)
        this.$store.dispatch('AGDeleteShareOperation', {
          spaceUri: this.spaceUri,
          share: fullShare
        })
      } finally {
        this.deleteLoading = false
      }
    },
    close() {
      this.externalModel = false
    },
    clearError() {
      this.error = undefined
    },
    async updateShareRole(share, role) {
      this.$set(this.updateLoading, share.uri, true)
      try {
        const fullShare = await this.$store.dispatch('AGReadShareOperation', share._links.self.href)
        this.$store.dispatch('AGUpdateShareOperation', {
          share: fullShare,
          role
        })
      } finally {
        this.$set(this.updateLoading, share.uri, false)
      }
    },
    shareRoleWriterCount(share, item) {
      // If the new role is reader, it is free and the paywall banner does not need to be shown
      if (item.value === 'reader') {
        return this.$apptive.constants.features.FREE_FEATURE_COUNT
      }
      // If the role of the share already is writer or admin,
      // changing its role does not set the count above the limit
      return share.role !== 'reader' ? this.$apptive.constants.features.FREE_FEATURE_COUNT : this.writerUserCount
    }
  },
  components: {
    Avatar,
    ChipSelect,
    SpaceInvite,
    PaywallMenu
}
}
</script>

<style scoped lang="css">
.dialog-content {
  display: flex;
  flex-flow: column;
  max-height: 100%;
}

.share-list {
  max-height: 300px;
  overflow: auto;
}
::v-deep .v-input__append-inner{
  margin-top: 10px !important;
}

::v-deep .v-input__append-outer{
  margin-top: 3px !important;
}

.list-header {
  height: unset;
}

.shrink-item {
  flex: 0 1 auto !important;
}

.share-item-content {
  flex-wrap: nowrap;
}

.text-h5 {
  word-break: inherit;
}
</style>
