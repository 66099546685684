<template>
  <BaseBlock
    :blockUri="blockUri"
    v-on="$listeners"
    :editorMode="editorMode"
    :grabbableClass="grabbableClass"
  >
    <template v-slot:menu>
      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.blockSettings')"
        @click="openMenu"
      />
    </template>
    <div
      v-if="!imageUrl"
      class="image-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-image-outline</v-icon>
      <div>{{ $t('blockTypes.image.subtitle') }}</div>
    </div>
    <div
      v-if="imageUrl"
      class="image-container"
      :style="imageStyles"
    >
      <img
        :src="imageUrl"
        :height="imageHeight"
        :width="imageWidth"
        
      />
    </div>
    <v-alert
      v-if="showError"
      type="error"
      dense
      text
      class="mt-2"
    >
      {{ $t('blocks.inputBlock.imageRequired') }}
    </v-alert>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
      min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.image.menuTitle') }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="imageInput"
            :label="$t('blocks.image.urlLabel')"
            outlined
            class="mb-3"
          />
          <v-text-field
            v-model="widthInput"
            type="text"
            :label="$t('blocks.image.widthLabel')"
            outlined
            class="mb-3"
          />
          <v-text-field
            v-model="heightInput"
            type="text"
            :label="$t('blocks.image.heightLabel')"
            outlined
          />
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-end">
          <v-btn
            @click="setImage"
            :loading="loading"
            text
          >{{ $t('dialogs.saveButton') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import MenuItem from '@/components/MenuItem.vue'
import blockProps from '@/mixins/blockProps'

export default {
  mixins: [ blockProps ],
  props: {},
  data() {
    return {
      showMenu: false,
      imageInput: '',
      widthInput: '',
      heightInput: '',
      loading: false,
      menuX: 0,
      menuY: 0,
      showError: false
    }
  },
  watch: {
    imageUrl: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.imageInput = newVal
        } else {
          this.imageInput = ''
        }
      }
    },
    width: {
      immediate: true,
      handler(newVal) {
        this.widthInput = newVal || ''
      }
    },
    height: {
      immediate: true,
      handler(newVal) {
        this.heightInput = newVal || ''
      }
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    imageUrl() {
      return this.block?.imageUrl
    },
    width() {
      return this.block?.width
    },
    height() {
      return this.block?.height
    },
    imageWidth() {
      return this.width || 'auto'
    },
    imageHeight() {
      return this.height || 'auto'
    },
    imageStyles() {
      const styles = {
        maxWidth: '100%'
      }
      return styles
    },
  },
  methods: {
    openMenu(event) {
      if (event) {
        this.menuX = event.clientX - 300
        this.menuY = event.clientY
      }
      this.showMenu = true
    },
    async setImage() {
        this.loading = true
        try {
        // Handle URL
        if (this.imageInput && this.imageInput.length > 0) {
        this.block.setImageUrl(this.imageInput)
        } else {
        this.block.setImageUrl(null)
        }

        // Handle width and height using the normalizing method
        this.block.setWidth(this.normalizeUnitValue(this.widthInput))
        this.block.setHeight(this.normalizeUnitValue(this.heightInput))

        await this.block.updateValue()
        this.validateAndUpdate()
        } finally {
            this.loading = false
            this.showMenu = false
        }
    },

    normalizeUnitValue(value) {
    // Return null for empty values
    if (!value || value.trim() === '') {
        return null
    }
    
    const trimmed = value.trim()
    // Check if value already includes a unit (%, em, rem, vw, etc.)
    return /^\d+(\.\d+)?(%|px|em|rem|vh|vw|vmin|vmax)$/.test(trimmed) ? 
        trimmed : `${trimmed}px`
    },
    validateAndUpdate() {
      if (!this.block.validate()) {
        this.showError = true
        this.$emit('validationError', true)
      } else {
        this.showError = false
        this.$emit('validationError', false)
      }
    }
  },
  components: {
    BaseBlock,
    MenuItem
  }
}
</script>

<style lang="scss" scoped>
.image-empty {
  background: #F2F1EE;
  color: #75746F;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.image-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: start;
}

</style> 