import Block from '@/store/models/blocks/Block'
import { isEqual } from 'lodash'
import store from '@/store/store'
import { typeFor } from '@/constants/columnTypes'

export default class FormInputBlock extends Block {
  constructor(data) {
    super(data)
    this.formKey = data.formKey
    this.isDirty = false
  }

  get inputValue() {
    const valueKey = this[this.type].type.name
    return  this[this.type][valueKey]
  }

  set inputValue(value) {
    const valueKey = this[this.type].type.name
    if (isEqual(value, this[this.type][valueKey])) {
      return
    }
    this[this.type][valueKey] = value
    this.isDirty = true
  }

  get label() {
    return this[this.type].label
  }

  get valueType() {
    return this[this.type].type
  }

  set valueType(newVal) {
    this[this.type].type = newVal
  }

  async setLabel(newVal) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: { [this.type]: { label: newVal } }
    })
    this[this.type].label = newVal
  }

  get required() {
    return this[this.type].required
  }

  async setRequired(newVal) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: { [this.type]: { required: newVal } }
    })
    this[this.type].required = newVal
  }

  get readonly() {
    return this[this.type].readonly
  }

  async setReadonly(newVal) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: { [this.type]: { readonly: newVal } }
    })
    this[this.type].readonly = newVal
  }

  get multiline() {
    return this[this.type].multiline
  }

  async setMultiline(newVal) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: { [this.type]: { multiline: newVal } }
    })
    this[this.type].multiline = newVal
  }

  async setKey(newKey) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: { formKey: newKey }
    })
    this.formKey = newKey

  }
  validate() {
    return this.required ? this.inputValue !== undefined && this.inputValue !== null : true 
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    const valueKey = this[this.type].type.name 
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        [this.type]: {
          [valueKey]: this[this.type][valueKey]
        }
      }
    })
    this.isDirty = false
  }

  get columnType() {
    const typeName = this.valueType?.name
    return typeFor(typeName)
  }

  get isSetup() {
    return true 
  }

  get hasBlockInputs() {
    return true
  }
  get submitFormKey() {
    return this.formKey ?? this.label
  }
}