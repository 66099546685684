<template>
  <div class="d-flex resourceCell">
    <div class="d-flex flex-row align-center">
      <v-select
        ref="input"
        :value="model"
        @input="setResource"
        class="invisible"
        :items="items"
        :menu-props="{offsetY: true, bottom: true}"
        item-text="displayValue"
        item-value="_links.self.href"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        :disabled="readonly"
      >
      <template v-slot:item="{ item, index }">
        <ResourceItemDisplay 
          :resource="item" 
          :clickable="readonly"
          :data-testid="`resource-item-${index}`"
        />
      </template>
      </v-select>
      <div v-if="model != null" class="d-flex align-center">
        <ResourceItemDisplay 
          :resource="model" 
          class="ml-2"
          :clickable="readonly"
        >
          <template v-if="!readonly">
            <v-btn icon x-small class="ml-1" @click="clear">
              <v-icon small>mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </ResourceItemDisplay>
      </div>
    </div>
  </div>
</template>

<script>
import { columnTypes } from '@/constants/columnTypes'
import externalModel from '@/mixins/externalModel'
import { gridCells } from '@/mixins/gridCells.js'
import resourceInputMixin from '@/mixins/resourceInputMixin'
import ResourceItemDisplay from '@/components/ResourceItemDisplay.vue'

export default {
  mixins: [ gridCells, externalModel, resourceInputMixin ],
  components: {
    ResourceItemDisplay
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    model() {
      if (!this.externalModel) return null
      if (!this.items.length) return this.externalModel

      const modelHref = this.externalModel?._links?.self?.href || this.externalModel?.href
      return this.items.find(item =>
        item._links?.self.href === modelHref
      ) || this.externalModel
    },
    modelName() {
      return columnTypes.resource.displayFormat(this.model)
    }
  },
  mounted() {
    this.loadResources()
  },
  methods: {
    setResource(newVal) {
      const item = this.items.find(item => item._links?.self.href === newVal)
      this.externalModel = { href: item._links.self.href }
      this.finishEditing()
    },
    activateSelect() {
      this.$refs.input.activateMenu()
    },
    clear() {
      this.externalModel = null
      this.finishEditing()
    },
    finishEditing() {
      this.$emit('blur')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.resourceCell {
  position: relative;
  overflow: hidden;
}

.invisible {
  opacity: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

.clear-butdton {
  background: white;
  right: 4px;
  top: 4px;
  height: 24px;
  width: 24px;
  border-radius: 4px !important;
}
</style>