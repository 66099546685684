<template>
  <v-avatar :size="size" v-ripple class="avatar-container" :style="fallbackStyle">
    <img
      v-if="!displayFallback"
      :src="avatarUrl"
      :style="imageStyle"
    >
    <div class="fallback-avatar" v-else>
      {{fallbackText}}
    </div>
  </v-avatar>
</template>

<script>
import axios from 'axios'
import { darkColor } from '@/utils/color'

export default {
  props: {
    userId: {
      type: String,
      default: null
    },
    size: {
      type: [Number, String],
      default: 40
    },
    initials: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      displayFallback: true,
      fallbackColors: [
        '#ff453aff', '#007affff', '#5856D6', '#30d158ff', '#ffd60aff'
      ]
    }
  },
  computed: {
    avatarUrl(){
      if (this.userId) {
        return this.$store.getters.avatarUrlOf(this.userId)
      }
      return this.$store.getters.avatarSrc || 'avatar.svg'
    },
    imageStyle() {
      return {
        height:`${this.size}px !important`,
        width: 'unset'
      }
    },
    fallbackText() {
      // If no initials are provided, return the fallback character
      if (!this.initials) {
        return '?'
      }
      
      const initials = this.initials
      
      // Handle email addresses
      if (initials.includes('@')) {
        // For email addresses like christian.d@2denker or christian@2denkerer
        const localPart = initials.split('@')[0]
        
        if (localPart.includes('.')) {
          // For name.surname@domain format
          const parts = localPart.split('.')
          return (parts[0].charAt(0) + parts[1].charAt(0)).toUpperCase()
        } else {
          // For name@domain format, use first two characters of name
          return localPart.slice(0, 2).toUpperCase()
        }
      }
      
      // Handle full names with spaces
      if (initials.includes(' ')) {
        const nameParts = initials.split(' ')
        // Use first and last initials if there are more than 2 parts
        if (nameParts.length > 2) {
          return (nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0)).toUpperCase()
        }
        // For two-part names, continue using first and second part
        return (nameParts[0].charAt(0) + (nameParts[1] ? nameParts[1].charAt(0) : '')).toUpperCase()
      }
      
      // Default: use first two characters
      return initials.slice(0, 2).toUpperCase()
    },
    fallbackBackground() {
      return this.fallbackColors[this.fallbackText.charCodeAt(0) % this.fallbackColors.length]
    },
    fallbackTextColor() {
      return darkColor(this.fallbackBackground) ? 'rgba(0, 0, 0, 0.54)' : 'white'
    },
    fallbackStyle() {
      return this.displayFallback ? {
        color: this.fallbackTextColor,
        background: this.fallbackBackground
      } : {}
    }
  },
  watch: {
    avatarUrl: {
      immediate: true,
      async handler(newVal) {
        if (!newVal || newVal === 'avatar.svg') {
          this.displayFallback = true
          return
        }
        
        try {
          await axios.head(newVal, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          })
          this.displayFallback = false
        } catch {
          this.displayFallback = true
        }
      }
    }
  }
}
</script>

<style scoped>
.avatar-container {
  container-type: inline-size;
}

.fallback-avatar {
  font-size: 50cqi;
}
</style>