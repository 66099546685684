import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'

export default {
  mixins: ['blockProps'],
  data() {
    return {
      blockPickerIndex: null,
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },

    canEdit() {
      return hasPermission(this.block, [PERMISSIONS.patch]) && this.editorMode && (!this.$vuetify.breakpoint.mobile || this.inEditMode)
    },

    canAddChild() {
        return hasPermission(this.block, [PERMISSIONS.addChild]) && this.editorMode && (!this.$vuetify.breakpoint.mobile || this.inEditMode)
    },

    canUpdate() {
        return hasPermission(this.block, [PERMISSIONS.update]) && this.editorMode
    },

    children() {
      return this.block?.children || []
    }
  },

  methods: {

    async addChild(type, position, defaultProps = {}) {
      if (!this.canEdit || type == null) {
        return
      }

      const newBlock = await this.$store.dispatch('AGAddBlockChild', {
        block: this.block,
        type: type,
        position: position,
        blockProps: {
          [type]: {
            ...defaultProps
          }
        }
      })

      this.focus(newBlock)
      return newBlock
    },

    async mergeParagraphs(currentBlock) {
        const currentIndex = this.block.children.findIndex(child => child.uri === currentBlock.uri)
        if (currentIndex > 0) {
          const previousBlock = this.block.children[currentIndex - 1]
          if (previousBlock && previousBlock.type === 'paragraph') {
            // Update UI state immediately
            const mergedText = previousBlock.text + currentBlock.text
            previousBlock.text = mergedText
            currentBlock.deleted = true
            
            // Remove from children array immediately for UI update
            this.block.children.splice(currentIndex, 1)
            
            // Focus the previous block at the end
            await this.$nextTick()
            this.$refs[`block_${previousBlock.id}`][0].focus()
            
            // Then update the backend
            await previousBlock.updateValue()
            await currentBlock.deleteBlockIn(this.block)
          }
        }
      },
      async switchBlockType(newBlockType, block) {
        if (!this.canEdit) {
          return
        }
        // Store position before deletion
        const position = this.block.children.findIndex(child => child.uri === block.uri)
        const oldText = block.text
        
        // First delete the old block and wait for it to complete
        await this.deleteChild(block, false)
        
        // Wait for the next tick to ensure Vue's reactivity has processed the deletion
        await this.$nextTick()
        
        // Then create the new block
        await this.$store.dispatch('AGAddBlockChild', {
          block: this.block,
          type: newBlockType.type,
          position: position + 1,
          blockProps: {
            [newBlockType.type]: {
              ...newBlockType.defaultProps,
              text: oldText
            }
          }
        })
        await this.loadBlock()
      },

      async loadBlock() {
        try {
          if(this.block) {
            await this.block.reload()
          }
          else {
            console.log('page loads block')
            await this.$store.dispatch('AGReadBlock', { blockUri: this.blockUri, customErrorHandling: [400]})
          }
          // New pages come with an empty header, focus it
          if (this.block.children.length === 1 && this.block.children[0] instanceof HeaderBlock && !this.block.children[0].text) {
            await this.$nextTick()
            this.focus(this.block.children[0])
          }
        } catch (error) { 
            if (error.response?.status === 400) {
              this.$emit('unaccessibleView')
            }
        }
      },

    async deleteChild(child, reload = true) {
      if (!this.canEdit) {
        return
      }
      
      child.deleted = true
      await child.deleteBlockIn(this.block)
      
      if (reload) {
        await this.block.reload()
      }
    },

    async addParagraphWithText(text, position, focusPicker = true) {
        if (!this.canEdit) {
          return
        }
        const newParagraphBlock = await this.$store.dispatch('AGAddBlockChild', {
          block: this.block,
          type: 'paragraph',
          position: position
        })
        if (text) {
          newParagraphBlock.text = text
          await newParagraphBlock.updateValue()
        }
  
        await this.$nextTick()
        if (focusPicker) {
          this.blockPickerIndex = position
        } else {
          this.focus(newParagraphBlock)
          this.blockPickerIndex = null
        }
      },

    async updateBlock(block) {
      if (block.deleted || !this.canEdit) {
        return
      }
      block.updateValue()
    },

    component(block) {
      return block.blockType.component
    },

    focus(block) {
        if(!block) return
      this.$nextTick(() => {
        const ref = this.$refs[`block_${block.id}`]
        if (ref && ref[0] && typeof ref[0].focus === 'function') {
          ref[0].focus()
        }
      })
    },

    /**
     * Handler for drag and drop reordering of children
     */
    onDragChange() {
      if (!this.canEdit) return
      this.block.update()
    }
  }
} 