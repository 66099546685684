<template>
  <div
    class="pl-2 cell border-right d-flex align-center"
    :class="classesFromData"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <ResourceItemDisplay
      v-for="(item, index) in valueArray"
      :key="index"
      :resource="item"
      class="mr-1"
      :clickable="true"
    />
  </div>
</template>

<script>
import { noValue } from '@/utils/jsUtils.js'
import { classesFromData } from '@/utils/vueUtils.js'
import ResourceItemDisplay from '@/components/ResourceItemDisplay.vue'

export default {
  name: 'RoGridResourceCell',
  inheritAttrs: false,
  components: {
    ResourceItemDisplay
  },
  props: {
    value: null
  },
  computed: {
    valueArray() {
      if (noValue(this.value)) {
        return []
      }
      const array = Array.isArray(this.value) ? this.value : [this.value]
      return array
    },
    classesFromData() {
      return classesFromData({ staticClass: this.$vnode.data.staticClass })
    }
  }
}
</script>