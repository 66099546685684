<template>
  <v-menu offset-y v-model="dialog" :close-on-content-click="false" max-width="480">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="dialogButton"></slot>
      </div>
    </template>
    <v-card min-width="320">
      <v-card-title>
        <span v-html="title" />
      </v-card-title>
      <v-card-subtitle>
        {{ $t("blocks.shareDialog.subtitle") }}
      </v-card-subtitle>
      <v-card-text v-if="!loading">
        <BlockPageHook 
          v-for="hook in hooks"
          class="mb-3"
          :key="hook.uri"
          :link="hook"
          @linkDeleted="loadHooks"
        />
        <v-btn v-if="hooks && hooks.length == 0" @click="createBlockHook" color="primary" :loading="loading">
          {{ $t("blocks.shareDialog.buttons.publish") }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import BlockPageHook from './BlockPageHook.vue'

export default {
  props: {
    blockUri: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      hooks: [],
      loading: false
    }
  },
  watch: {
    dialog: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.loading = true
          try {
            await this.loadHooks()
          } finally {
            this.loading = false
          }
        }
      }
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    title() {
      return this.$t('blocks.shareDialog.title', {blockName: this.block.name})
    }
  },
  methods: {
    async loadHooks() {
      const blockLinks = await this.$store.dispatch('AGListBlockHooks', {block: this.block})
      this.hooks = blockLinks
    },
    async createBlockHook() {
      this.loading = true
      try {
        await this.$store.dispatch('AGCreateBlockHook', {
          block: this.block,
          role: 'reader'
        })
        await this.loadHooks()
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    BlockPageHook
  }
}
</script> 
