import colors from 'vuetify/lib/util/colors'

export const ViewIconTypes = {
  GRID: {
    icon: 'mdi-table',
    color: '#1664c0'
  },
  FORM: {
    icon: 'mdi-form-textbox',
    color: '#E54B4B'
  },
  FLOW_FORM: {
    icon: 'mdi-sitemap-outline',
    color: '#AF52DE'
  },
  KANBAN: {
    icon: 'mdi-trello',
    color: '#1A9E27'
  },
  CALENDAR: {
    icon: 'mdi-calendar',
    color: '#FFAD20'
  },
  MAP: {
    icon: 'mdi-google-maps',
    color: '#399DE9'
  },
  ACCESS_KEY: {
    icon: 'mdi-key-outline',
    color: colors.blue.darken3
  },
  GALLERY: {
    icon: 'mdi-view-grid',
    color: '#893FB7'
  },
  LIST: {
    icon: 'mdi-format-list-bulleted',
    color: '#FF6D00'
  },
  BLOCK: {
    icon: 'mdi-file-document-outline',
    color: '#6D4C41'
  },
  UNKNOWN: {
    icon: 'mdi-help',
    color: '#F4511E'
  },
}
Object.freeze(ViewIconTypes)
