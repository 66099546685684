<template>
  <div>
    <template v-if="iconset === 'emoji'">
      <span
        v-bind="$attrs"
        v-on="$listeners"
        class="space-icon emoji-icon"
        :style="{'background-color': backGroundColor, ...constants}"
      >{{ iconName }}</span>
    </template>
    <template v-else-if="iconset === 'file'">
      <span
        v-bind="$attrs"
        v-on="$listeners"
        class="space-icon file-icon-container"
        :style="{'background-color': backGroundColor, ...constants}"
      >
        <img class="file-icon" :src="iconName" :alt="$t('icon.fileIcon')" />
      </span>
    </template>
    <v-icon
      v-else
      v-bind="$attrs"
      v-on="$listeners"
      class="space-icon"
      :style="{'background-color': backGroundColor, ...constants}"
      :color="color"
    >{{`mdi-${iconName}`}}</v-icon>
  </div>
</template>


<script>
export const ICON_SIZE = 24

import { darkColor } from '@/utils/color'
  export default {
    props: {
      space: {
        type: Object,
        default: undefined
      },
      icon: {
        type: String,
        default: 'table'
      },
      backgroundColor: {
        type: String,
        default: undefined
      },
      inverted: {
        type: Boolean,
        default: false
      },
      iconset: {
        type: String,
        default: 'mdi'
      }
    },
    computed: {

      iconName() {
        return this.space?.icon ?? this.icon
      },
      baseColor() {
        return this.backgroundColor ?? this.space?.color ?? this.$vuetify.theme.themes.light.space
      },
      backGroundColor() {
        return this.inverted ? (darkColor(this.baseColor) ? 'white' : 'white') : this.baseColor
      },
      color() {
        if (this.inverted) {
          return this.baseColor
        }
        return darkColor(this.backGroundColor) ? 'black' : 'white'
      },
      constants() {
        return {
          '--icon-size': ICON_SIZE + 'px'
        }
      }
    }
  }
</script>

<style scoped>
.space-icon {
  margin-top: 0px;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 20%;
  font-size: calc(var(--icon-size) - 6px);
}

.emoji-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.file-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 20%;
}

.file-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20%;
}
</style>