<template>
  <div class="app" :style="{ height: '100%' }">
      <div
        v-if="state === LoadingStates.Success"
        class="d-flex flex-column justify-center align-center"
        style="height: 100%; width: 100%"
      >
        <lottie
            :options="successAnimationOptions"
            :height="100"
            :width="100"
            v-on:animCreated="handleSuccessAnimation"
          />
          <div>
            <div class="text-h4 text-center">
              {{ $t('forms.view.successTitle') }}
            </div>
            <div class="mt-2 text-center">
              {{ successMessage || $t('forms.view.successMessage') }}
            </div>
          </div>
          <div class="d-flex justify-center mt-4">
        <FormBadge  ></FormBadge>
      </div>
      </div>
      <div v-else-if="state === LoadingStates.Warning" 
        class="d-flex flex-column justify-center align-center"
        style="height: 100%; width: 100%"
      >
        <lottie
          :options="warningAnimationOptions"
          :height="100"
          :width="100"
          v-on:animCreated="handleWarningAnimation"
        />
        <div class="text-center mx-3">
          {{ hintMessage }}
        </div>
      </div>
      <div v-else-if="state === LoadingStates.Error" 
        class="d-flex flex-column justify-center align-center"
        style="height: 100%; width: 100%"
      >
        <lottie
          :options="errorAnimationOptions"
          :height="100"
          :width="100"
          v-on:animCreated="handleErrorAnimation"
        />
        <div>
          <div class="text-center mx-3">
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <div v-else-if="state === LoadingStates.Loaded">
        <FlowFormBlockPage
          ref="formPage"
          :block="block"
          @unaccessibleView="handleUnaccessibleView"
        />
      <v-container >
        <v-row justify="center">
          <v-col
              cols="12"
              sm="10"
              md="9"
              lg="8"
            >
            <v-row justify="end">
                <v-btn
                  v-if="canSubmit && state === LoadingStates.Loaded"
                  :loading="submitting"
                  class="mr-6 mb-8"
                  @click="submitBlock"
                  color="primary"
                >{{submitButtonTitle}}</v-btn>
            </v-row >
          </v-col>
        </v-row >
      </v-container>
  </div>
    <LoadingOverlay :show="state === LoadingStates.Loading" />
    <LoginDialog
      @credentialsProvided="loadView"
      v-model="loginDialog"
      :resource="flowInstanceLinkUri"
    />
  </div>

</template>

<script>
import { LoadingStates } from '@/utils/loadingStates.js'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import FlowFormBlockPage from '@/components/block/FlowFormBlockPage.vue'
import Lottie from 'vue-lottie'
import * as successAnimationData from '@/assets/success.json'
import * as errorAnimationData from '@/assets/error.json'
import * as infoAnimationData from '@/assets/info.json'

import ApiClient from '@/ApiClient'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus'
import LoginDialog from '@/components/LoginDialog.vue'
import FormBadge from '@/components/form/FormBadge.vue'

export default {
  props: ['uri'],
  data() {
    return {
      state: LoadingStates.Initial,
      submitting: false,
      errorMessage: undefined,
      flowInstanceLinkUri: null,
      block: null,
      loginDialog: false,
      hintMessage: undefined,
      successAnimationOptions: {
        animationData: successAnimationData.default,
        loop: false,
        color: 'red'
      },
      errorAnimationOptions: {
        animationData: errorAnimationData.default,
        loop: false
      },
      warningAnimationOptions: {
        animationData: infoAnimationData.default,
        loop: false,
      },
      LoadingStates
    }
  },
  mounted() {
    if(!this.uri) {
      // here we get the uri from the search params as vue router ignores queries that are before the /#view/
      const urlParams = new URLSearchParams(window.location.search)
      this.flowInstanceLinkUri = urlParams.get('uri') 
    }
    else if(this.uri)  {
      this.flowInstanceLinkUri = this.uri
    }
    linkAuthorizationNeededBus.onLinkAuthorizationNeeded(this.onLinkAuthorizationNeeded)
    this.loadView()
  },
  beforeDestroy() {
    linkAuthorizationNeededBus.linkAuthorizationNeededOff(this.onLinkAuthorizationNeeded)
  },
  methods: {
    onLinkAuthorizationNeeded() {
      // show login
      this.loginDialog = true
    },
    credentialsProvided() {
      this.canLogout = true
      this.loadView()
    },
    async loadView() {
      this.state = LoadingStates.Loading
      try {
        const newBlock = await this.$store.dispatch('AGReadBlock', { 
          blockUri: this.flowInstanceLinkUri,  
          customErrorHandling: [404, 400] 
        })
        this.block = newBlock
        this.state = LoadingStates.Loaded
      } catch (error) { 
        if (error.response?.status === 404) {
          this.errorMessage = this.$t('errorMessages.unaccessibleView')
        }
        else if (error.response?.status === 400) {
          this.hintMessage = this.$t('errorMessages.noNextFlowFormPage')
          this.state = LoadingStates.Warning
          return
        } else {
          this.errorMessage = error.response.data.description
        }
        this.state = LoadingStates.Error
      }
    },
    handleUnaccessibleView() {
      this.errorMessage = this.$t('errorMessages.unaccessibleView')
      this.state = LoadingStates.Error
    },
    async submitBlock() {
      if (!this.validated) {
        // Get the form page component
        const formPage = this.$refs.formPage
        // Trigger validation on all block components
        this.block.children.forEach(child => {
          const blockRef = formPage.$refs[`block_${child.id}`]
          if (blockRef && blockRef[0]) {
            blockRef[0].validateAndUpdate?.()
          }
        })
        return
      }

      // collect values 
      const payload = this.block.payload()
      
      // Add query parameters to payload only if they don't exist
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.forEach((value, key) => {
        // Skip the 'uri' parameter since it's used for routing
        if (key !== 'uri' && !(key in payload)) {
          payload[key] = value
        }
      })

      try {
        this.submitting = true
        const response = await ApiClient.postUri(this.submitActionUri, payload)
        const aLink = response.headers.location
        this.flowInstanceLinkUri = aLink
        await this.loadView()
      } finally {
        this.submitting = false
      }
    },
    logout() {
      //reload view to rest auth setting in api client
      window.location.reload()
    },
    handleSuccessAnimation(animation) {
      animation.play()
    },
    handleErrorAnimation(animation) {
      animation.play()
    },
    handleWarningAnimation(animation) {
      animation.play()
    }
  },
  computed: {
    canSubmit() {
      return this.submitAction
    },
    validated() {
      return this.block?.children?.every(child => child.validate())
    },
    submitButtonTitle() {
      const actionName = this.block?.actions?.length > 0 ? this.block?.actions[0].name : this.$t('blocks.submitPage')
      return actionName ?? this.$t('blocks.submitPage')
    },
    submitAction() {
      return this.block?.actions?.length > 0
    },
    submitActionUri() {
      return this.block?.actions?.length > 0 ? this.block?.actions[0].uri : null
    }
  },
  components: {
    LoadingOverlay,
    FlowFormBlockPage,
    lottie: Lottie,
    LoginDialog,
    FormBadge
  }
}
</script>

<style scoped>
.app {
  background-color: white;
}


.error-display {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>