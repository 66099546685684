import store from '../store'
import CardViewBase from './CardViewBase'
import { columnTypes } from '@/constants/columnTypes.js'

// Constants for ListView properties
export const LIST_VIEW_POSITION = 'listPosition'
export const LIST_VIEW_HIDE_FIELD_TYPE_ICON = 'hideFieldTypeIcon'
const INITIAL_ORDER_INCREMENT = 100000 // Initial large increment value
const MINIMUM_POSITION = 1 // Ensures no position goes below this

export default class ListView extends CardViewBase {
  constructor(data) {
    super(data)
    this.timeoutMap = {}
    this.migrate()
  }

  migrate() {
    // Initialize the LIST_VIEW_POSITION if it doesn't exist
    if (!this.properties[LIST_VIEW_POSITION]) {
      this.properties[LIST_VIEW_POSITION] = {}
    }
  }

  get cardPositionKey() {
    return LIST_VIEW_POSITION
  }

  reload() {
    this.timeoutMap = {}
    return store().dispatch('AGReadStatefulViewOperation', this.uri)
  }

  // Method to get the position of an entity, or assign an initial order based on its index
  entityPosition(entityId, entityIndex = 0) {
    // Check if the entity has an existing position
    if (this.properties[LIST_VIEW_POSITION][entityId] !== undefined) {
      return this.properties[LIST_VIEW_POSITION][entityId]
    }

    // If no position exists, assign a default based on the entity index
    const initialPosition = (entityIndex + 1) * INITIAL_ORDER_INCREMENT
    this.setEntityPosition(entityId, initialPosition, false) // Persist the initial order
    return initialPosition
  }

  setEntityPosition(entityId, position, persist = true) {
    // Ensure the LIST_VIEW_POSITION object exists
    if (!this.properties[LIST_VIEW_POSITION]) {
      this.properties[LIST_VIEW_POSITION] = {}
    }
    this.properties[LIST_VIEW_POSITION][entityId] = position
    if (persist) {
      return this.persist()
    }
    return undefined
  }

  shouldLoadEntity(entity, grid) {
    // Called when a list item is mounted and the entity is not loaded
    // Load page that might contain that entity 
    let pageIndex = entity.pageIndex
    if (this.timeoutMap[pageIndex]) {
      // A call was scheduled for this pageId
      return
    }
    // No call scheduled for this page
    const timeoutId = setTimeout(() => grid.entitiesPager.loadPage(entity.pageIndex), 1000)
    this.timeoutMap[pageIndex] = timeoutId
  }

  get hideTypeIcon() {
    return this.properties[LIST_VIEW_HIDE_FIELD_TYPE_ICON] || false
  }

  set hideTypeIcon(newVal) {
    this.properties[LIST_VIEW_HIDE_FIELD_TYPE_ICON] = newVal
  }

  // Utility method to clean up ordering data
  cleanUpOrdering(grid) {
    if (!grid?.entities?.length) return
    const currentEntityIds = grid.entities.map(entity => entity._id)
    const orderingEntityIds = Object.keys(this.properties[LIST_VIEW_POSITION] || {})

    // Filter out the entities in the ordering that no longer exist in the grid
    const updatedOrdering = orderingEntityIds.reduce((ordering, entityId) => {
      if (currentEntityIds.includes(entityId)) {
        ordering[entityId] = this.properties[LIST_VIEW_POSITION][entityId]
      }
      return ordering
    }, {})
    // Update the LIST_VIEW_POSITION property with the cleaned-up data
    this.properties[LIST_VIEW_POSITION] = updatedOrdering
  }

  // Utility method to shift all positions forward
  shiftPositionsForward(currentItems) {
    for (let i = 0; i < currentItems.length; i++) {
      const entity = currentItems[i].entity
      if (entity) {
        const currentPosition = this.entityPosition(entity._id)
        this.setEntityPosition(entity._id, currentPosition + INITIAL_ORDER_INCREMENT, false)
      }
    }
    this.persist()
  }

  persist() {
    return store().dispatch('AGPatchStatefulViewOperation', {
      statefulView: this,
      properties: this.properties
    })
  }

  // Utility method to shift positions forward from a specific index
  shiftPositionsForwardFrom(startIndex, currentItems) {
    for (let i = startIndex; i < currentItems.length; i++) {
      const entity = currentItems[i].entity
      if (entity) {
        const currentPosition = this.entityPosition(entity._id)
        this.setEntityPosition(entity._id, currentPosition + INITIAL_ORDER_INCREMENT, false)
      }
    }
    this.persist()
  }

  // Method to reorder a single entity
  reorderEntity(entityId, newIndex, currentItems) {

    let newPosition

    // If the list has only one item, assign a default initial position
    if (currentItems.length === 1) {
      return INITIAL_ORDER_INCREMENT
    }

    if (newIndex === 0) {
      // If moved to the first position
      const nextEntity = currentItems[1].entity
      const nextPosition = this.entityPosition(nextEntity._id)
      newPosition = Math.floor(nextPosition / 2)
   
      if (newPosition < MINIMUM_POSITION) {
        this.shiftPositionsForward(currentItems)
        newPosition = INITIAL_ORDER_INCREMENT
      }
    } else if (newIndex === currentItems.length - 1) {
      // If moved to the last position
      const previousEntity = currentItems[currentItems.length - 2].entity
      const previousPosition = this.entityPosition(previousEntity._id)
      newPosition = previousPosition + INITIAL_ORDER_INCREMENT
      
    
    } else {
      // If moved between two items
      const previousEntity = currentItems[newIndex - 1].entity
      const nextEntity = currentItems[newIndex + 1].entity
      const previousPosition = this.entityPosition(previousEntity._id)
      const nextPosition = this.entityPosition(nextEntity._id)
      
      // Ensure we have enough space between positions
      if (nextPosition - previousPosition <= 1) {
        this.shiftPositionsForwardFrom(newIndex, currentItems)
        newPosition = previousPosition + INITIAL_ORDER_INCREMENT
      } else {
        newPosition = previousPosition + Math.floor((nextPosition - previousPosition) / 2)
      }
    }

    this.setEntityPosition(entityId, newPosition)
    return newPosition
  }

  // Method to get the first Boolean field from an item's attributes
  getBooleanField(item) {
    const boolField = item.attributes.find(attr => attr.property?.name === 'boolean')
    return boolField
  }

  // Method to get the value of the Boolean field
  getBooleanValue(item) {
    const boolField = this.getBooleanField(item)
    return boolField ? boolField.fieldValue : false
  }

  // Method to update a Boolean field value
  async updateBooleanValue(item, newValue) {
    if (!item.entity) return
    
    const boolField = this.getBooleanField(item)
    if (!boolField) return

    // Find the field in the grid
    const field = this.parentGrid.fields.find(f => f.id === boolField.fieldId)
    if (!field) return

    // Update the entity
    this.parentGrid.entityChanged(item.entity, newValue, field)
    await this.parentGrid.updateEntity(item.entity)
  }

  // Method to get the first user field from an item's attributes
  getUserField(item) {
    return item.attributes.find(attr => attr.property === columnTypes.user)
  }

  // Method to get the user value
  getUserValue(item) {
    const userField = this.getUserField(item)
    return userField ? userField.fieldValue : null
  }

  // Function to create an array of list item models
  listModel(grid) {
    // Clean up the ordering before constructing the model
    this.cleanUpOrdering(grid)

    // Get the total number of items and page size from the entitiesPager object
    let numberOfItems = grid.entitiesPager?.numberOfItems || 0
    let pageSize = grid.entitiesPager?.size || 1

    // Check if we need to initialize positions
    const needsInitialization = grid.entities.some(entity => 
      this.properties[LIST_VIEW_POSITION][entity._id] === undefined
    )

    // Initialize positions for all entities if needed
    if (needsInitialization) {
      const positions = {}
      grid.entities.forEach((entity, index) => {
        positions[entity._id] = (index + 1) * INITIAL_ORDER_INCREMENT
      })
      this.properties[LIST_VIEW_POSITION] = {
        ...this.properties[LIST_VIEW_POSITION],
        ...positions
      }
      // Persist the initial positions
      this.persist()
    }

    // Create an array to store the list item models
    const items = []

    // Loop through all entities and create list item models
    for (let i = 0; i < numberOfItems; i++) {
      let pageIndex = Math.ceil((i + 1) / pageSize)
      items.push(this.itemModel(grid.entities[i], grid, pageIndex))
    }

    // Sort items based on their positions
    items.sort((a, b) => {
      if (!a.entity || !b.entity) return 0
      return a.position - b.position
    })

    return items
  }

  itemModel(entity, grid, pageIndex) {
    const getAttribute = (entityField, index) => ({
      key: grid.fields[index]?.name,
      value: entityField ? grid.displayFormat(entityField, index) : null,
      fieldValue: entityField,
      fieldId: grid.fields[index]?.id,
      property: grid.fields[index]?.columnType,
    })

    const getPlaceholderAttribute = (gridField, index) => ({
      key: grid.fields[index]?.name,
      value: null,
      fieldValue: null,
      fieldId: grid.fields[index]?.id,
      property: grid.fields[index]?.columnType,
    })

    const attributes = entity
      ? entity.fields.map(getAttribute).filter(attr => attr.key)
      : grid.fields.map(getPlaceholderAttribute).filter(attr => attr.key)

    // Get the title field (first shown field)
    const titleAttribute = attributes
      .filter(attr => this.fieldIsShown(attr.fieldId) && attr.property === columnTypes.string)
      .sort((a, b) => this.fieldPostion(a.fieldId) - this.fieldPostion(b.fieldId))[0]

    // Get the description field (second shown field, excluding boolean fields)
    const descriptionAttribute = attributes
      .filter(attr => this.fieldIsShown(attr.fieldId) && attr.property === columnTypes.string)
      .sort((a, b) => this.fieldPostion(a.fieldId) - this.fieldPostion(b.fieldId))[1]
    const entityIndex = grid.entities.findIndex(e => e._id === entity._id)
    return {
      entity: entity || null,
      title: titleAttribute?.value || 'Untitled',
      description: descriptionAttribute?.value || '',
      position: this.entityPosition(entity._id, entityIndex, false), // Assign initial position if necessary
      attributes: attributes
        .filter(attr => this.fieldIsShown(attr.fieldId))
        .sort((a, b) => a.position - b.position),
      pageIndex: pageIndex
    }
  }
} 