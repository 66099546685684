<template>
    <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass">
       
        <router-link 
            :to="{ name: 'BlockPage', params: { blockUri: blockUri } }" 
            class="flex-grow-1 text-decoration-none black--text">
            <div class="d-flex align-center">
                <v-icon class="mr-1">mdi-file-document-outline</v-icon>
                {{ blockName }}
             </div>
        </router-link>

    </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import blockProps from '@/mixins/blockProps'

export default {
    mixins: [ blockProps ],
    props: {
    },
    computed: {
        block() {
            return this.$store.getters.blockWithUri(this.blockUri)
        },
        blockName() {
            return this.block.name || `${this.$t('blocks.emptyPageTitle')}`
        },
    },
    components: {
        BaseBlock,
    }
}
</script>

<style lang="css" scoped>
.outlined>>>.v-input__slot {
    border: 1px solid #D0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}
</style>