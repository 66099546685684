import Block from '@/store/models/blocks/Block'
import store from '@/store/store'
import i18n from '@/plugins/i18n'

export const HEADER_LEVELS = {
  1: {
    backendName: 1,
    name: i18n.t('blocks.headers.h1'),
    class: 'header-block-h1'
  },
  2: {
    backendName: 2,
    name: i18n.t('blocks.headers.h2'),
    class: 'header-block-h2'
  },
  3: {
    backendName: 3,
    name: i18n.t('blocks.headers.h3'),
    class: 'header-block-h3'
  },
}

export default class HeaderBlock extends Block {
  constructor(data) {
    super(data)
    this.isDirty = false
  }

  get text() {
    return this.header.text
  }

  set text(newVal) {
    if (newVal === this.header.text) {
      return
    }
    this.header.text = newVal
    this.isDirty = true
  }

  get level() {
    return this.header.level ?? HEADER_LEVELS['3'].backendName
  }

  set level(newVal) {
    if (newVal === this.header.level) {
      return
    }
    this.header.level = newVal
    this.isDirty = true
  }

  get textAlign() {
    return this[this.type].annotations.textAlign
  }

  set textAlign(newVal) {
    this[this.type].annotations.textAlign = newVal
    this.updateAnnotations()
  }

  get fontWeight() {
    return this[this.type].annotations.fontWeight
  }

  set fontWeight(newVal) {
    this[this.type].annotations.fontWeight = newVal
    this.updateAnnotations()
  }

  get fontSize() {
    return this[this.type].annotations.fontSize
  }

  set fontSize(newVal) {
    this[this.type].annotations.fontSize = newVal
    this.updateAnnotations()
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        header: {
          text: this.text,
          level: this.level,
        }
      }
    })
    this.isDirty = false
  }
}