var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseBlock',_vm._g({attrs:{"blockUri":_vm.blockUri,"editorMode":_vm.editorMode,"grabbableClass":_vm.grabbableClass,"nesting":""},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('blocks.horizontalLayout.layoutDirection')))])],1),_c('SubMenu',{attrs:{"activatorText":_vm.$t('blocks.horizontalLayout.mainAxisAlignment'),"activatorIcon":"mdi-align-horizontal-distribute"}},_vm._l((_vm.mainAxisAlignments),function(align){return _c('v-list-item',{key:align.value,on:{"click":function($event){return _vm.setMainAxisAlignItems(align.value)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",class:{ 'primary--text': _vm.mainAxisAlignItems === align.value },attrs:{"size":16}},[_vm._v(_vm._s(align.icon))]),_c('div',[_vm._v(_vm._s(align.label))])],1)])}),1),_c('SubMenu',{attrs:{"activatorText":_vm.$t('blocks.horizontalLayout.counterAxisAlignment'),"activatorIcon":"mdi-align-vertical-distribute"}},_vm._l((_vm.counterAxisAlignments),function(align){return _c('v-list-item',{key:align.value,on:{"click":function($event){return _vm.setCounterAxisAlignItems(align.value)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",class:{ 'primary--text': _vm.counterAxisAlignItems === align.value },attrs:{"size":16}},[_vm._v(_vm._s(align.icon))]),_c('div',[_vm._v(_vm._s(align.label))])],1)])}),1)]},proxy:true}])},_vm.$listeners),[_c('draggable',{staticClass:"layout-block",style:({
            display: 'flex',
            flexDirection: _vm.layoutMode === 'HORIZONTAL' ? 'row' : 'column',
            justifyContent: _vm.getJustifyContent(_vm.mainAxisAlignItems),
            alignItems: _vm.getAlignItems(_vm.counterAxisAlignItems),
            flexWrap: _vm.wrap ? 'wrap' : 'nowrap',
            gap: '3rem'
        }),attrs:{"disabled":!_vm.canEdit,"handle":".grabbable","ghost-class":"ghost-card","animation":200,"delay":120,"delayOnTouchOnly":true,"direction":_vm.layoutMode === 'HORIZONTAL' ? 'horizontal' : 'vertical'},on:{"change":_vm.onDragChange},model:{value:(_vm.block.children),callback:function ($$v) {_vm.$set(_vm.block, "children", $$v)},expression:"block.children"}},[_vm._l((_vm.block.children),function(child,i){return _c('div',{key:i,staticClass:"layout-block-child"},[_c('div',{staticClass:"layout-block-child-content"},[_c(_vm.component(child),{ref:`block_${child.id}`,refInFor:true,tag:"component",attrs:{"blockUri":child.uri,"editorMode":_vm.editorMode,"grabbableClass":_vm.canEdit ? 'grabbable' : ''},on:{"delete":function($event){return _vm.deleteChild(child)},"updateBlock":() => _vm.updateBlock(child),"appendParagraphWithText":text => _vm.addParagraphWithText(text, i + 2, false),"appendEmptyParagraph":() => _vm.blockPickerIndex = i + 1,"mergeParagraphs":_vm.mergeParagraphs,"switchBlockType":_vm.switchBlockType}}),(_vm.blockPickerIndex === i + 1)?_c('BlockPicker',{attrs:{"autofocus":true,"defaultOpen":true},on:{"blockSelected":(type, defaultProps) => _vm.addChild(type, i + 2, defaultProps),"appendParagraphWithText":text => _vm.addParagraphWithText(text, i + 2, false),"addParagraphBlock":text => _vm.addParagraphWithText(text, i + 2),"removeBlockPicker":function($event){_vm.blockPickerIndex = null},"appendEmptyParagraph":() => _vm.blockPickerIndex = i + 1}}):_vm._e()],1)])}),(_vm.editorMode && _vm.canEdit && !_vm.block.hasChildren)?_c('div',{staticClass:"add-block-wrapper"},[_c('BlockPicker',{attrs:{"pickerOnly":true},on:{"blockSelected":(type, defaultProps) => _vm.addChild(type, _vm.block.children.length + 1, defaultProps),"addParagraphBlock":text => _vm.addParagraphBlock(text, _vm.block.children.length + 1)}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }