<template>
  <div>
    <template v-if="iconset === 'emoji'">
      <span
        class="navigation-icon emoji-icon"
      >{{ icon }}</span>
    </template>
    <template v-else-if="iconset === 'file'">
      <span
        class="navigation-icon file-icon-container"
        :style="{'background-color': color}"
      >
        <img class="file-icon" :src="icon" :alt="$t('icon.fileIcon')" />
      </span>
    </template>
    <v-icon
      v-else
      :color="color || iconColor"
      class="navigation-icon"
    >{{ iconPrefixed }}</v-icon>
  </div>
</template>

<script>

export default {
  name: 'BlockNavigationIcon',
  props: {
    icon: {
      type: String,
      default: null
    },
    iconset: {
      type: String,
      default: 'mdi'
    },
    color: {
      type: String,
      default: null
    },
    defaultIcon: {
      type: String,
      default: null
    },
    smallIcon: {
      type: Boolean,
      default: false
    },
    standalone: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconPrefixed() {
      if (!this.icon) {
        if (this.smallIcon) {
          return 'mdi-circle-small'
        } else if (this.defaultIcon) {
          return `mdi-${this.defaultIcon}`
        } else if (this.standalone) {
          return 'mdi-circle-medium'
        } else {
          return 'mdi-file-document-outline'
        }
      }
      return `mdi-${this.icon}`
    },
    iconColor() {
      // Default icon color when no backgroundColor is provided
      return 'inherit'
    }
  }
}
</script>

<style scoped>
.navigation-icon {
  margin-top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 20%;
  font-size: 18px;
}

.emoji-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.file-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20%;
}
</style> 