import Vue from 'vue'
import ApiClient from '@/ApiClient'
import { PERMISSIONS } from '../../utils/halUtils'
import statefulViewTypes from '@/constants/statefulViewTypes.js'

export default {
  state() {
    return {
      statefulViews: {},
      statefulViewLists: {}
    }
  },
  mutations: {
    addStatefulView(state, statefulView) {
      Vue.set(state.statefulViews, statefulView.uri, statefulView)
    },
    addStatefulViewList(state, { gridUri, statefulViewList}) {
      Vue.set(state.statefulViewLists, gridUri, statefulViewList)
    }
  },
  actions: {
    async AGAddStatefulViewOperation(context, { grid, name, type, fieldIds, properties, slotProperties, virtualGridParams }) {
      const virtualGrid = await context.dispatch('AGAddVirtualGridOperation', {
        gridUri: grid.uri,
        fields: grid.shownFieldIds(),
        ...virtualGridParams
      })
      const link = virtualGrid.getLink(PERMISSIONS.addSView)
      const response = await ApiClient.postUri(link, {
        name,
        type,
        fieldIds,
        properties,
        slotProperties,
      })
      context.dispatch('AGListStatefulViewsOperation', grid)
      return context.dispatch('AGReadStatefulViewOperation', response.headers.location)
    },
    AGAddSpreadsheetViewOperation(context, { grid, virtualGridParams }) {
      return context.dispatch('AGAddStatefulViewOperation', {
        grid,
        name: 'Spreadsheet View',
        type: statefulViewTypes.spreadsheet.name,
        fieldIds: grid.shownFieldIds(),
        properties: { version: statefulViewTypes.spreadsheet.version },
        virtualGridParams
      })
    },
    AGReadStatefulViewOperation(context, { uri, cached = false,  customErrorHandling }) {
      uri = uri ?? arguments[1]
      const cachedView = context.getters.statefulViewWithUri(uri)
      if (cached && cachedView) {
        return Promise.resolve(cachedView)
      }

      return ApiClient.getUri(uri , undefined, {customErrorHandling})
      .then(response => {
        const type = Object.values(statefulViewTypes).find(type => type.name === response.data.type)
        if (type != null) {
          const newStatefulView = new type.model(response.data)
          context.commit('addStatefulView', newStatefulView)
          return newStatefulView
        }
      })
    },
    AGPatchStatefulViewOperation(context, { statefulView, name, type, fieldIds, properties, slotProperties }) {
      const link = statefulView.getLink(PERMISSIONS.patch)
      if (link == null) {
        console.error(`Missing patch permission for statefulView: ${statefulView.id}`)
        return
      }
      return ApiClient.patchUri(link, {
        name,
        type,
        fieldIds,
        properties,
        slotProperties
      })
    },
    AGListStatefulViewsOperation(context, grid) {
      const link = grid.getLink(PERMISSIONS.sviews)
      return ApiClient.getUri(link, {nested: true})
        .then(response => context.commit('addStatefulViewList', {
          gridUri: grid.uri,
          statefulViewList: response.data.items.map(item => {
            return {
              uri: item._links.self.href,
              name: item.name,
              id: item.id,
              type: item.type,
              _links: item._links
            }
          })
        }))
    },
    AGShareViewOperation(context, { statefulView }) {
      const link = statefulView.getLink('addShare')
      return ApiClient.postUri(link, {
        role: 'reader',
        identity: 'anonymous'
      })
    },
    async AGReadViewShareOperation(context, { shareUri }) {
      const response = await ApiClient.getUri(shareUri)
      return response.data
    },
    AGDeleteViewShareOperation(context, { share }) {
      const link = share._links.remove.href
      return ApiClient.deleteUri(link)
    },
    AGListViewSharesOperation(context, { statefulView }) {
      const link = statefulView.getLink('shares')
      return ApiClient.getUri(link).then(response => response.data.items)
    },
    AGListViewEntitiesOperation(context, { statefulView, filter, sorting, pageIndex = 1, pageSize = 300}) {
      const link = statefulView.getLink('entities')
      return ApiClient.getUri(
        link,
        { layout: 'indexed', pageSize, pageIndex, filter: filter?.exportObject(), sorting },
        { headers: { Accept: 'application/vnd.apptivegrid.hal' } }
      )
    },
    AGMoveStatefulViewOperation(context, {space, virtualGridUri, position}) {
      // HACK : Here we assume that there is always only one statefulView
      // per virtualGrid and that their position in their relative listing
      // coincide.
      const link = space.getLink('changeVirtualGridPosition')
      return ApiClient.postUri(link, {
        uri: virtualGridUri,
        // despite the call being from the space, the position here is the
        // position of the list of virtual grid with the same parent persistent grid
        position: position + 1
      })
    }
  },
  getters: {
    statefulViewWithUri: (state) => (uri) => {
      return state.statefulViews[uri]
    },
    statefulViewsOfGrid: (state) => (uri) => {
      return state.statefulViewLists[uri] ?? []
    },
    gridUriContainingStatefulView: (state) => (uri) => {
      return Object.keys(state.statefulViewLists)
        .find(key => state.statefulViewLists[key]
          .some(view => view.uri === uri))
    }
  }
}