import { render, staticRenderFns } from "./BlockPicker.vue?vue&type=template&id=33999f93&scoped=true"
import script from "./BlockPicker.vue?vue&type=script&lang=js"
export * from "./BlockPicker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33999f93",
  null
  
)

export default component.exports