import Block from './Block'
import store from '@/store/store'

export default class LayoutBlock extends Block {
  initFromData(data) {
    super.initFromData(data)
    this.layout = data.layout || {
      layoutMode: 'HORIZONTAL',
      mainAxisAlignItems: 'CENTER',
      counterAxisAlignItems: 'CENTER',
      wrap: true
    }
  }

  get layoutMode() {
    return this.layout.layoutMode
  }

  get mainAxisAlignItems() {
    return this.layout.mainAxisAlignItems
  }

  get counterAxisAlignItems() {
    return this.layout.counterAxisAlignItems
  }

  get wrap() {
    return this.layout.wrap
  }

  async setLayoutMode(mode) {
    this.layout.layoutMode = mode
    this.isDirty = true
    await this.updateValue()
  }

  async setMainAxisAlignItems(alignment) {
    this.layout.mainAxisAlignItems = alignment
    this.isDirty = true
    await this.updateValue()
  }

  async setCounterAxisAlignItems(alignment) {
    this.layout.counterAxisAlignItems = alignment
    this.isDirty = true
    await this.updateValue()
  }

  async setWrap(wrap) {
    this.layout.wrap = wrap
    this.isDirty = true
    await this.updateValue()
  }
  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        layout: this.layout
      }
    })
    this.isDirty = false
  }
  async update() {
    const payload = this.getSerializableData()
    await store().dispatch('AGUpdateBlock', {
      block: this,
      payload: payload
    })
  }
} 