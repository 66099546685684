<template>
  <BaseBlock
    :blockUri="blockUri"
    v-on="$listeners"
    :editorMode="editorMode"
    :grabbableClass="grabbableClass"
    :nesting="editItemBlock"
    controlsPosition="top"
  >
    <template v-slot:menu>

      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.entityList.selectItemBlock')"
        @click="editItemBlock = true"
      />
      <MenuItem
        icon="mdi-cog-outline"
        :disabled="!grid"
        :text="$t('blocks.entityList.selectGrid')"
        @click="openMenu"
      />
    </template>

    <div v-if="editItemBlock" class="">
      <HtmlJSBlock
        :blockUri="itemBlockUri"
        :editorMode="editorMode"
        :openEditorOnMount="true"
        @editorClosed="editItemBlock = false"
        :gridContext="grid"
      />
    </div>
    <div v-else-if="!grid" class="empty-state d-flex flex-row gap-3 pa-3" @click="openMenu">
      <v-icon color="#75746F">mdi-format-list-text</v-icon>
      {{ $t('blocks.entityList.selectGridDescription') }}
    </div>
    <div v-else class="entity-list">

      <div v-for="(child, index) in children" :key="index" @click="showFullScreenItem(child)">
        <component
          :is="child.blockType.component"
          :blockUri="child.uri"
          :editorMode="false"
          :sandboxInIframe="false"
        />
      </div>
    </div>
    <v-menu
        v-model="showMenu"
        :close-on-content-click="false"
        :position-x="menuX"
        :position-y="menuY"
        min-width="320px"
      >
        <v-card>
          <v-card-title>{{ $t('blocks.entityList.selectGrid') }}</v-card-title>
          <v-card-text>
            <TemplateResourceInput
              v-model="selectedGrid"
              :loadItems="loadGrids"
              ref="gridPicker"
            />
          </v-card-text>
        </v-card>
      </v-menu>

    <!-- Full screen dialog for showing selected item -->
    <v-dialog
      v-model="showFullScreenDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card >
        <v-toolbar dense color="white" elevation="0">
          <v-spacer></v-spacer>
          <v-btn icon @click="showFullScreenDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <component
            v-if="selectedItemUri"
            :is="selectedItemComponent"
            :blockUri="selectedItemUri"
            :editorMode="false"
            :sandboxInIframe="true"
            :fullScreen="true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import MenuItem from '@/components/MenuItem.vue'
import blockProps from '@/mixins/blockProps'
import TemplateResourceInput from '@/components/flow/TemplateResourceInput.vue'
import EditPageDialog from '@/components/block/EditPageDialog.vue'
import HtmlJSBlock from '@/components/block/HtmlJSBlock.vue'
export default {
  mixins: [blockProps],
  data() {
    return {
      statefulViewUri: null,
      showMenu: false,
      menuX: 0,
      menuY: 0,
      editItemBlock: false,
      showFullScreenDialog: false,
      selectedItemUri: null,
      selectedItem: null
    }
  },

  inject: ['spaceUri'],
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    grid() {
      return this.block.grid
    },
    itemBlock() {
      return this.block.itemBlock
    },
    children() {
      return this.block.children || []
    },
    selectedGrid: {
      get() {
        return this.grid
      },
      set(newGrid) {
        if (newGrid) {
          this.setGrid(newGrid)
        }
      }
    },
    selectedItemComponent() {
      return this.selectedItem?.blockType?.component
    },
    itemBlockUri() {
      return this.itemBlock?._links.self.href
    }
  },
  methods: {
    openMenu(event) {
      this.showMenu = true
      this.menuX = event.clientX
      this.menuY = event.clientY
    },
    async loadGrids() {
      const persistentGrids = await   this.$store.dispatch('loadGridsInSpace', { spaceUri: this.spaceUri, type: 'persistent' })
      const virtualGrids = await this.$store.dispatch('loadGridsInSpace', { spaceUri: this.spaceUri, type: 'virtual' })
      return [...persistentGrids, ...virtualGrids]
    },
    async setGrid(grid) {
      await this.block.setGrid( grid.unwrap() )
    },
    showFullScreenItem(item) {
      if (!item || !item.uri) return
      
      this.selectedItem = item
      this.selectedItemUri = item.uri
      this.showFullScreenDialog = true
    }
  },
  watch: {
    itemBlock: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) {
    
          const html = {
            type: 'html',
            html: {
                text: {
                  value: `
                  <div class="grid-entry-placeholder">
                    <div>${this.$t('blocks.entityList.gridEntryPlaceholder')}</div>
                  </div>
                  <style>
                    .grid-entry-placeholder {
                      display: flex;
                      flex-direction: row;
                      gap: 12px;
                      padding: 12px;
                      background: #F2F1EE;
                      color: #75746F;
                      border-radius: 4px;
                    }
                  </style>
                  `
                }
            }
          }
          await this.block.setItemBlock(html)
        }
      }
    }
  },
  components: {
    BaseBlock,
    MenuItem,
    TemplateResourceInput,
    EditPageDialog,
    HtmlJSBlock
  }
}
</script>

<style scoped>


.empty-state {
  cursor: pointer;
  background: #F2F1EE;
  color: #75746F;
  border-radius: 4px;
  width: 100%;
}

.entity-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}


/* Make items in the list look clickable */
.entity-list > div {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.entity-list > div:hover {
  transform: translateY(-2px);
}
</style> 