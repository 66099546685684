<template>
  <AppBar
    :space="space"
    :extension-height="extensionHeight"
    height="48px"
    app
    flat
    clipped-right
  >
  <v-hover v-slot:default="{ hover }">
    <div class="d-flex align-center">
    <v-app-bar-nav-icon
      v-if="hover || sideNavigation || $vuetify.breakpoint.mobile"
      @click="sideNavigation = !sideNavigation"
    />
    <SpaceIcon v-else class="mx-3" :space="space" />
  </div>
</v-hover>
    <slot name="prepend-title"></slot>

    <v-toolbar-title v-show="!sideNavigation && !$vuetify.breakpoint.mobile" class="font-weight-medium">
      <v-hover v-slot:default="{ hover }">
        <div class="d-flex align-center">
      <EditableText

        :readonly="!canRenameSpace"
        class="full-width textOverflow pa-1"
        :text="spaceTitle"
        @submit="renameSpace"
        :maxLength="maxSpaceNameLength"
        data-testid="spaceTitle"
      />
      <SpaceMoreMenu
      v-if="space != null && !sideNavigation && hover"
      :spaceUri="spaceUri"
      :spaceName="spaceTitle"
      :disableRename="!canRenameSpace"
      :disableShare="!canShareSpace"
      :disableUploadCsv="!canUploadCsv"
    />
     <v-btn
      icon
      x-small
      v-show="!hover">
      <v-icon>mdi-chevron-right</v-icon>
     </v-btn>
    </div>
    </v-hover>
      </v-toolbar-title>
 

    <slot name="append-title"></slot>
    <v-spacer></v-spacer>
    <slot name="actions"></slot>

    <div v-if="developerMode">
      <v-btn
        class="mr-2"
        icon
        width="35"
        height="35"
        @click="toggleApptiveDrawer"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </div>
    <SpaceCollaborators
      v-if="space != null"
      :space="space"
      class="mr-3"
    />
    <UserProfile v-if="userLoggedIn" />

    <template v-slot:extension>
      <slot name="extension" />
    </template>
  </AppBar>
</template>

<script>
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import EditableText from '@/components/EditableText.vue'
import SpaceMoreMenu from '@/components/space/SpaceMoreMenu.vue'
import SpaceCollaborators from '@/components/SpaceCollaborators.vue'
import UserProfile from '@/components/user/UserProfile.vue'
import AppBar from '@/components/AppBar.vue'
import SpaceIcon from '@/components/space/SpaceIcon.vue'

export default {
  props: {
    spaceUri: {
      type: String
    },
    extension: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    renameSpace(newName) {
      if (this.space.name == newName) {
        return
      }
      return this.$store.dispatch('AGRenameSpaceOperation', {
        spaceUri: this.space.uri,
        newName: newName
      })
    },
    toggleApptiveDrawer() {
      this.$store.commit('setApptivesDrawer', !this.$store.state.apptivesDrawer)
    },
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    spaceTitle() {
      return this.space?.title()
    },
    canRenameSpace() {
      return hasPermission(this.space, [PERMISSIONS.patch])
    },
    canShareSpace() {
      return hasPermission(this.space, [PERMISSIONS.addShare])
    },
    canUploadCsv() {
      return hasPermission(this.space, [PERMISSIONS.uploadCSV])
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    sideNavigation: {
      get() {
        return this.$store.state.sideNavigation
      },
      set(newVal) {
        return this.$store.commit('setSideNavigation', newVal)
      }
    },
    developerMode() {
      return this.$store.getters.developerMode
    },
    extensionHeight() {
      return this.extension ? '32px' : null
    },
    maxSpaceNameLength() {
      return this.$apptive?.constants.nameLengths.space
    }
  },
  components: {
    EditableText,
    SpaceMoreMenu,
    SpaceCollaborators,
    UserProfile,
    AppBar,
    SpaceIcon
  }
}
</script>

<style
  lang="scss"
  scoped
></style>