<template>
  <v-sheet class="d-flex"
    @pointerenter="showPencil = true"
    @pointerleave="showPencil = false"
  >
    <div>
      <v-list-item-title>
        <EditableText
          class="px-3 pt-3 pb-1"
          :text="name"
          @submit="patchName"
          :showPencil="showPencil"
        />
      </v-list-item-title>
      <v-list-item-subtitle class="px-3 d-flex align-center">
        <ViewIcon :type="typeIcon"/>
        <span class="ml-1">{{ displayName }}</span>
       
      </v-list-item-subtitle>
      <v-list-item-subtitle class="px-3 pb-3">{{ type }}</v-list-item-subtitle>
    </div>
    <v-spacer />
    <div class="d-flex align-center">
      <ConfirmButton :onClick="deleteLink">
        <template v-slot:initial="{on}">
          <v-btn text color="accent" v-on="on">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:confirm="{on}">
          <v-btn text color="red" v-on="on"><v-icon>mdi-delete-empty-outline</v-icon></v-btn>
        </template>
      </ConfirmButton>
    </div>
  </v-sheet>
</template>

<script>
import ConfirmButton from '@/components/ConfirmButton.vue'
import EditableText from '@/components/EditableText.vue'
import ViewIcon from '@/components/ViewIcon.vue'
import { ViewIconTypes } from '@/constants/viewIconTypes'

export default {
  props: {
    link: null
  },
  data() {
    return {
      showPencil: false,
      ViewIconTypes
    }
  },
  computed: {
    uri() {
      return this.link?.uri
    },
    name() {
      return this.link?.name ?? 'Link'
    },
    type() {
      return this.link?.typeDisplay
    },
    typeIcon() {
      const iconMap = {
        addEntity: ViewIconTypes.FORM,
        editEntity: ViewIconTypes.FORM,
        block: ViewIconTypes.BLOCK,
        view: ViewIconTypes.GRID,
        flowInstance: ViewIconTypes.FLOW_FORM,
        externalFlowTrigger: ViewIconTypes.FLOW_FORM
      }
      
      return iconMap[this.link?.type] || ViewIconTypes.UNKNOWN
    },
    gridName() {
      return this.link?.gridName
    },
    formName() {
      return this.link?.formName
    },
    displayName() {
      return `${this.gridName ? this.gridName + ' / ' : ''}${this.link?.displayValue}`
    }
  },
  methods: {
    async patchName(newName) {
      this.$emit('newName', newName)
    },
    deleteLink() {
      this.$emit('delete')
    }
  },
  components: {
    EditableText,
    ConfirmButton,
    ViewIcon
}
}
</script>