<template>
<v-dialog
  v-model="externalModel"
  max-width="400px"
>
  <v-card>
    <v-card-title
      class="d-flex justify-start text-h5 mb-2"
    >
      {{ $t('dialogs.blockKeysTitle.title') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('dialogs.blockKeysTitle.text') }}
    </v-card-subtitle>
    <v-card-text class="d-flex flex-column" :style="{gap: '20px'}">
      <div
        v-for="(childBlock, index) in inputFields"
        :key="index"
      >
        <div
          class="d-flex align-center"
        >
          <v-icon small>mdi-form-textbox</v-icon>
          <div
            class="ml-2 textOverflow text-subtitle-2 grey--text text--darken-2"
          >{{childBlock[childBlock.type].label}}</div>
        </div>
        <v-text-field
          class="mt-2"
          outlined
          hide-details
          clearable
          :value="childBlock.formKey"
          @input="value => form[childBlock.id] = value"
          @blur="setKey(childBlock)"
          :loading="loading[childBlock.id]"
          data-testid="input-block-key"
        />
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import externalModel from '@/mixins/externalModel.js'

export default {
  mixins: [externalModel],
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {},
      loading: {}
    }
  },
  computed: {
    inputFields() {
      return (this.block?.children ?? []).filter(child => 'formKey' in child)
    }
  },
  watch: {
    block: {
      immediate: true,
      handler() {
        this.inputFields.forEach(child => {
          this.form[child.id] = child.formKey
        })
      }
    }
  },
  methods: {
    setKey(childBlock) {
      const keyInputValue = this.form[childBlock.id]
      this.$set(this.loading, childBlock.id, true)
      childBlock.setKey(keyInputValue)
        .finally(() => {
          this.$set(this.loading, childBlock.id, false)
        })
    }
  }
}
</script> 