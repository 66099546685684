<template>
  <GridCellFactory
    class="pa-2"
    :class="readonly ? 'readonly-cell' : 'field-cell'"
    solo
    roundedBorders
    :editEntityMode="true"
    :value="externalModel"
    :displayValue="displayValue"
    :type="columnType"
    :field="blockField"
    fontSize="1rem"
    v-on:input="(value) => externalModel = value"
    v-on:blur="$emit('blur')"
    :readonly="readonly"
    :multiline="multiline"
    :rows="multiline ? 3 : 1"
  />
</template>

<script>
import GridCellFactory from '@/components/gridView/GridCellFactory.vue'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [
    externalModel
  ],
  props: {
    blockUri: {
      type: String,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    multiline: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    columnType() {
      return this.block.columnType
    },
    displayValue() {
      return this.columnType?.displayFormat
        ? this.columnType.displayFormat(this.externalModel)
        : this.externalModel
    },
    blockField() {
      return {
        type: this.block.valueType,
        _links: this.block._links,
      }
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    }
  },
  components: {
    GridCellFactory
  },
}
</script>

<style
  lang="scss"
  scoped
>
.field-cell {
  min-height: 24px;
  width: 100%;
  border: 1px solid #caced4 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
}
.readonly-cell {
  min-height: 24px;
  width: 100%;
  border: 1px solid #e5e6e7 !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #f9f9f9;
}
</style>
