import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class PageBlock extends Block {
  static VIEW_TYPES = {
    DEFAULT: 'default',
    FULL: 'full',
  }

  constructor(data) {
    super(data)
  }

  get name() {
    return this.page.name
  }

  set name(newVal) {
    this.page.name = newVal
  }

  async patchName(newName) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          name: newName
        }
      }
    })
    this.name = newName
  }

  async update() {
    const payload = this.getSerializableData()
    await store().dispatch('AGUpdateBlock', {
      block: this,
      payload: payload
    })
  }

  get headerImageUrl() {
    return this.page.headerImage
  }

  set headerImageUrl(newVal) {
    this.page.headerImage = newVal
  }

  get pageBlocks() {
    return this.children.filter( aBlock => aBlock.type === 'page')
  }

  get icon() {
    return this.page.icon
  }

  set icon(newIcon) {
    this.page.icon = newIcon
  }

  async addChildPage() {
      const newBlock = await store().dispatch('AGAddPageToBlockOrSpace', this)
      this.children.push(newBlock)
      return newBlock
  }

  isSelectedIn(blockUri) {
    return blockUri === this.uri
  }

  isChildSelectedIn(blockUri) {
    // First, check if any immediate child has the matching URI
    const isDirectChildSelected = this.children?.some(child => child.uri === blockUri)
  
    if (isDirectChildSelected) {
      return true
    }
  
    // If not found among direct children, recursively check their children
    return this.children?.some(child => child.isChildSelectedIn?.(blockUri))
  }

  async setHeaderImageUrl(url) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          headerImage: url
        }
      }
    })
    this.headerImageUrl = url
  }

  async setIcon(icon) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          icon: icon
        }
      }
    })
    this.icon = icon
  }

  get viewType() {
    return this.page.viewType || PageBlock.VIEW_TYPES.CENTERED // default to 'centered' view
  }

  set viewType(newVal) {
    const allowedTypes = Object.values(PageBlock.VIEW_TYPES)
    if (!allowedTypes.includes(newVal)) {
      throw new Error(`Invalid view type. Must be one of: ${allowedTypes.join(', ')}`)
    }
    this.page.viewType = newVal
  }

  async setViewType(newViewType) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        page: {
          viewType: newViewType
        }
      }
    })
    this.viewType = newViewType
  }
}