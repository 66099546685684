<template>
  <div style="height: 100%;">
    <SpaceAppBar :spaceUri="spaceUri" >
      <template v-slot:append-title>
        <v-toolbar-title class="font-weight-medium d-flex align-center">

      <EditableText
            v-if="currentBlock"
            :readonly="!canRenameBlock"
            class="textOverflow pa-1"
            :text="currentBlock.name"
            @submit="val => patchName(currentBlock, val)"
            :maxLength="$apptive.constants.nameLengths.grid"
          />
        </v-toolbar-title>
      </template>

      <!-- Add actions slot for edit mode toggle -->
      <template v-slot:actions>
        <v-btn
          v-if="$vuetify.breakpoint.mobile"
          @click="toggleEditMode"
          color="grey lighten-3"
          fab
          width="28"
          height="28"
          elevation="0"
        >
          <v-icon small color="primary">{{ inEditMode ? 'mdi-check' : 'mdi-pencil-outline' }}</v-icon>
        </v-btn>

        <BlockPageShareMenu
        v-if="canShare"
        :blockUri="currentBlock.uri"
      >
        <template v-slot:dialogButton>
          <v-btn
            color="accent"
            text
            small
          >
            <v-icon small class="mr-1">mdi-share</v-icon>
            <span class="d-none d-sm-flex">{{$t('blocks.shareDialog.buttons.publish')}}</span>
          </v-btn>
        </template>
      </BlockPageShareMenu>
      </template>
    </SpaceAppBar>

      <v-progress-circular
        indeterminate
        v-if="loading"
        color="primary"
        width="2"
        size="36"
        class="loader"
      />
        <router-view :inEditMode="inEditMode" v-else-if="hasBlocks" />

        <v-container v-else>
          <div class="d-flex flex-column align-center justify-center mt-12">
           <v-img  src="/blocks_empty_space.svg"></v-img>
           <div class="text-h6">
               {{ $t("blocks.emptyStateBlocksHeader") }}
           </div>
           <div style="max-width: 420px;" class="text-center accent--text mt-2">
               {{ $t("blocks.emptyStateBlocksDescription") }}
           </div>
           <v-btn
             :disabled="!canAddPage"
             data-testid="addPageButtonEmptyState"
             color="primary"
             class="mt-6"
             @click="addPage"
           >
               {{ $t("blocks.emptyStateBlocksAddPage") }}</v-btn>
          </div>
        </v-container>
  </div>
</template>

<script>

import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import SpaceAppBar from '@/views/SpaceAppBar.vue'
import EditableText from '@/components/EditableText.vue'
import BlockPageShareMenu from '@/components/block/BlockPageShareMenu.vue'
export default {
  props: {
    spaceUri: null,
  },
  data() {
    return {
      loading: false,
      inEditMode: false,
    }
  },
  mounted() {
    document.title = `${this.space?.title()} / ${this.$t('blocks.pages')}`
  },
  watch: {
    spaceUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal
          })
          await this.listBlocks()
          if ( this.$route.name === 'BlockEditor' ) {
            this.initialNavigation()
          }
        } finally {
          this.loading = false
        }
      }
    }
  },
  provide() {
    const self = this
    return {
      plan: () => self.space?.plan,
      spaceUri: this.spaceUri,
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    hasBlocks() {
      return this.blocks.length > 0
    },
    canAddPage() {
      return hasPermission(this.space, [PERMISSIONS.addBlock])
    },
    blocks () {
      return this.$store.getters.blocksOfSpace(this.spaceUri)
    },
    currentBlock() {
      const blockUri = this.$route.params.blockUri
      
      // Helper function to search blocks recursively
      const findBlockByUri = (blocks) => {
        for (const block of blocks) {
          if (block.uri === blockUri) return block
          if (block.children) {
            const found = findBlockByUri(block.children)
            if (found) return found
          }
        }
        return null
      }

      return findBlockByUri(this.blocks)
    },
    canRenameBlock() {
      return hasPermission(this.currentBlock, [ PERMISSIONS.patch ])
    },
    canShare() {
      return hasPermission(this.currentBlock, [ PERMISSIONS.addHook ])
    },
  },
  methods: {

    initialNavigation() {
      if (this.blocks.length >= 1) {
        this.$router.replace({ name: 'BlockPage', params: { blockUri: this.blocks[ 0 ].uri } })
      }
      else if (this.$route.name !== 'BlockEditor') {
        // once the last blockpage was deleted, switch to the BlockEditor route
        this.$router.replace({ name: 'BlockEditor'})
      }
    },
    async listBlocks() {
        await this.$store.dispatch('AGListSpaceBlocks', { space: this.space})
    },
    async addPage() {
      this.loading = true
      try {
        const newBlock = await this.$store.dispatch('AGAddPageToBlockOrSpace', this.space)
        await this.listBlocks()
        this.$router.push({ name: 'BlockPage', params: { blockUri: newBlock.uri } }) 
      } finally {
        this.loading = false
      }
    },
    async patchName(block, newName) {
      await block.patchName(newName)
      block.name = newName
    },
    async blockDeleted(block) {
      await this.listBlocks()
      if (this.$route.name === 'BlockPage' && this.$route.params.blockUri === block.uri) {
        this.initialNavigation()
      }
    },
    toggleEditMode() {
      this.inEditMode = !this.inEditMode
    }
  },
  components: {
    SpaceAppBar,
    EditableText,
    BlockPageShareMenu
  }
}
</script>

<style
  lang="scss"
  scoped
>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
}
</style>