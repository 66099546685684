<template>
  <FlowVariablePickerMenu
    v-model="pickerMenu"
    :pickerValue="pickerValue"
    :filterNode="node"
    :flow="flow"
    @pickerInput="(expression) => append(expression, lastSelectedComponent)"
    left offset-x
    :close-on-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-sheet class="d-flex flex-column parent-height" dense v-if="node">
        <v-toolbar color="white" dense class="flex-grow-0 pl-6" elevation="0">
          <FlowNodeIcon
            size="32"
            :nodeType="node"
          />

          <v-toolbar-title class="subtitle-1 font-weight-medium mr-1 ml-1">
            <EditableText
              :readonly="!canRenameNode"
              class="pa-1"
              :text="node.name"
              color="primary"
              @submit="renameNode"
              data-testid="nodeName"
              :maxLength="50"
            />
          </v-toolbar-title>
          <v-spacer />
            <v-btn v-if="docuLink" :href="docuLink" target="_blank" small icon><v-icon dense> mdi-help-circle-outline</v-icon></v-btn>
            <FlowNodeMenu @deleteNode="deleteNode" v-model="nodeMenu" :node="node" :flow="flow" />
        </v-toolbar>

        <v-progress-linear v-show="patchLoading" data-testid="node-patch-loading" class="patch-loader" color="primary" indeterminate/>
        <div v-if="node.components.length > 0" dense class="scroll-container pb-12">
          <v-expansion-panels :value="openPanels" panels multiple flat>
            <v-expansion-panel v-for="(component, index) in node.components" :key="index" class="pa-0">
              <v-expansion-panel-header class="pb-0 pt-0 pl-2">
                <template v-slot:actions>
                  <v-icon color="accent" small class="icon mr-1 ml-n1">mdi-chevron-down</v-icon>
                </template>
                <template v-slot:default="{ open }">

                <div class="header subtitle-2 d-inline-block text-truncate" v-if="component.props.label">
                  <span class="font-weight-bold text-capitalize">{{ component.props.label }}</span>
                  <span v-if="component.props.name && !open"
                    class="caption ml-2 grey--text"> {{inputModels[component.props.name]}} </span>
                 </div>
                 
                </template>
               
              </v-expansion-panel-header>
              <v-expansion-panel-content class="ml-2" >

                <v-btn
                  v-if="lastSelectedComponent == component && canPatchNode"
                  data-testid="variablePickerButton"
                  @click.native="onVariablePickerButtonClicked"
                  rounded
                  fab
                  x-small
                  elevation="2"
                  dark
                  color="primary"
                  class="picker-button"
                >
                  <v-icon >mdi-code-braces</v-icon>
                </v-btn>

                <component :selected="isSelected(component)" :ref="`input${component.props.key}`"
                  :is="component.component" v-bind="component.props" :flow="flow"
                  v-model="inputModels[component.props.key]"
                  :disabled="!canPatchNode"
                  @click.native="(event) => componentClicked(on, event, component)"
                  @componentClicked="(event, component) => componentClicked(on, event, component)"
                  @focus="lastSelectedComponent = component"
                  @componentFocused="(childComponent) => lastSelectedComponent = childComponent"
                  @blur="patchNodeConfig"
                  @patchNodeGrid="patchNodeGrid"
                  @patchNodeFlowForm="patchNodeFlowForm"
                  @pageEdited="loadSetupView"
                  :testKey="component.props.key"
                   />

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div v-if="isNodeExternalTrigger" class="scroll-container">
          <RequestTriggerConfiguration :flow="flow" />
        </div>
        <div v-else-if="isNodeStart" class="scroll-container">
          <FlowTrigger :flowUri="flow.uri" :event="node.eventType" :spaceUri="space.uri"/>
        </div>
      </v-sheet>
      <ExpressionEditorMenu
        v-model="expressionEditorMenu"
        :flow="flow"
        :pickerValue="pickerValue"
        :filterNode="node"
        :position-x="expressionEditorMenuX"
        :position-y="expressionEditorMenuY"
      />
    </template>

  </FlowVariablePickerMenu>
</template>

<script>
import { isEqual } from 'lodash'
import FlowVariablePickerMenu from './FlowVariablePickerMenu.vue'
import FlowTrigger from '@/components/flow/FlowTrigger.vue'
import RequestTriggerConfiguration from '@/components/flow/RequestTriggerConfiguration.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import EditableText from '../EditableText.vue'
import FlowNodeMenu from '@/components/flow/FlowNodeMenu.vue'
import FlowNodeIcon from '@/components/flow/FlowNodeIcon.vue'
import { nodeTypes } from '@/constants/nodeTypes.js'
import ExternalTriggerNode from '@/store/models/flow/nodes/ExternalTriggerNode'
import StartNode from '@/store/models/flow/nodes/StartNode'
import variablePickerBus from '@/components/flow/variablePickerBus'
import ExpressionEditorMenu from '@/components/ExpressionEditorMenu.vue'

export default {
  props: {
    space: null,
    node: null,
    flow: null,
    positionX: null,
    positionY: null,
  },
  data() {
    return {
      tab: 0,
      patchLoading: false,
      inputModels: {},
      panels: null,
      lastSelectedComponent: undefined,
      pickerMenu: false,
      nodeMenu: false,
      expressionEditorMenu: false,
      expressionEditorMenuX: 0,
      expressionEditorMenuY: 0,
      nodeTypes
    }
  },
  mounted() {
    variablePickerBus.onExpressionEdit(this.onExpressionEdit)
  },
  beforeDestroy() {
    variablePickerBus.expressionEditOff(this.onExpressionEdit)
    this.patchNodeConfig()
  },
  watch: {
    'node.properties': {
      immediate: true,
      handler(newVal) {
        this.inputModels = { ...newVal }
      }
    },
  },
  provide() {
    return {
      spaceUri: this.space?.uri
    }
  },
  computed: {
    canPatchNode() {
      return hasPermission(this.node, [PERMISSIONS.patch])
    },
    canRenameNode() {
      return this.canPatchNode
    },
    dirty() {
      if ((!this.inputModels || Object.keys(this.inputModels).length <= 0) && !this.node.properties) {
        return false
      }
      return !isEqual(this.inputModels, this.node.properties)
    },
    pickerValue() {
      if (this.flow?.testInstance == null) return
      return this.flow.testInstance.steps.reduce(
        (acc, step) => {
          // HACK for nodeId
          const key = step._links.node.href.split('/')[ step._links.node.href.split('/').length - 1 ]
          acc[ key ] = { output: step.output, metadata: step.metadata }
          return acc
        },
        {}
      )
    },
    styles() {
      return {
        'max-height': `calc(100vh - ${this.$vuetify.application.top + this.positionY}px)`,
        overflow: 'auto'
      }
    },
    openPanels() {
      return this.node.components.map((item, i) => i)
    },
    nodeColor() {
      return this.node.color
    },
    docuLink() {
      return this.node.docuLink
    },
    isNodeExternalTrigger() {
      return this.node != null && this.node instanceof ExternalTriggerNode
    },
    isNodeStart() {
      return this.node != null && this.node instanceof StartNode
    }
  },
  methods: {
    deleteNode() {
      this.$emit('deleteNode', this.node)
      this.showContextMenu = false
    },
    append(expression, component) {
      const input = this.$refs[ `input${component.props.key}` ][ 0 ]
      input.insert(`{{${expression}}}`)
    },
    async patchNodeConfig() {
      if (!this.dirty) return
      this.patchLoading = true
      try {
        await this.node.updateProperties(this.inputModels)
      } finally {
        this.patchLoading = false
      }
    },
    async componentClicked(on, event, component) {
      if (!this.canPatchNode) {
        return
      }
      this.lastSelectedComponent = component
    },
    async renameNode(newName) {
      this.node.name = newName
      this.node.patchName()
    },
    isSelected(component) {
      return isEqual(component, this.lastSelectedComponent)
    },
    async patchNodeGrid() {
      await this.node.updateProperties({ grid: this.inputModels.grid })
      this.node.loadSetupView(this.space)
    },
    async patchNodeFlowForm(){
      await this.node.updateProperties({ block: this.inputModels.block })
      this.node.loadSetupView(this.space)
    },
    loadSetupView() {
      this.node.loadSetupView(this.space)
    },
    onVariablePickerButtonClicked() {
      this.pickerMenu = !this.pickerMenu
      if (this.pickerMenu) {
        this.expressionEditorMenu = false
      }
    },
    onExpressionEdit(expression, handler, event) {
      this.expressionEditorMenuX = event.clientX
      this.expressionEditorMenuY = event.clientY - 12
      this.pickerMenu = false
      this.expressionEditorMenu = true
    }
  },
  components: {
    FlowVariablePickerMenu,
    FlowTrigger,
    RequestTriggerConfiguration,
    EditableText,
    FlowNodeMenu,
    FlowNodeIcon,
    ExpressionEditorMenu
}
}
</script>

<style lang="css" scoped>
.scroll-container {
  overflow: auto;
}
.parent-height {
  height: 100%;
}

.nodeIcon {
  width: 32px;
  height: 32px;
  border-radius: 100px;
}
.icon {
  order: 0;
}

.header {
  order: 1;
}

.patch-loader {
  margin-left: -8px;
  width: calc(100% + 8px);
}
.picker-button {
  position: absolute;
  top: 30px;
  right: 45px;
  z-index: 1;
  
}

</style>
