<template>
  <BaseBlock
    :blockUri="blockUri"
    v-on="$listeners"
    :editorMode="editorMode"
    :grabbableClass="grabbableClass"
  >
    <template v-slot:menu>
      <SubMenu :activatorText="$t('blocks.headerLevel')" activatorIcon="mdi-format-size">
        <v-list-item
          v-for="(levelItem, index) in levels"
          :key="index"
          @click="setHeaderLevel(levelItem)"
        >
          <div class="d-flex align-center">
            <v-icon
              :size="16"
              class="mr-3 control flex-end"
              :class="{ shown: level === levelItem }"
            >mdi-check</v-icon>
            <div :class="HEADER_LEVELS[levelItem].class">{{HEADER_LEVELS[levelItem].name}}</div>
          </div>
        </v-list-item>
      </SubMenu>
      <TextFormatMenu
        :block="block"
      />
      <SubMenu 
        :activatorText="$t('blocks.switchType')"
        :items="availableBlockTypes"
        @select="handleBlockTypeSwitch"
        activatorIcon="mdi-swap-horizontal"
      >
        <v-list-item
          v-for="(typeItem, index) in availableBlockTypes"
          :key="index"
          @click="handleBlockTypeSwitch(typeItem)"
        >
        <v-list-item-avatar>
          <v-icon>{{typeItem.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ typeItem.displayTitleString }}</v-list-item-title>
          <v-list-item-subtitle>{{ typeItem.displaySubtitleString }}</v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
      </SubMenu>
    </template>
    <div
      ref="contenteditable"
      data-testid="header-block"
      :contenteditable="contenteditable"
      class="header-text-field"
      :class="[headerClass, textAlignClass]"
      :placeholder="$t('blocks.emptyHeader')"
      @keydown.enter.prevent="appendEmptyParagraph"
      @blur="updateBlock"
      :style="{ color: color }"
    >{{ text }}</div>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import SubMenu from '@/components/SubMenu.vue'
import { HEADER_LEVELS } from '@/store/models/blocks/HeaderBlock'
import { blockTypes } from '@/constants/blockTypes'
import blockProps from '@/mixins/blockProps'
import TextFormatMenu from './TextFormatMenu.vue'
import { COLORS } from '@/constants/blockAnnotations'

export default {
  mixins: [ blockProps ],
  props: {
  },
  data() {
    return {
      HEADER_LEVELS,

    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    contenteditable() {
      return this.editorMode ? 'plaintext-only' : 'false'
    },
    headerClass() {
      if (this.level == null) {
        return HEADER_LEVELS[3].class
      }
      return HEADER_LEVELS[this.level]?.class ?? HEADER_LEVELS[3].class
    },
    text: {
      get() {
        return this.block.text
      },
      set(newVal) {
        this.block.text = newVal
      }
    },
    levels() {
      return Object.keys(HEADER_LEVELS)
    },
    level: {
      get() {
        return this.block.level
      },
      set(newVal) {
        this.block.level = newVal
      }
    },
    availableBlockTypes() {
      return Object.values(blockTypes).filter(type => 
        type.type === 'paragraph'
      )
    },
    textAlignClass() {
      return `text-${this.block?.textAlign}`
    },
    color() {
      return this.block?.color ?? COLORS.DEFAULT_TEXT
    }
   
  },
  watch: {
    text: {
      immediate: true,
      async handler(newVal) {
        if(newVal && this.$refs.contenteditable) {
          await this.$nextTick()
          this.$refs.contenteditable.textContent = newVal
        }
      }
    }
  },
  methods: {
    setHeaderLevel(level) {
      this.level = level
      this.$emit('updateBlock')
    },
    setTextAlign(align) {
      this.textAlign = align
    },
    updateBlock() {
      this.text = this.$refs.contenteditable.textContent
      this.$emit('updateBlock')
    },
    focus() {
      const el = this.$refs.contenteditable
      const selection = window.getSelection()
      const range = document.createRange()
      selection.removeAllRanges()
      range.selectNodeContents(el)
      range.collapse(false)
      selection.addRange(range)
      el.focus()
    },
    appendEmptyParagraph() {
      this.$emit('appendEmptyParagraph')
    },
    handleBlockTypeSwitch(newType) {
        this.$emit('switchBlockType', newType, this.block)
    },
 
  },
  components: {
    BaseBlock,
    SubMenu,
    TextFormatMenu
  }
}
</script>

<style
  lang="scss"
  scoped
>
::v-deep .header-text-field input {
  max-height: unset
}

.header-text-field {
  outline: unset;
  width: 100%;
  height: fit-content;
}

.control {
  opacity: 0;
  transition: opacity 0.3s;
}

.shown {
  opacity: 1;
}

[contenteditable=plaintext-only]:empty:not(:focus):before{
  content: attr(placeholder);
  pointer-events: none;
  color: lightgray;
  display: block; /* For Firefox */
}

.header-block-h1 {
  font-size: 2.25em;
  font-weight: 600;
  line-height: 1.2;
}

.header-block-h2 {
  font-size: 1.625em;
  font-weight: 600;
  line-height: 1.2;
}

.header-block-h3 {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.2;
}
</style>