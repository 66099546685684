<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass">
    <template v-slot:menu>
      <slot name="menu"></slot>
    </template>
    <div>
      <div class="d-flex align-center mb-2 font-weight-medium ">
        <EditableText
          :maxLength="200"
          :multiline="true"
          :readonly="!canChangeLabel"
          :text="label"
          :singleClick="true"
          @submit="saveFieldLabel"
          :required="false"
          :placeholder="$t('forms.builder.formFieldLabelPlaceholder')"
        />
        <span v-if="isRequired" class="required-indicator">*</span>
      </div>
      <slot></slot>
    </div>
  </BaseBlock>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import BaseBlock from '@/components/block/BaseBlock.vue'
import blockProps from '@/mixins/blockProps'

export default {
  mixins: [ blockProps ],
  props: {

  },
  computed: {
    canChangeLabel() {
      return this.editorMode && hasPermission(this.block, [ PERMISSIONS.patch ])
    },
    label() {
      return this.block?.label
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    isRequired() {
      return this.block?.required === true
    }
  },
  methods: {
    async saveFieldLabel(newValue) {
      this.block.setLabel(newValue)
    }
  },
  components: {
    EditableText,
    BaseBlock
  },
}
</script>

<style scoped>
.control {
  opacity: 0;
  transition: opacity 0.3s;
}

.shown {
  opacity: 1;
}

.required-indicator {
  color: #FF0000;
  margin-left: 4px;
}
</style>