export default class Pager {
  constructor(data, loadFunction, updateItemsFunction) {
    this.items = {}
    this.setPagingData(data)
    this.loadFunction = loadFunction
    this.updateItemsFunction = updateItemsFunction
    this.loading = false
  }

  get loadedPages() {
    return new Set(Object.keys(this.items))
  }

  setPagingData(data) {
    this.page = data.page
    this.items[this.page] = data.items ?? []
    this.items[this.page].forEach((item, index) => item.position = ((data.page - 1) * data.size) + (index + 1))
    this.numberOfItems = data.numberOfItems
    this.numberOfPages = data.numberOfPages
    this.size = data.size
  }

  allItems() {
    let keys = Object.keys(this.items).map(key => parseInt(key))
    const items = this.items
    keys.sort((a, b) => a - b)
    return keys
      .reduce(
        (acc, key) => [...acc, ...items[key]],
        []
      )
  }

  pageOf(entityIndex) {
    let keys = Object.keys(this.items).map(key => parseInt(key))
    keys.sort((a, b) => a - b)
    return keys[Math.floor(entityIndex / this.size)]
  }

  remove(entity) {
    for (let pageItems of Object.values(this.items) ) {
      const index = pageItems.findIndex(item => item._id === entity._id)
      if (index >= 0) {
        pageItems.splice(index, 1)
        this.numberOfItems --
        return
      }
    }
  }

  addEntity(entity) {
    try {
      const lastpage = this.items[this.numberOfPages]
      lastpage.push(entity)
      this.numberOfItems ++
    } catch (error) {
      console.error(error)
    }
  }

  async loadPage(newIndex) {
    this.loading = true
    let loadResponse
    let pageRemoved = false
    try {
      loadResponse = await this.loadFunction(newIndex)
      this.setPagingData(loadResponse.data)
      this.updateItemsFunction(this.allItems())
    } finally {
      this.loading = false
    }
    return {...loadResponse.data, pageRemoved }
  }

  lastLoadedPage() {
    let keys = Object.keys(this.items).map(key => parseInt(key))
    keys.sort((a, b) => a - b)
    return keys[keys.length - 1]
  }

  hasNext() {
    const lastLoadedPage = this.lastLoadedPage()
    return lastLoadedPage < this.numberOfPages && !this.loadedPages.has(lastLoadedPage + 1)
  }

  loadNext() {
    if (!this.loading && this.hasNext()) {
      return this.loadPage(this.lastLoadedPage() + 1)
    }
  }

  firstLoadedPage() {
    let keys = Object.keys(this.items).map(key => parseInt(key))
    keys.sort((a, b) => a - b)
    return keys[0]
  }

  hasPrevious() {
    const firstLoadedPage = this.firstLoadedPage()
    return firstLoadedPage > 1 && !this.loadedPages.has(firstLoadedPage - 1)
  }

  loadPrevious() {
    if (!this.loading && this.hasPrevious()) {
        return this.loadPage(this.firstLoadedPage() - 1)
    }
  }
}