<template>
  <div v-if="block" class="block-page-root" data-testid="blockPage">
    <LoadingOverlay :show="loading" />
    <v-img
      v-if="headerImageUrl"
      :src="headerImageUrl"
      class="headerImage"
    />
    <div :class="{ 'mobile-container': $vuetify.breakpoint.mobile && canUpdate && inEditMode, 'mx-4': !$vuetify.breakpoint.mobile, 'mx-4': $vuetify.breakpoint.mobile }" class="my-4 mt-10">
      <AGDraggable
        :disabled="!canUpdate"
        v-model="block.children"
        @change="onDragChange"
        handle=".grabbable"
      >
        <!-- Grid system with a single row and centered content -->

        <v-row            
          v-for="(child, i) in children"
          :key="child.id"
          justify="center"
          class="mx-0"
        >
        <v-col
        v-bind="columnSize(child)"
      >
      <div>
            <component
              :ref="`block_${child.id}`"
              :is="component(child)"
              :blockUri="child.uri"
              :editorMode="canEdit"
              @updateBlock="() => updateBlock(child)"
              @delete="deleteChild(child)"
              @appendEmptyParagraph="() => blockPickerIndex = i + 1"
              @appendParagraphWithText="text => addParagraphWithText(text, i + 2, false)"
              @mergeParagraphs="mergeParagraphs"
              @switchBlockType="switchBlockType"
              style="overflow-wrap: break-word;"
              grabbableClass="grabbable"
            />
          </div>
       <!-- Conditionally display the block picker after the block if it's the chosen index -->
       <BlockPicker
              
              :autofocus="true"
              :defaultOpen="true"
              v-if="blockPickerIndex === i + 1"
              @blockSelected="(type, defaultProps) => addChild(type, i + 2, defaultProps)"
              @appendParagraphWithText="text => addParagraphWithText(text, i + 2, false)"
              @addParagraphBlock="text => addParagraphWithText(text, i + 2)"
              @removeBlockPicker="blockPickerIndex = null"
              @appendEmptyParagraph="() => blockPickerIndex = i + 1"
            />
          </v-col>
           
        </v-row>
        </AGDraggable>
        <v-row            
            justify="center"
            class="mx-0"
          >
        <v-col
        v-bind="columnSize()"

        >
          <BlockPicker
            v-if="canAddChild"
            class="mb-12"
            data-testid="blockPicker"
            ref="appendBlockPicker"
            @blockSelected="(type, defaultProps) => addChild(type, block.childCount + 1, defaultProps)"
            @addParagraphBlock="text => addParagraphWithText(text, block.childCount + 1, false)"
            />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BlockPicker from '@/components/block/editor/BlockPicker.vue'
import CellInputBlock from '@/components/block/CellInputBlock.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import AGDraggable from '@/components/AGDraggable.vue'
import blockProps from '@/mixins/blockProps'
import BlockParentMixin from '@/mixins/blockParentMixin'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'

export default {
  mixins: [blockProps, BlockParentMixin],
  props: {
    inEditMode: {
      type: Boolean,
      default: () => false
    },
    hideChildPages: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    headerImageUrl() {
      return this.block?.headerImageUrl
    },


    title() {
      return this.block?.name || this.$t('blocks.emptyPageTitle')
    },
    children() {
      if (this.hideChildPages) {
        return this.block?.children.filter(child => child.type === 'page')
      }
      return this.block?.children
    }
  },
  watch: {
    blockUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) {
          return
        }
        this.loading = true
        try {
          this.loadBlock()
        } finally {
          this.loading = false
        }
      }
    },
    title: {
      immediate: true,
      handler(newVal) {
        document.title = newVal
      }
    },
    inEditMode: {
      handler(newVal) {
        this.$emit('editModeChange', newVal)
      }
    }
  },
  provide() {
    return {
      canAddChild: () => this.canAddChild
    }
  },
  methods: {

    columnSize(child) {
      const isFullWidth = child?.width === 'fullwidth' || this.block?.viewType === 'full'
      return {
        cols: 12,
        sm: isFullWidth ? 10 : 10,
        md: isFullWidth ? 10 : 9,
        lg: isFullWidth ? 11 : 7
      }
    },

    async updateBlock(block) {
      if (block.deleted || !this.canEdit) {
        return
      }
      block.updateValue()
      const firstHeader = this.block.children.find(child => child instanceof HeaderBlock)
      if (!this.block.name && firstHeader != null && firstHeader.uri === block.uri) {
        await this.block.patchName(block.text)
      }
    },

  },
  components: {
    CellInputBlock,
    BlockPicker,
    LoadingOverlay,
    AGDraggable
  }
}
</script>

<style scoped>
.block-page-root {
  width: 100%;
  background: white;
  position: inherit;
  overflow: auto;
}

.headerImage {
  object-fit: cover;
  max-height: 240px;
  min-height: 220px;
}

/* Add responsive heights for smaller screens */
@media (max-width: 600px) {
  .headerImage {
    max-height: 120px;
    min-height: 100px;
  }
}

.mobile-container {
  padding-left: 48px;
  padding-bottom: 72px;
}
</style>
