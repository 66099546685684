<template>
  <div class="mr-3" @click="launchFilePicker()" style="cursor: pointer;">

    <Avatar :size="size" :userId="userId" :initials="email"/>

    <input type="file"
      ref="file"
      :name="uploadFieldName"
      @change="onFileChange($event.target.name, $event.target.files)"
      style="display:none" />
      <!-- error dialog displays any potential errors -->
      <animated-dialog
        error
        v-model="errorDialog"
        :text="errorText"
      />

  </div>
</template>

<script>
import Avatar from '@/components/user/Avatar.vue'
import AnimatedDialog from '@/components/AnimatedDialog'

export default {
  props: {
    size: {
      type: Number,
      default: 72
    },
    email: {
      type: String,
      default: null
    }
  },
  components: {
    Avatar,
    AnimatedDialog
  },
  data () {
    return {
      errorDialog: null,
      errorText: '',
      uploadFieldName: 'file',
      maxSize: 1024
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    }
  },
  methods: {
    launchFilePicker(){
      this.$refs.file.click()
    },
    upload(imageFile){
      this.$store.dispatch('uploadAvatar', imageFile)
      this.$intercom?.trackEvent( 'User added avatar' )
    },
    onFileChange(fieldName, file) {
      const { maxSize } = this
      let imageFile = file[0]

      //check if user actually selected a file
      if (file.length>0) {
        let size = imageFile.size / maxSize / maxSize
        console.log(imageFile.type)

        if (!(imageFile.type == 'image/jpeg') ) {
          // check whether the upload is an image
          this.errorDialog = true
          this.errorText = this.$t('avatarEditor.errors.fileWrongFormat')

        } else if (size>1) {
          // check whether the size is greater than the size limit
          this.errorDialog = true
          this.errorText = this.$t('avatarEditor.errors.fileSizeToBig')
        } else {
          // upload file to s3
          this.upload(imageFile)
        }
      }
    }
  }
}
</script>
