<template>
  <BaseBlock
    :blockUri="blockUri"
    v-on="$listeners"
    :editorMode="editorMode"
    :grabbableClass="grabbableClass"
  >
    <template v-slot:menu>
      <MenuItem
        icon="mdi-cog-outline"
        :text="$t('blocks.blockSettings')"
        @click="openMenu"
      />
    </template>
    <div
      v-if="!embedUri"
      class="embed-empty d-flex flex-row gap-3 pa-3"
      @click="openMenu"
    >
      <v-icon color="#75746F">mdi-web</v-icon>
      <div>{{ $t('blockTypes.embed.subtitle') }}</div>
    </div>
    <div
      v-if="embedUri"
      class="iframe-container"
      ref="iframeContainer"
      :style="iframeHeight"
    >
      <iframe allow="microphone; camera" :src="embedUri" />
    </div>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="menuX"
      :position-y="menuY"
       min-width="320px"
    >
      <v-card>
        <v-card-title>{{ $t('blocks.embed.menuTitle') }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="uriInput"
            flat
            :label="$t('blocks.embed.uriInputLabel')"
            outlined
            dense
          />
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-end">
          <v-btn
            @click="setUri"
            :loading="loading"
            text
          >{{ $t('dialogs.saveButton') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import MenuItem from '@/components/MenuItem.vue'
import debounce from '@/utils/debounce'
import blockProps from '@/mixins/blockProps'

export default {
  mixins: [ blockProps ],
  props: {
  },
  data() {
    return {
      showMenu: false,
      uriInput: undefined,
      loading: false,
      heightInit: false,
      resizeObserver: undefined,
      menuX: 0,
      menuY: 0,
    }
  },
  watch: {
    embedUri: {
      immediate: true,
      async handler(newVal) {
        this.uriInput = newVal
        if (!newVal || this.resizeObserver != null) {
          return
        }
        await this.$nextTick()
        const debouncedSetHeight = debounce(this.setHeight, 500)
        this.resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.contentBoxSize) {
              const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[ 0 ] : entry.contentBoxSize
              if (!this.heightInit) {
                this.heightInit = true
                return
              }
              debouncedSetHeight(contentBoxSize.blockSize)
            }
          }
        })
        this.resizeObserver.observe(this.$refs.iframeContainer)
      },
    }
  },
  beforeDestroy() {
    this.resizeObserver?.unobserve(this.$refs.iframeContainer)
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    embedUri() {
      return this.block?.embedUri
    },
    iframeHeight() {
      if (!this.block.height) {
        return null
      }
      return {
        height: this.block.height
      }
    }
  },
  methods: {
    openMenu(event) {
      this.menuX = event.clientX
      this.menuY = event.clientY
      this.showMenu = true
    },
    async setUri() {
      this.loading = true
      try {
        this.block.setEmbedUri(this.uriInput)
        this.block.updateValue()
      } finally {
        this.loading = false
        this.showMenu = false
      }
    },
    setHeight(height) {
      this.loading = true
      try {
        this.block.setHeight(`${height}px`)
        this.block.updateValue()
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    BaseBlock,
    MenuItem
  },
}
</script>

<style lang="scss" scoped>
.embed-empty {
  background: #F2F1EE;
  color: #75746F;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.iframe-container {
  resize: vertical;
  min-height: 150px;
  overflow: auto;
}

iframe {
  height: 100%;
  width: 100%;
  border: none;
}
</style>