<template>
  <BaseStatefulView
    class="white"
    :persistentGridUri="gridUri"
    :virtualGridUri="virtualGridUri"
    :viewTitle="statefulView ? statefulView.name : ''"
    @reloadView="reloadView"
    :showToolbar="showToolbar"
    :embedded="embedded"
    :autoreload="autoreload"
  >
    <template
      v-slot:toolbar-left
      v-if="!loading && virtualGrid"
    >
      <div>
        <VirtualGridToolbar
          :virtualGrid="virtualGrid"
          :statefulViewUri="statefulViewUri"
          @filterMouseEnter="highlightFilteredColumns = true"
          @filterMouseLeave="highlightFilteredColumns = false"
          :labels="toolbarLabels"
          :sorting="false"
        />
      </div>
    </template>

    <div
      v-if="!loading && virtualGrid && statefulView"
      class="d-flex flex-column full-height"
      ref="listContainer"
    >
      <draggable
        v-model="listItems"
        class="list-container"
        :animation="200"
        :disabled="!canPatchView"
        @change="handleDragChange"
        :delay="120"
        :delayOnTouchOnly="true"
        :forceFallback="true"
        @start="onDragStart"
        @end="onDragEnd"

      >
        <template v-for="(item) in listItems">
          <ListViewItem
            :key="item.entity._id"
            :item="item"
            :canPatchView="canPatchView"
            :checked="getBooleanValue(item)"
            :hasBooleanField="hasBooleanField(item)"
            :userValue="getUserValue(item)"
            @edit="editEntity"
            @update-boolean="(value) => updateBooleanValue(item, value)"
            :class="{ 'cursor-move': canUpdateEntity( item.entity) }"

          >
            <template #item-actions="slotProps">
              <slot name="item-actions" v-bind="slotProps"></slot>
            </template>
          </ListViewItem>
        </template>
      </draggable>
    </div>

    <FullHeightLoader v-model="loading" />
    <EditEntityDialog
      :virtualGrid="virtualGrid"
      :entity="selectedEntity"
      v-model="editEntityDialog"
      :readOnly="!canPatchView"
    />
  </BaseStatefulView>
</template>

<script>
import BaseStatefulView from '@/views/BaseStatefulView.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import EditEntityDialog from '@/components/gridView/EditEntityDialog.vue'
import VirtualGridToolbar from '@/components/gridView/VirtualGridToolbar.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import draggable from 'vuedraggable'
import viewProps from '@/mixins/viewProps'
import ListViewItem from './ListViewItem.vue'

export default {
  name: 'ListView',
  mixins: [viewProps],
  components: {
    BaseStatefulView,
    FullHeightLoader,
    EditEntityDialog,
    VirtualGridToolbar,
    draggable,
    ListViewItem
  },

  data() {
    return {
      loading: false,
      selectedEntity: undefined,
      editEntityDialog: false,
      highlightFilteredColumns: false,
      isDragging: false
    }
  },

  watch: {
    statefulViewUri: {
      immediate: true,
      async handler() {
        this.loading = true
        try {
          const statefulView = await this.$store.dispatch(
            'AGReadStatefulViewOperation',
            this.statefulViewUri
          )
          await this.$store.dispatch('AGReadVirtualGridOperation', {
            virtualGridUri: statefulView.parentGridUri,
            loadEntities: false
          })
          statefulView.initShownFields()
          await this.$nextTick()
          this.virtualGrid.loadEntities()
        } finally {
          this.loading = false
        }
      }
    },
    isDragging: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            document.body.classList.add('no-select')
          }
          else {
            document.body.classList.remove('no-select')
          }
        }
      }
  },

  computed: {
    statefulView() {
      return this.$store.getters.statefulViewWithUri(this.statefulViewUri)
    },
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    virtualGrid() {
      return this.statefulView?.parentGrid
    },
    virtualGridUri() {
      return this.statefulView?.parentGridUri
    },
    canAddEntity() {
      return hasPermission(this.grid, [PERMISSIONS.addEntity])
    },
    canPatchView() {
      return hasPermission(this.statefulView, [PERMISSIONS.patch])
    },
    devMode() {
      return this.$store.getters.developerMode
    },
    listItems: {
      get() {
        if (!this.virtualGrid || !this.statefulView) return []
        return this.statefulView.listModel(this.virtualGrid)
      },
      set() {
        // The setter is required for v-model but we don't need to do anything here
        // since the actual reordering is handled in handleDragChange
      }
    },
  },

  methods: {
    reloadView() {
      this.virtualGrid.reload()
    },
    editEntity(entity) {
      this.selectedEntity = entity
      this.editEntityDialog = true
    },
    async handleDragChange(evt) {
      if (evt.moved) {
        // Get the moved item and its new index
        const { element, newIndex } = evt.moved
        // Ensure we have a valid entity
        if (element.entity) {
          await this.statefulView.reorderEntity(element.entity._id, newIndex, this.listItems)
        }
      }
    },
    hasBooleanField(item) {
      return this.statefulView.getBooleanField(item) !== undefined
    },
    getBooleanValue(item) {
      return this.statefulView.getBooleanValue(item)
    },
    async updateBooleanValue(item, newValue) {
      await this.statefulView.updateBooleanValue(item, newValue)
    },
    getUserField(item) {
      return this.statefulView.getUserField(item)
    },
    getUserValue(item) {
      return this.statefulView.getUserValue(item)
    },
    onDragStart() {
      this.isDragging = true
   },
   onDragEnd() {
    this.isDragging = false
   },
   canUpdateEntity(entity) {
    return hasPermission(entity, [PERMISSIONS.update])
  },
  }
}
</script>

<style scoped>
.full-height {
  height: 100%;
  background-color: white;
}

.list-container {
  padding: 8px;
  overflow-y: auto;
}
.drag-handle {
  cursor: move;
  color: #9e9e9e;
}

.list-item--checked {
  opacity: 0.75;
}

.cursor-move {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.cursor-move:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style> 