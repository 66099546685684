import KanbanView from '@/store/models/KanbanView'
import CalendarView from '@/store/models/CalendarView'
import SpreadsheetView from '@/store/models/SpreadsheetView'
import MapView from '@/store/models/MapView'
import GalleryView from '@/store/models/GalleryView'
import ListView from '@/store/models/ListView'

export default {
  kanban: {
    name: 'kanban',
    model: KanbanView,
    version: 1
  },
  spreadsheet: {
    name: 'spreadsheet',
    model: SpreadsheetView,
    version: 1
  },
  calendar: {
    name: 'calendar',
    model: CalendarView,
    version: 1
  },
  map: {
    name: 'map',
    model: MapView,
    version: 1
  },
  gallery: {
    name: 'gallery',
    model: GalleryView,
    version: 1
  },
  list: {
    name: 'list',
    model: ListView,
    version: 1
  }
}