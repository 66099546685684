<template>
  <div>
    <v-list-item-title>
      <EditableText
        class="pa-1 mb-1 black--text font-weight-medium"
        :text="name"
        @submit="newName => patchName(newName)"
        :showPencil="showPencil"
      />
    </v-list-item-title>
    <BaseLinkView
      :uri="uri"
      :buttons="buttons"
      :moreItems="moreMenuItems"
    />
    <LinkRestrictionsDialog
      v-model="restrictionDialog"
      :link="link"
    />
  </div>
</template>

<script>
import BaseLinkView from '@/components/BaseLinkView.vue'
import EditableText from '@/components/EditableText.vue'
import LinkRestrictionsDialog from '@/components/LinkRestrictionsDialog.vue'

export default {
  props: {
    link: null,
    showPencil: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      restrictionDialog: false
    }
  },
  computed: {
    uri() {
      return this.link?.redirectUri
    },
    name() {
      return this.link?.name
    },
    absoluteUri() {
      if (this.uri == null) {
        return
      }
      return `${window.location.origin}${this.uri}`
    },
    buttons() {
      return [
        {
          icon: 'mdi-share',
          color: 'primary',
          tooltip: this.$t('forms.shareForm.buttons.open'),
          onClick: this.open,
          testId: 'openSharedFormButton'
        },
        {
          icon: 'mdi-content-copy',
          color: 'primary',
          onClick: this.copyLink,
          tooltip: this.$t('forms.shareForm.buttons.copy'),
          testId: 'copySharedFormLinkButton'
        },
        {
          icon: 'mdi-qrcode',
          color: 'primary',
          onClick: this.showQrCode,
          tooltip: this.$t('forms.shareForm.buttons.qrCode'),
          testId: 'showFormLinkQrCodeButton'
        },
        {
          icon: 'mdi-lock-open-variant-outline',
          color: 'primary',
          onClick: this.showRestrictionsDialog,
          tooltip: this.$t('forms.shareForm.buttons.setRestrictions'),
          testId: 'showFormLinkRestrictionsDialogButton'
        },
      ]
    },
    moreMenuItems() {
      return [
        {
          icon: 'mdi-application-brackets-outline',
          text: this.$t('forms.shareForm.embed.open'),
          onClick: this.openEmbeddedEditor,
          testId: 'openEmbeddedEditorButton'
        },
        {
          icon: 'mdi-pencil',
          text: this.$t('forms.shareForm.buttons.openPrefillEditor'),
          onClick: this.showPrefillFormEditor,
          testId: 'showPrefillFormEditorButton'
        },
        {
          icon: 'mdi-delete-outline',
          text: this.$t('virtualGrid.qrCode.dialog.buttons.remove'),
          color: 'red',
          onClick: this.deleteLink,
          testId: 'deleteLinkButton'
        },
      ]
    }
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.absoluteUri)
    },
    showQrCode() {
      this.$emit('showQrCode')
    },
    open() {
      window.open(this.absoluteUri, '_blank')
    },
    openEmbeddedEditor() {
      const routeData = this.$router.resolve({
        name: 'EmbeddedFormEditor',
        params: {
          sharedUrl: this.absoluteUri,
      }})
      window.open(routeData.href, '_blank')
    },
    showPrefillFormEditor() {
      this.$emit('showPrefillFormEditor')
    },
    async deleteLink() {
      await this.link.delete()
      this.$emit('linkDeleted')
    },
    patchName(newName) {
      this.link.patchName(newName)
    },
    showRestrictionsDialog() {
      this.restrictionDialog = true
    }
  },
  components: {
    BaseLinkView,
    EditableText,
    LinkRestrictionsDialog
}
}
</script>