<template>
  <IconPicker v-model="externalModel" :show-emojis="true" :show-file-icons="true" :default-icon="defaultIcon" />
</template>

<script>
import IconPicker from '../common/IconPicker.vue'
import externalModel from '@/mixins/externalModel'

export default {
  name: 'PageBlockIconPicker',
  mixins: [externalModel],
  components: {
    IconPicker
  },
  data() {
    return {
      defaultIcon: {
        iconset: 'mdi',
        value: 'file-document-outline',
        color: '#616161'
      }
    }
  }
}
</script> 