<template>
    <div>
        <v-menu max-height="400px" v-model="menuOpen" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn  v-if="pickerOnly" v-bind="attrs" v-on="on" icon small color="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <div v-else>
                    <ParagraphTextField
                        ref="paragraphField"
                        :placeholder="defaultOpen ? $t('blocks.pickerSearchPlaceholder') : $t('blocks.pickerPlaceholder')"
                        v-model="text"
                        :editorMode="true"
                        class="paragraph-text-field pt-6"
                        @onBlur="onBlur"
                        @openBlockPicker="menuOpen = true"
                        @appendEmptyParagraph="addParagraphBlock"
                        :autofocus="autofocus"
                    />
              
                </div>
            </template>
            <v-list dense>
                <v-list-item v-for="item in blockItems" :key="item.displayTitleString" @click="onBlockSelected(item)">
                    <v-list-item-avatar v-if="item.icon">
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="item.displayTitleString">
                        <v-list-item-title>{{ item.displayTitleString }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.displaySubtitleString }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-if="item.header">
                        <v-list-item-title>{{ item.header }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>


</template>

<script>
import { blockTypes } from '@/constants/blockTypes.js'
import ParagraphTextField from '@/components/block/ParagraphTextField.vue'
export default {
    props: {
        autofocus: {
            type: Boolean,
            default: false
        },
        pickerOnly: {
            type: Boolean,
            default: false
        },
        defaultOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedBlock: null,
            blockPrototypes: null,
            search: null,
            text: '',
            menuOpen: false
        }
    },
    components: {
        ParagraphTextField
    },
    computed: {
        alphaMode() {
            return this.$store.getters.alphaMode
        },
        searchText() {
            return (this.text && this.text.trim()) ? 
                this.text.toLowerCase().trim().replace(/[/\s]+/g, '') : ''
        },
        hasNonHeaderItems() {
            return this.blockItems.some(item => !item.header)
        },
        blockItems() {
            var filteredItems = [
                ...this.blockBasicItems,
                ...this.blockSpecialNodes,
                ...this.blockInputItems
            ]
            
            if (this.searchText) {
                filteredItems = filteredItems.filter(item => {
                    if (item.header) return true // Keep headers
                    const title = item.displayTitleString?.toLowerCase() || ''
                    const subtitle = item.displaySubtitleString?.toLowerCase() || ''
                    const searchText = this.searchText
                    return title.includes(searchText) || subtitle.includes(searchText)
                })
            }
            
            return filteredItems
        },
        blockBasicItems() {
            return [
                {header: this.$t('blocks.basicHeader')},
                blockTypes.paragraph, // A paragraph of text
                blockTypes.header1,
                blockTypes.header2,
                blockTypes.header3,
                blockTypes.page,
                blockTypes.horizontalLayout,
                blockTypes.image,
            ]
        },
        blockSpecialNodes() {
            var items = [
                {header: this.$t('blocks.specialHeader')},
                blockTypes.embed,
                blockTypes.resourceBlock,
                blockTypes.externalHookButtonBlock,
                blockTypes.gridCountCardBlock,
            ]
            if (this.alphaMode) {
                items.push(blockTypes.html)
                items.push(blockTypes.entityList)
            }
            return items
        },
        blockInputItems() {
            const items = [
                {header: this.$t('blocks.formFieldHeader')},
                blockTypes.text, // Text is often used for general input
                blockTypes.textArea, // Text area is often used for longer text input
                blockTypes.email, // Essential for contact forms
                blockTypes.phonenumber, // Also commonly used in forms
                blockTypes.date, // Date inputs for events, deadlines, etc.
                blockTypes.dateandtime, // Specific for capturing date and time
                blockTypes.decimal, // For financial and other numerical inputs
                blockTypes.integer, // Common for quantity, age, etc.
                blockTypes.enum, // Dropdowns are very common in forms
                blockTypes.boolean, // Toggles for yes/no, true/false questions
                blockTypes.signature, // For agreements or consents
                blockTypes.enumcollection, // Multiple choice questions
                // blockTypes.user, // User selection from a predefined list
                 blockTypes.entity, // Selecting an entity such as a product or category
                blockTypes.entityCollectionInput, // Selecting multiple entities
                blockTypes.address, // For complete address inputs
                blockTypes.location, // For location data
                // blockTypes.resourceInput, // For linking resources
                blockTypes.uri, // For URL inputs
                blockTypes.attachmentcollection, // For handling multiple files
                blockTypes.currency, // Specific for monetary values
                blockTypes.video,
                // {header: this.$t('blocks.specialHeader')},
                // blockTypes.grid, // To select a grid as reference
                // blockTypes.multitext, // A list of text inputs
                // blockTypes.mimeentity, // Specific use cases like emails
                // blockTypes.mimeentitycollection, // Collections of MIME entities
            ]
            if (this.alphaMode) {
                items.push(blockTypes.voiceRecorder)
            }
            return items
        }
    },
    methods: {
        updateMenuState() {
            if (!this.defaultOpen) {
                return
            }

            if (!this.text || !this.text.trim()) {
                this.menuOpen = false
                return
            }

            if (this.text.length >= 10) {
                this.menuOpen = false
                return
            }

            this.menuOpen = this.hasNonHeaderItems
        },
        async onBlur() {
            // add paragraph block if text is not empty and menu is not open
            if (this.text && this.text.trim() && !this.menuOpen) {
                this.$emit('addParagraphBlock', this.text)
                this.text = ''
            } else if (!this.menuOpen) {
                // remove block picker if menu is not open and text is empty
                this.$emit('removeBlockPicker')
            }
        },
        addParagraphBlock() {
            this.$emit('addParagraphBlock', this.text)
            this.text = ''
        },
        async onBlockSelected(block) {
            if (block != null && typeof block === 'object') {
                this.$emit('blockSelected', block.type, block.defaultProps)
            } else if (typeof block === 'string' && block.length > 0) {
                this.$emit('addParagraphBlock', block)
                this.text = ''
            }
            this.search = null
            await this.$nextTick()
            this.selectedBlock = null
            this.$emit('removeBlockPicker')
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.menuOpen = this.defaultOpen
        })
    },
    watch: {
        text() {
            this.updateMenuState()
        },
        blockItems() {
            this.updateMenuState()
        },
    },
    emits: ['addParagraphBlock', 'removeBlockPicker', 'blockSelected']
}
</script>

<style scoped>

</style>