import HalObject from '@/store/models/HalObject.js'
import i18n from '@/plugins/i18n.js'
import store from '@/store/store.js'

export default class Link extends HalObject {
  constructor(data) {
    super(data)
    this.name = data.name
    this.type = data.type
    this.metaType = data.metaType
    this.uri = data.uri
    this.gridName = data.gridName
    this.formName = data.formName
    this.redirectUri = data.redirectUri
    this.requiresAuthorization = data.requiresAuthorization ?? false
    this.publicAccess = data.publicAccess ?? true
    this.requiresPassword = data.requiresPassword ?? false
    this.displayValue = data.displayValue

  }

  get typeDisplay() {
    return i18n.te(`links.types.${this.type}`) ? i18n.t(`links.types.${this.type}`) : ''
  }

  async patchName(newName) {
    await store().dispatch('AGPatchLinkNameOperation', {
      link: this,
      newName
    })
    this.name = newName
  }

  async setRestrictions({role, requiresAuthorization, publicAccess, requiresPassword, username, password}) {
    let payload = {
      role,
      requiresAuthorization,
      publicAccess,
      requiresPassword
    }
    if (requiresPassword === true) {
      payload = {
        ...payload,
        username,
        password
      }
    }
    await store().dispatch('AGPatchLinkRestrictionOperation', {
      link: this,
      ...payload
    })
    this.role = role
    this.requiresAuthorization = requiresAuthorization
    this.publicAccess = publicAccess
    this.requiresPassword = requiresPassword
  }

  delete() {
    return store().dispatch('AGDeleteLinkOperation', {
      link: this
    })
  }
}