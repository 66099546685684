import { COLORS, TEXT_ALIGNMENTS } from './blockAnnotations'
import CellInputBlockVue from '@/components/block/CellInputBlock.vue'
import EmbedBlockVue from '@/components/block/EmbedBlock.vue'
import HeaderBlockVue from '@/components/block/HeaderBlock.vue'
import LayoutBlockVue from '@/components/block/LayoutBlock.vue'
import ParagraphBlockVue from '@/components/block/ParagraphBlock.vue'
import ResourceBlockVue from '@/components/block/ResourceBlock.vue'
import VideoBlockVue from '@/components/block/VideoBlock.vue'
import VoiceRecorderBlockVue from '@/components/block/VoiceRecorderBlock.vue'
import GridCountCardBlockVue from '@/components/block/GridCountCardBlock.vue'
import HtmlJSBlock from '@/components/block/HtmlJSBlock.vue'
import EntityListBlockVue from '@/components/block/EntityListBlock.vue'
import ImageBlockVue from '@/components/block/ImageBlock.vue'

import i18n from '@/plugins/i18n'
import EmbedBlock from '@/store/models/blocks/EmbedBlock'
import FormInputBlock from '@/store/models/blocks/FormInputBlock'
import HeaderBlock, { HEADER_LEVELS } from '@/store/models/blocks/HeaderBlock'
import LayoutBlock from '@/store/models/blocks/LayoutBlock'
import PageBlock from '@/store/models/blocks/PageBlock'
import ParagraphBlock from '@/store/models/blocks/ParagraphBlock'
import ResourceBlock from '@/store/models/blocks/ResourceBlock'
import StatefulFormInputBlock from '@/store/models/blocks/StatefulFormInputBlock'
import VideoBlock from '@/store/models/blocks/VideoBlock'
import ExternalHookButtonBlockVue from '@/components/block/ExternalHookButtonBlock.vue'
import PageBlockVue from '@/components/block/PageBlockVue.vue'
import HtmlBlock from '@/store/models/blocks/HtmlBlock'
import EntityListBlock from '@/store/models/blocks/EntityListBlock'
import ImageBlock from '@/store/models/blocks/ImageBlock'

// Define blockTypes as a plain object with properties for each type
export const blockTypes = {
  paragraph: {
    type: 'paragraph',
    component: ParagraphBlockVue,
    modelClass: ParagraphBlock,
    displayTitleString: i18n.t('blockTypes.paragraph.title'),
    displaySubtitleString: i18n.t('blockTypes.paragraph.subtitle'),
    icon: 'mdi-text-long',
    defaultProps: {
      annotations: {
        textAlign: null,
        color: COLORS.DEFAULT_TEXT,
      }
    },
  },
  header1: {
    type: 'header',
    component: HeaderBlockVue,
    modelClass: HeaderBlock,
    displayTitleString: i18n.t('blockTypes.header1.title'),
    displaySubtitleString: i18n.t('blockTypes.header1.subtitle'),
    icon: 'mdi-format-header-1',
    defaultProps: {
      level: HEADER_LEVELS[1].backendName,
      annotations: {
        textAlign: TEXT_ALIGNMENTS.LEFT,
        color: COLORS.DEFAULT_TEXT,
      }
    },
    match: (blockData) => {
      return parseInt(blockData.header.level) === HEADER_LEVELS[1].backendName
    }
  },
  header2: {
    type: 'header',
    component: HeaderBlockVue,
    modelClass: HeaderBlock,
    displayTitleString: i18n.t('blockTypes.header2.title'),
    displaySubtitleString: i18n.t('blockTypes.header2.subtitle'),
    icon: 'mdi-format-header-2',
    defaultProps: {
      level: HEADER_LEVELS[2].backendName,
      annotations: {
        textAlign: TEXT_ALIGNMENTS.LEFT,
        color: COLORS.DEFAULT_TEXT,
      }
    },
    match: (blockData) => {
      return parseInt(blockData.header.level) === HEADER_LEVELS[2].backendName
    }
  },
  header3: {
    type: 'header',
    component: HeaderBlockVue,
    modelClass: HeaderBlock,
    displayTitleString: i18n.t('blockTypes.header3.title'),
    displaySubtitleString: i18n.t('blockTypes.header3.subtitle'),
    icon: 'mdi-format-header-3',
    defaultProps: {
      level: HEADER_LEVELS[3].backendName,
      annotations: {
        textAlign: TEXT_ALIGNMENTS.LEFT,
        color: COLORS.DEFAULT_TEXT,
      }
    },
    match: (blockData) => {
      return parseInt(blockData.header.level) === HEADER_LEVELS[3].backendName
    }
  },
  string: {
    type: 'string',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.text'),
    icon: 'mdi-alphabetical-variant',
  },
  grid: {
    type: 'grid',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.grid'),
    icon: 'mdi-grid-large',
  },
  dateandtime: {
    type: 'dateAndTimeInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.dateTime'),
    icon: 'mdi-calendar-clock',
  },
  dictionary: {
    type: 'dictionary',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.dictionary'),
    icon: 'mdi-book-open-page-variant',
  },
  attachmentcollection: {
    type: 'attachmentCollectionInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock, 
    displayTitleString: i18n.t('blockTypes.attachmentCollection.title'),
    displaySubtitleString: i18n.t('blockTypes.attachmentCollection.subtitle'),
    icon: 'mdi-paperclip',
  },
  uri: {
    type: 'uriInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.uri.title'),
    displaySubtitleString: i18n.t('blockTypes.uri.subtitle'),
    icon: 'mdi-web',
  },
  decimal: {
    type: 'decimalInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.decimal.title'),
    displaySubtitleString: i18n.t('blockTypes.decimal.subtitle'),
    icon: 'mdi-decimal',
  },
  mimeentitycollection: {
    type: 'mimeentitycollection',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.mimeEntityCollection'),
    icon: 'mdi-email-multiple',
  },
  entity: {
    type: 'entityInput',
    component: CellInputBlockVue,
    modelClass: StatefulFormInputBlock,
    displayTitleString: i18n.t('blockTypes.entity.title'),
    displaySubtitleString: i18n.t('blockTypes.entity.subtitle'),
    icon: 'mdi-table-arrow-right',
  },
  integer: {
    type: 'integerInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.integer.title'),
    displaySubtitleString: i18n.t('blockTypes.integer.subtitle'),
    icon: 'mdi-numeric',
  },
  mimeentity: {
    type: 'mimeentity',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.mimeEntity'),
    icon: 'mdi-email',
  },
  enumcollection: {
    type: 'enumCollectionInput',
    component: CellInputBlockVue,
    modelClass: StatefulFormInputBlock,
    displayTitleString: i18n.t('blockTypes.enumCollection.title'),
    displaySubtitleString: i18n.t('blockTypes.enumCollection.subtitle'),
    icon: 'mdi-format-list-bulleted',
  },
  user: {
    type: 'userInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.user'),
    icon: 'mdi-account',
  },
  location: {
    type: 'geoLocationInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.location'),
    icon: 'mdi-map-marker',
  },
  boolean: {
    type: 'booleanInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.boolean'),
    icon: 'mdi-toggle-switch',
  },
  currency: {
    type: 'currencyInput',
    component: CellInputBlockVue,
    modelClass: StatefulFormInputBlock,
    displayTitleString: i18n.t('blockTypes.currency'),
    icon: 'mdi-cash',
    defaultProps: {
      type: {
        name: 'currency',
        currency: 'EUR'
      }
    },
  },
  date: {
    type: 'dateInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.date'),
    icon: 'mdi-calendar',
  },
  multitext: {
    type: 'multitext',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.multiText'),
    icon: 'mdi-text-box-multiple',
  },
  address: {
    type: 'addressInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.address'),
    icon: 'mdi-home-city',
  },
  enum: {
    type: 'enumInput',
    component: CellInputBlockVue,
    modelClass: StatefulFormInputBlock,
    displayTitleString: i18n.t('blockTypes.enum.title'),
    displaySubtitleString: i18n.t('blockTypes.enum.subtitle'),
    icon: 'mdi-arrow-down-drop-circle-outline',
  },
  text: {
    type: 'textInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.text.title'),
    displaySubtitleString: i18n.t('blockTypes.text.subtitle'),
    icon: 'mdi-form-textbox',
    match: (blockData) => {
      return blockData.textInput.multiline === false || !blockData.textInput.multiline 
    }
  },
  textArea: {
    type: 'textInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.textArea.title'),
    displaySubtitleString: i18n.t('blockTypes.textArea.subtitle'),
    icon: 'mdi-form-textarea',
    defaultProps: {
      multiline: true,
      type: {
        name: 'string',
      }
    },
    match: (blockData) => {
      return blockData.textInput.multiline === true
    }
  },
  entityCollectionInput: {
    type: 'entityCollectionInput',
    component: CellInputBlockVue,
    modelClass: StatefulFormInputBlock,
    displayTitleString: i18n.t('blockTypes.entityCollection.title'),
    displaySubtitleString: i18n.t('blockTypes.entityCollection.subtitle'),
    icon: 'mdi-table-multiple',
  },
  signature: {
    type: 'signatureInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.signature.title'),
    displaySubtitleString: i18n.t('blockTypes.signature.subtitle'),
    icon: 'mdi-signature-freehand',
  },
  email: {
    type: 'emailInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.email.title'),
    displaySubtitleString: i18n.t('blockTypes.email.subtitle'),
    icon: 'mdi-at',
  },
  phonenumber: {
    type: 'phoneNumberInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.phoneNumber'),
    icon: 'mdi-phone',
  },
  attachment: {
    type: 'attachmentInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.attachment'),
    icon: 'mdi-attachment',
  },
  voiceRecorder: {
    type: 'attachmentInput',
    component: VoiceRecorderBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.voiceRecorder.title'),
    displaySubtitleString: i18n.t('blockTypes.voiceRecorder.subtitle'),
    icon: 'mdi-microphone',
    defaultProps: {
      viewType: 'voiceRecorder'
    },
    match: (blockData) => {
      return blockData.attachmentInput?.viewType === 'voiceRecorder' || 
             blockData.attachmentInput?.type?.viewType === 'voiceRecorder'
    }
  },
  resourceInput: {
    type: 'resourceInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.resource'),
    icon: 'mdi-link-variant',
    defaultProps: {
      type: {
        name: 'resource',
        validResourceTypes: ['view', 'externalHook']
      }
    },
  },
  resourceBlock: {
    type: 'resourceBlock',
    component: ResourceBlockVue,
    modelClass: ResourceBlock,
    displayTitleString: i18n.t('blockTypes.resourceBlock.title'),
    displaySubtitleString: i18n.t('blockTypes.resourceBlock.subtitle'),
    icon: 'mdi-table',
    defaultProps: {
      type: {
        name: 'resource',
        validResourceTypes: ['view']
      }
    },
    match: (blockData) => {
      return blockData.resourceBlock.type.name === 'resource' && blockData.resourceBlock.type.validResourceTypes?.includes('view') && blockData.resourceBlock?.viewType == null
    }
  }, 
  externalHookButtonBlock: {
    type: 'resourceBlock',
    component: ExternalHookButtonBlockVue,
    modelClass: ResourceBlock,
    displayTitleString: i18n.t('blockTypes.externalHookButtonBlock.title'),
    displaySubtitleString: i18n.t('blockTypes.externalHookButtonBlock.subtitle'),
    icon: 'mdi-button-cursor',
    defaultProps: {
      type: {
        name: 'resource',
        validResourceTypes: ['externalHook'],
        annotations: {  
          color: COLORS.DEFAULT_BUTTON_TEXT_COLOR,
          backgroundColor: COLORS.DEFAULT_BUTTON_BACKGROUND,
        }
      }
    },
    match: (blockData) => {
      return blockData.resourceBlock.type.name === 'resource' && blockData.resourceBlock.type.validResourceTypes?.includes('externalHook')
    }
  },
  gridCountCardBlock: {
    type: 'resourceBlock',
    component: GridCountCardBlockVue,
    modelClass: ResourceBlock,
    displayTitleString: i18n.t('blockTypes.gridCountCardBlock.title'),
    displaySubtitleString: i18n.t('blockTypes.gridCountCardBlock.subtitle'),
    icon: 'mdi-counter',
    defaultProps: {
      type: {
        name: 'resource',
        validResourceTypes: ['view']
      },
      viewType: 'gridCountCard'
    },
    match: (blockData) => {
      return blockData.resourceBlock.type.name === 'resource' && blockData.resourceBlock.type.validResourceTypes?.includes('view') && blockData.resourceBlock.viewType === 'gridCountCard'
    }
  }, 
  block: {
    type: 'block',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.block'),
    icon: 'mdi-file-document-outline',
  },
  page: {
    type: 'page',
    component: PageBlockVue,
    modelClass: PageBlock,
    displayTitleString: i18n.t('blockTypes.page.title'),
    displaySubtitleString: i18n.t('blockTypes.page.subtitle'),
    icon: 'mdi-file-document-outline',
  },
  embed: {
    type: 'IFrame',
    component: EmbedBlockVue,
    modelClass: EmbedBlock,
    displayTitleString: i18n.t('blockTypes.embed.title'),
    displaySubtitleString: i18n.t('blockTypes.embed.subtitle'),
    icon: 'mdi-application-outline',
  },
  video: {
    type: 'videoInput',
    component: VideoBlockVue,
    modelClass: VideoBlock,
    displayTitleString: i18n.t('blockTypes.video.title'),
    displaySubtitleString: i18n.t('blockTypes.video.subtitle'),
    icon: 'mdi-play-box-outline',
  },
  image: {
    type: 'image',
    component: ImageBlockVue,
    modelClass: ImageBlock,
    displayTitleString: i18n.t('blockTypes.image.title'),
    displaySubtitleString: i18n.t('blockTypes.image.subtitle'),
    icon: 'mdi-image',
  },
  horizontalLayout: {
    type: 'layout',
    component: LayoutBlockVue,
    modelClass: LayoutBlock,
    displayTitleString: i18n.t('blockTypes.horizontalLayout.title'),
    displaySubtitleString: i18n.t('blockTypes.horizontalLayout.subtitle'),
    icon: 'mdi-table-row',
    defaultProps: {
      layoutMode: 'HORIZONTAL',
      mainAxisAlignItems: 'START',
      counterAxisAlignItems: 'MIN',
      wrap: false
    },
    match: (blockData) => {
      return blockData.layout.type.name === 'layout' && blockData.layout.layoutMode === 'HORIZONTAL'
    }
  },
  html: {
    type: 'html',
    component: HtmlJSBlock,
    modelClass: HtmlBlock,
    displayTitleString: i18n.t('blockTypes.html.title'),
    displaySubtitleString: i18n.t('blockTypes.html.subtitle'),
    icon: 'mdi-language-html5',
  },
  entityList: {
    type: 'entityList',
    component: EntityListBlockVue,
    modelClass: EntityListBlock,
    displayTitleString: i18n.t('blockTypes.entityList.title'),
    displaySubtitleString: i18n.t('blockTypes.entityList.subtitle'),
    icon: 'mdi-format-list-text',
  },
}
