import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class ParagraphBlock extends Block {
  constructor(data) {
    super(data)
    this.isDirty = false
  }

  get text() {
    return this.paragraph.text
  }

  set text(newVal) {
    if (newVal === this.paragraph.text) {
      return
    }
    this.paragraph.text = newVal
    this.isDirty = true
  }

  get textAlign() {
    return this[this.type].annotations.textAlign
  }

  set textAlign(newVal) {
    this[this.type].annotations.textAlign = newVal
    this.updateAnnotations()
  }

  get fontWeight() {
    return this[this.type].annotations.fontWeight
  }

  set fontWeight(newVal) {
    this[this.type].annotations.fontWeight = newVal
    this.updateAnnotations()
  }

  get fontSize() {
    return this[this.type].annotations.fontSize
  }

  set fontSize(newVal) {
    this[this.type].annotations.fontSize = newVal
    this.updateAnnotations()
  }

  async updateValue() {
    if (!this.isDirty) {
      return
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        paragraph: {
          text: this.text
        }
      }
    })
    this.isDirty = false
  }
}