import { render, staticRenderFns } from "./PageBlockVue.vue?vue&type=template&id=5b09b2b3&scoped=true"
import script from "./PageBlockVue.vue?vue&type=script&lang=js"
export * from "./PageBlockVue.vue?vue&type=script&lang=js"
import style0 from "./PageBlockVue.vue?vue&type=style&index=0&id=5b09b2b3&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b09b2b3",
  null
  
)

export default component.exports