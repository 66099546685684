<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass">
    <ParagraphTextField
        ref="paragraphField"
        :placeholder="$t('blocks.emptyParagraph')"
        v-model="text"
        @onBlur="updateBlock"
        @delete="deleteBlock"
        @appendEmptyParagraph="appendEmptyParagraph"
        @appendParagraphWithText="appendParagraphWithText"
        @mergeParagraphs="mergeParagraphs"
        :editorMode="editorMode"
        :class="textAlignClass"
        :style="{ color: color }"
      />
    <template v-slot:menu>
      <TextFormatMenu :block="block" />
      
      <SubMenu 
        :activatorText="$t('blocks.switchType')"
      >
        <v-list-item
          v-for="(typeItem, index) in availableBlockTypes"
          :key="index"
          @click="handleBlockTypeSwitch(typeItem)"
        >
          <v-list-item-avatar>
            <v-icon>{{typeItem.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ typeItem.displayTitleString }}</v-list-item-title>
            <v-list-item-subtitle>{{ typeItem.displaySubtitleString }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </SubMenu>
    </template>
    <template v-slot:right-controls>
      <ProofRead v-if="developerMode && editorMode" :originalText="text" @apply="(newText) => text = newText"></ProofRead>
    </template>

  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import ProofRead from '@/components/block/ProofRead.vue'
import SubMenu from '@/components/SubMenu.vue'
import ParagraphTextField from '@/components/block/ParagraphTextField.vue'
import TextFormatMenu from './TextFormatMenu.vue'
import { blockTypes } from '@/constants/blockTypes'
import blockProps from '@/mixins/blockProps'
import { COLORS } from '@/constants/blockAnnotations'
export default {
  mixins: [ blockProps ],
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    text: {
      get() {
        return this.block.text
      },
      set(newVal) {
        this.block.text = newVal
      }
    },
    developerMode() {
      return this.$store.getters.developerMode
    },
    availableBlockTypes() {
      return Object.values(blockTypes).filter(type => 
        type.type === 'header'
      )
    },
    textAlignClass() {
      return `text-${this.block?.textAlign}`
    },
    color() {
      return this.block?.color ?? COLORS.DEFAULT_TEXT
    }
  },
  methods: {
    updateBlock() {
      if (this.block.isDirty) {
        this.$emit('updateBlock')
      }
    },
    appendEmptyParagraph() {
      this.$emit('appendEmptyParagraph')
    },
    appendParagraphWithText(text) {
      this.$emit('appendParagraphWithText', text)
    },
    deleteBlock() {
      this.$emit('delete')
    },
    mergeParagraphs() {
      this.$emit('mergeParagraphs', this.block)
    },
    focus() {
      this.$refs.paragraphField.focus()
    },
    handleBlockTypeSwitch(newType) {
      this.$emit('switchBlockType', newType, this.block)
    }
  },
  components: {
    BaseBlock,
    ProofRead,
    SubMenu,
    ParagraphTextField,
    TextFormatMenu
  }
}
</script>

<style lang="scss" scoped>
.append-menu {
  position: relative;
  left: 100px;
}
</style>
