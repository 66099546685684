<template>
  <div class="voice-recorder">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters class="recorder-wrapper">
        <v-col v-if="isLoading" cols="12" class="d-flex justify-center align-center" style="min-height: 300px;">
          <v-progress-circular
            indeterminate
            color="primary"
            size="32"
          ></v-progress-circular>
          <div class="ml-3">{{ $t('voiceRecorder.loading') }}</div>
        </v-col>
        
        <template v-else>
          <v-col cols="12" class="text-center">
            <div class="timer-display">
              {{ displayTime }}
            </div>
          </v-col>
          
          <v-col cols="12">
            <v-row justify="center" align="center" class="mt-8">
              <v-btn
                icon
                large
                @click="skipBackward"
                :disabled="isRecording || !audioUrl || disabled"
                class="mx-4"
              >
                <v-icon size="32">mdi-rotate-left</v-icon>
                <div class="skip-label">15</div>
              </v-btn>

              <v-btn
                color="primary"
                
                fab text
                @click="togglePlayback"
                :disabled="isRecording || !audioUrl || disabled"
                class="mx-4"
              >
                <v-icon size="36">{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon>
              </v-btn>

              <v-btn
                icon
                large
                @click="skipForward"
                :disabled="isRecording || !audioUrl || disabled"
                class="mx-4"
              >
                <v-icon size="32">mdi-rotate-right</v-icon>
                <div class="skip-label">15</div>
              </v-btn>
            </v-row>
          </v-col>

          <v-col cols="12" class="text-center mt-8">
            <v-row justify="center" no-gutters>
              <v-col cols="4" class="">

              </v-col>
              <v-col cols="4" class="">
                <v-btn
                  :color="isRecording ? 'error' : 'primary'"
                  rounded
                  outlined
                  :disabled="!hasPermission || disabled"
                  @click="toggleRecording"
                >
                  {{ buttonText }}
                </v-btn>
              </v-col>
              
              <v-col cols="4" class="" >
                <v-btn
                  color="success"
                  rounded
                  text

                  :disabled="disabled || !audioUrl || isRecording"
                  @click="finishRecording"
                >
                  {{ $t('voiceRecorder.finish') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>

      <audio 
        ref="audioPlayer" 
        :src="displayUrl" 
        @ended="handlePlaybackEnd"
        @timeupdate="handleTimeUpdate"
        @loadedmetadata="handleMetadataLoaded"
      ></audio>

      <v-row v-if="!hasPermission && !isLoading" class="mt-4">
        <v-col>
          <v-alert type="warning" text dense>
            {{ $t('voiceRecorder.allowMicrophone') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'VoiceRecorder',
  
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: true,
      isRecording: false,
      isPlaying: false,
      hasPermission: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      recordingDuration: 0,
      currentTime: 0,
      totalDuration: 0,
      recordingInterval: null,
      audioBlob: null,
      stream: null,
      isPaused: false,
      recordingStartTime: null
    }
  },

  computed: {
    displayUrl() {
      return this.audioUrl || (this.value?.url || this.value)
    },

    buttonText() {
      if (this.isRecording) return this.$t('voiceRecorder.pause')
      if (this.isPaused) return this.$t('voiceRecorder.continue')
      return this.$t('voiceRecorder.start')
    },

    displayTime() {
      if (this.isRecording || this.isPaused && !this.isPlaying) {
        return this.formatDuration(this.recordingDuration)
      }
      if (this.isPlaying || this.audioUrl) {
        return this.formatDuration(this.currentTime)
      }
      return this.formatDuration(0)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue && !this.audioUrl) {
          this.recordingDuration = 0
        }
      }
    }
  },

  async mounted() {
    this.isLoading = true
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      this.hasPermission = true
      this.initializeRecorder()
    } catch (error) {
      console.error('Microphone permission denied:', error)
      this.hasPermission = false
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    initializeRecorder() {
      const options = {
        mimeType: this.getSupportedMimeType()
      }
      
      this.mediaRecorder = new MediaRecorder(this.stream, options)
      
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data)
        }
      }

      this.mediaRecorder.onstop = () => {
        const mimeType = this.mediaRecorder.mimeType
        this.audioBlob = new Blob(this.audioChunks, { type: mimeType })
        this.audioUrl = URL.createObjectURL(this.audioBlob)
      }
    },

    getSupportedMimeType() {
      const types = [
        'audio/webm',
        'audio/mp4',
        'audio/ogg',
        'audio/wav'
      ]
      
      for (const type of types) {
        if (MediaRecorder.isTypeSupported(type)) {
          return type
        }
      }
      
      return ''  // Let the browser choose the default
    },

    toggleRecording() {
      if (this.isRecording) {
        this.pauseRecording()
      } else {
        this.startRecording()
      }
    },

    startRecording() {
      try {
        if (!this.isPaused) {
          this.audioChunks = []
          this.recordingDuration = 0
          this.recordingStartTime = Date.now()
        } else {
          this.recordingStartTime = Date.now() - (this.recordingDuration * 1000)
        }
        
        this.mediaRecorder.start(1000)
        this.isRecording = true
        this.isPaused = false
        
        if (!this.recordingInterval) {
          this.recordingInterval = setInterval(() => {
            this.recordingDuration = (Date.now() - this.recordingStartTime) / 1000
          }, 100)
        }

        this.$emit('recording-start')
      } catch (error) {
        console.error('Failed to start recording:', error)
        this.initializeRecorder()
      }
    },

    pauseRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop()
        this.isRecording = false
        this.isPaused = true
        clearInterval(this.recordingInterval)
        this.recordingInterval = null
        this.recordingDuration = (Date.now() - this.recordingStartTime) / 1000
        this.$emit('recording-pause')
      }
    },

    togglePlayback() {
      if (!this.audioUrl) return
      
      const audio = this.$refs.audioPlayer
      if (this.isPlaying) {
        audio.pause()
        this.isPlaying = false
      } else {
        audio.play()
        this.isPlaying = true
      }
    },

    handlePlaybackEnd() {
      this.isPlaying = false
    },

    deleteRecording() {
      if (this.audioUrl) {
        URL.revokeObjectURL(this.audioUrl)
      }
      this.audioUrl = null
      this.audioBlob = null
      this.audioChunks = []
      this.recordingDuration = 0
      this.$emit('input', null)
      this.$emit('recording-deleted')
    },

    skipForward() {
      const audio = this.$refs.audioPlayer
      if (audio) {
        audio.currentTime = Math.min(audio.duration, audio.currentTime + 2)
      }
    },

    skipBackward() {
      const audio = this.$refs.audioPlayer
      if (audio) {
        const newTime = Math.max(0, audio.currentTime - 2)
        audio.currentTime = newTime
        
        if (this.audioUrl && !this.isRecording) {
          this.recordingDuration = newTime
          this.isPaused = true
        }
      }
    },

    handleTimeUpdate() {
      const audio = this.$refs.audioPlayer
      if (audio) {
        this.currentTime = audio.currentTime
      }
    },

    handleMetadataLoaded() {
      const audio = this.$refs.audioPlayer
      if (audio) {
        this.totalDuration = audio.duration
        if (!this.recordingDuration) {
          this.recordingDuration = audio.duration
        }
      }
    },

    formatDuration(seconds) {
      if (!seconds) return '00:00,00'
      
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60
      const milliseconds = Math.floor((remainingSeconds % 1) * 100)
      
      return `${String(minutes).padStart(2, '0')}:${String(Math.floor(remainingSeconds)).padStart(2, '0')},${String(milliseconds).padStart(2, '0')}`
    },

    finishRecording() {
      if (this.audioBlob) {
        const reader = new FileReader()
        reader.readAsDataURL(this.audioBlob)
        reader.onloadend = () => {
          const base64data = reader.result
          this.$emit('input', base64data)
          this.$emit('recording-complete', this.audioBlob)
        }
      }
    }
  },

  beforeDestroy() {
    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl)
    }
    if (this.recordingInterval) {
      clearInterval(this.recordingInterval)
    }
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop())
    }
  }
}
</script>

<style scoped>
.voice-recorder {
  width: 100%;
  padding: 20px;
}

.recorder-wrapper {
  min-height: 300px;
}

.timer-display {
  font-family: monospace;
  font-size: 36px;
  font-weight: medium;
  letter-spacing: 2px;
}

.skip-label {
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-align: center;
}

.v-btn.v-btn--icon {
  position: relative;
  width: 64px !important;
  height: 64px !important;
}
</style> 