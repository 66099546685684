<template>
  <div ref="editor" class="code-mirror-wrapper"></div>
</template>

<script>
import { EditorView } from '@codemirror/view'
import { EditorState } from '@codemirror/state'
import { html } from '@codemirror/lang-html'
import { basicSetup } from 'codemirror'
import externalModel from '@/mixins/externalModel'
import { autocompletion, startCompletion } from '@codemirror/autocomplete'
import { keymap } from '@codemirror/view'

export default {
  name: 'CodeMirrorEditor',
  mixins: [externalModel],
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    gridContext: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      editor: null,
      grid: null
    }
  },
  methods: {
    async getFieldCompletions(context) {
      // Show completions after typing 'field' or when explicitly triggered
      const word = context.matchBefore(/field*/)
     if (!word && !context.explicit) return null
      return {
        from: word ? word.from : context.pos,
        options: this.grid?.fields.map(field => ({
          label: field.name,
          type: field.type.name,
          apply: `{{fieldValue('${field.id}')}}`,
          detail: `${field.id} (${field.type.name})`
        }))
      }
    },
    initializeEditor() {
      const state = EditorState.create({
        doc: this.value,
        extensions: [
          basicSetup,
          html(),
          autocompletion({
            override: [this.getFieldCompletions.bind(this)],
            defaultKeymap: true,
            maxRenderedOptions: 50,
            activateOnTyping: true
          }),
          keymap.of([{
            key: 'Ctrl-Space',
            run: startCompletion
          }]),
          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              const newValue = update.state.doc.toString()
              this.externalModel = newValue
            }
          }),
          EditorView.editable.of(!this.readonly)
        ]
      })

      this.editor = new EditorView({
        state,
        parent: this.$refs.editor
      })
    },
    async loadGridData() {
      if (this.gridContext?.href) {
        try {
          this.grid = await this.$store.dispatch('AGReadGridOperation', this.gridContext.href)
        } catch (error) {
          console.error('Failed to load grid data:', error)
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (this.editor && newValue !== this.editor.state.doc.toString()) {
          this.editor.dispatch({
            changes: {
              from: 0,
              to: this.editor.state.doc.length,
              insert: newValue
            }
          })
        }
      }
    },
    readonly(newValue) {
      if (this.editor) {
        this.editor.dispatch({
          effects: EditorView.editable.of(!newValue)
        })
      }
    },
    gridContext: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadGridData()
        } else {
          this.grid = null
        }
      }
    }
  },
  mounted() {
    if (!this.editor) {
      this.initializeEditor()
    }
    if (this.gridContext?.href) {
      this.loadGridData()
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
    }
  }
}
</script>

<style scoped>
.code-mirror-wrapper {
  height: 100%;
  width: 100%;
}

.code-mirror-wrapper :deep(.cm-editor) {
  height: 100%;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.code-mirror-wrapper :deep(.cm-editor.cm-focused) {
  outline: none;
  border-color: var(--v-primary-base);
}

.code-mirror-wrapper :deep(.cm-scroller) {
  font-family: monospace;
  line-height: 1.5;
  height: 100%;
}

.code-mirror-wrapper :deep(.cm-content) {
  padding: 8px;
}

.code-mirror-wrapper :deep(.cm-gutters) {
  border-right: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}
</style> 