import { render, staticRenderFns } from "./SpaceListItem.vue?vue&type=template&id=3fee6972&scoped=true"
import script from "./SpaceListItem.vue?vue&type=script&lang=js"
export * from "./SpaceListItem.vue?vue&type=script&lang=js"
import style0 from "./SpaceListItem.vue?vue&type=style&index=0&id=3fee6972&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fee6972",
  null
  
)

export default component.exports