import Vue from 'vue'
const bus = new Vue()

export default {
  showPreview(files, startIndex) {
    bus.$emit('showPreview', files, startIndex)
  },
  onPreviewRequested(handler) {
    bus.$on('showPreview', handler)
  },
  previewRequestedOff(handler) {
    bus.$off('showPreview', handler)
  }
}