<template>
  <div
    class="d-flex textCell"
    @mouseenter="showExpand = true"
    @mouseleave="showExpand = false"
  >
    <v-btn v-if="!editEntityMode" class="expand-button" icon dark x-small color="primary" v-show="showExpand" @click="showMenu = true">
      <v-icon>mdi-arrow-expand</v-icon>
    </v-btn>
    <v-menu
      v-if="!editEntityMode"
      v-model="showMenu"
      :disabled="!textAreaMode && !showMenu"
      content-class="area-menu"
      nudge-left="5"
      nudge-top="1"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <input
          ref="input"
          class="pl-2 grey--text text--darken-3 transparent-textarea"
          type="text"
          :readonly="readonly"
          v-model="model"
          v-on="on"
          @keyup.enter.shift="insertNewline"
          @focus="$emit('focus')"
          @select="$emit('focus')"
          @keyup.exact.enter="nextLine"
          @blur="finishEditing()"
          data-testid="cellInput"
        />
      </template>

      <v-textarea
        ref="area"
        class="pl-2 grey--text text--darken-3 limited-height transparent-textarea"
        autofocus
        solo
        flat
        hide-details
        data-testid="text-cell-textarea"
        :readonly="readonly"
        v-model="model"
        @focus="$emit('focus')"
        @blur="finishEditing()"
      />
    </v-menu>
    <v-textarea
        v-else-if="textAreaMode || multiline"
        class="pl-2 edit-mode-area grey--text text--darken-3 limited-height transparent-textarea"
        solo
        flat
        auto-grow
        :rows="rows"
        hide-details
        :readonly="readonly"
        v-model="model"
        @focus="$emit('focus')"
        @blur="finishEditing()"
      />
    <v-text-field
      v-else
      class="pl-2 edit-mode-area grey--text text--darken-3 transparent-textarea"
      solo
      flat
      hide-details
      :readonly="readonly"
      v-model="model"
      @focus="$emit('focus')"
      @blur="finishEditing()"
    />
  </div>
</template>

<script>
import { gridCells } from '@/mixins/gridCells.js'
export default {
  name: 'GridTextCell',
  props: {
    multiline: {
      type: Boolean,
      default: () => true
    },
    rows: {
      type: Number,
      default: () => 1
    }
  },
  mixins: [gridCells],
  data() {
    return {
      showMenu: false,
      showExpand: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        const sanitizedValue = newVal === '' ? null : newVal
        this.$emit('input', sanitizedValue)
      }
    },
    textAreaMode() {
      return this.hasMultipleLines(this.model)
    }
  },
  methods: {
    hasMultipleLines(string) {
      if (!string || !(string instanceof String)) return false
      return string.includes('\n')
    },
    insertNewline() {
      if (this.model) {
        this.model += '\n'
        this.showMenu = true
      }
    },
    finishEditing() {
      this.$emit('blur')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textCell {
  position: relative;
  overflow: visible;
}

.expand-button {
  position: absolute;
  background: white;
  right: 4px;
  top: 4px;
  height: 24px;
  width: 24px;
  border-radius: 4px !important;
}

.area-menu {
  background: white;
  border-radius: 0px;
}
</style>

<style>
.area-menu textarea{
  color: #484848 !important;
  resize: both !important;
  font-size: 1em;
}

/* Removing v-text-area padding so that the parent can set it */
.edit-mode-area .v-input__slot {
  padding: 0 !important;
}
.edit-mode-area .v-text-field__slot {
  margin: 0 !important;
}
.edit-mode-area textarea {
  margin: 0 !important;
  padding: 0 !important;
}

.limited-height {
  max-height: 80vh;
  overflow-y: auto;
}
.transparent-textarea .v-input__slot {
  background-color: transparent !important;
}
</style>
