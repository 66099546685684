<template>
  <BaseBlock :blockUri="blockUri" v-on="$listeners" :editorMode="editorMode" :grabbableClass="grabbableClass" nesting>
    <template v-slot:menu>
     
      <v-list-item>
        <v-list-item-title>{{ $t('blocks.horizontalLayout.layoutDirection') }}</v-list-item-title>
      </v-list-item>
      <!-- Main Axis Alignment -->
      <SubMenu :activatorText="$t('blocks.horizontalLayout.mainAxisAlignment')" activatorIcon="mdi-align-horizontal-distribute">
        <v-list-item
          v-for="align in mainAxisAlignments"
          :key="align.value"
          @click="setMainAxisAlignItems(align.value)"
        >
          <div class="d-flex align-center">
            <v-icon
              :size="16"
              class="mr-3"
              :class="{ 'primary--text': mainAxisAlignItems === align.value }"
            >{{ align.icon }}</v-icon>
            <div>{{ align.label }}</div>
          </div>
        </v-list-item>
      </SubMenu>

      <!-- Counter Axis Alignment -->
      <SubMenu :activatorText="$t('blocks.horizontalLayout.counterAxisAlignment')" activatorIcon="mdi-align-vertical-distribute">
        <v-list-item
          v-for="align in counterAxisAlignments"
          :key="align.value"
          @click="setCounterAxisAlignItems(align.value)"
        >
          <div class="d-flex align-center">
            <v-icon
              :size="16"
              class="mr-3"
              :class="{ 'primary--text': counterAxisAlignItems === align.value }"
            >{{ align.icon }}</v-icon>
            <div>{{ align.label }}</div>
          </div>
        </v-list-item>
      </SubMenu>

      <!-- Wrap Toggle -->
      <!-- <v-list-item @click="setWrap(!wrap)">
        <v-list-item-icon>
          <v-icon :color="wrap ? 'primary' : ''">
            {{ wrap ? 'mdi-wrap' : 'mdi-wrap-disabled' }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('blocks.wrap') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </template> 



  
      <draggable
        v-model="block.children"
        :disabled="!canEdit"
        handle=".grabbable"
        ghost-class="ghost-card"
        :animation="200"
        :delay="120"
        :delayOnTouchOnly="true"
        @change="onDragChange"
        :direction="layoutMode === 'HORIZONTAL' ? 'horizontal' : 'vertical'"
        class="layout-block"
        :style="{
            display: 'flex',
            flexDirection: layoutMode === 'HORIZONTAL' ? 'row' : 'column',
            justifyContent: getJustifyContent(mainAxisAlignItems),
            alignItems: getAlignItems(counterAxisAlignItems),
            flexWrap: wrap ? 'wrap' : 'nowrap',
            gap: '3rem'
        }">
    

    
        <div
          v-for="(child, i) in block.children"
          :key="i"
          class="layout-block-child"
        >
          <div class="layout-block-child-content">
            <component
              :ref="`block_${child.id}`"
              :is="component(child)"
              :blockUri="child.uri"
              :editorMode="editorMode"
              @delete="deleteChild(child)"
              @updateBlock="() => updateBlock(child)"
              @appendParagraphWithText="text => addParagraphWithText(text, i + 2, false)"
              @appendEmptyParagraph="() => blockPickerIndex = i + 1"
              @mergeParagraphs="mergeParagraphs"
              @switchBlockType="switchBlockType"
              :grabbableClass="canEdit ? 'grabbable' : ''"
            />
            <BlockPicker
              v-if="blockPickerIndex === i + 1"
              :autofocus="true"
              :defaultOpen="true"
              @blockSelected="(type, defaultProps) => addChild(type, i + 2, defaultProps)"
              @appendParagraphWithText="text => addParagraphWithText(text, i + 2, false)"
              @addParagraphBlock="text => addParagraphWithText(text, i + 2)"
              @removeBlockPicker="blockPickerIndex = null"
              @appendEmptyParagraph="() => blockPickerIndex = i + 1"
            />
          </div>
        
        </div>
        <div v-if="editorMode && canEdit && !block.hasChildren" class="add-block-wrapper">
        <BlockPicker
          :pickerOnly="true"
          @blockSelected="(type, defaultProps) => addChild(type, block.children.length + 1, defaultProps)"
          @addParagraphBlock="text => addParagraphBlock(text, block.children.length + 1)"
        />
      </div>
</draggable>

  </BaseBlock>
</template>

<script>
import BaseBlock from '@/components/block/BaseBlock.vue'
import BlockPicker from '@/components/block/editor/BlockPicker.vue'
import SubMenu from '@/components/SubMenu.vue'
import draggable from 'vuedraggable'
import blockProps from '@/mixins/blockProps'
import BlockParentMixin from '@/mixins/blockParentMixin'

export default {
  mixins: [blockProps, BlockParentMixin],
  name: 'LayoutBlock',
  
  props: {},

  data() {
    return {
      showBlockPicker: false,
      layoutModes: [
        { value: 'HORIZONTAL', label: this.$t('blocks.horizontalLayout.horizontal'), icon: 'mdi-arrow-right' },
        { value: 'VERTICAL', label: this.$t('blocks.horizontalLayout.vertical'), icon: 'mdi-arrow-down' }
      ],
      mainAxisAlignments: [
        { value: 'START', label: this.$t('blocks.horizontalLayout.alignStart'), icon: 'mdi-format-align-left' },
        { value: 'CENTER', label: this.$t('blocks.horizontalLayout.alignCenter'), icon: 'mdi-format-align-center' },
        { value: 'END', label: this.$t('blocks.horizontalLayout.alignEnd'), icon: 'mdi-format-align-right' },
        { value: 'SPACE_BETWEEN', label: this.$t('blocks.horizontalLayout.spaceBetween'), icon: 'mdi-format-align-justify' }
      ],
      counterAxisAlignments: [
        { value: 'MIN', label: this.$t('blocks.horizontalLayout.alignMin'), icon: 'mdi-align-vertical-top' },
        { value: 'CENTER', label: this.$t('blocks.horizontalLayout.alignCenter'), icon: 'mdi-align-vertical-center' },
        { value: 'MAX', label: this.$t('blocks.horizontalLayout.alignMax'), icon: 'mdi-align-vertical-bottom' },
        { value: 'STRETCH', label: this.$t('blocks.horizontalLayout.stretch'), icon: 'mdi-arrow-expand-vertical' }
      ]
    }
  },

  computed: {
    layoutMode() {
      return this.block?.layoutMode || 'HORIZONTAL'
    },
    mainAxisAlignItems() {
      return this.block?.mainAxisAlignItems || 'CENTER'
    },
    counterAxisAlignItems() {
      return this.block?.counterAxisAlignItems || 'CENTER'
    },
    wrap() {
      return this.block?.wrap || false
    }
  },

  methods: {
    getJustifyContent(alignment) {
      const map = {
        'START': 'flex-start',
        'CENTER': 'center',
        'END': 'flex-end',
        'SPACE_BETWEEN': 'space-between'
      }
      return map[alignment] || 'center'
    },
    getAlignItems(alignment) {
      const map = {
        'MIN': 'flex-start',
        'CENTER': 'center',
        'MAX': 'flex-end',
        'STRETCH': 'stretch'
      }
      return map[alignment] || 'center'
    },
    async addParagraphBlock(text, position) {
      await this.addChild('paragraph', position, { text })
    },
    async setLayoutMode(mode) {
      if (!this.canEdit) return
      await this.block.setLayoutMode(mode)
    },
    async setMainAxisAlignItems(alignment) {
      if (!this.canEdit) return
      await this.block.setMainAxisAlignItems(alignment)
    },
    async setCounterAxisAlignItems(alignment) {
      if (!this.canEdit) return
      await this.block.setCounterAxisAlignItems(alignment)
    },
    async setWrap(wrap) {
      if (!this.canEdit) return
      await this.block.setWrap(wrap)
    }
  },

  components: {
    BaseBlock,
    BlockPicker,
    SubMenu,
    draggable
  }
}
</script>

<style scoped>
.layout-block {
  width: 100%;
  min-height: 50px;
  position: relative;
  height: 100%;
}

.layout-block-child {
  min-width: 0; /* Prevents flex children from overflowing */
}

.add-block-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
}

.add-block-button {
  border: 2px dashed var(--v-primary-base);
  background: transparent !important;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.add-block-button:hover {
  opacity: 1;
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
  border-radius: 4px;
}

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.layout-block-child-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  height: 100%;
}
</style> 