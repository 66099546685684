export default {
  WEBHOOKS_PER_SPACE: 'webhooksPerSpace',
  FLOWS: 'flows',
  SAVE_FORM_PROGRESS: 'saveFormProgress',
  FORM_SUCCESS_REDIRECT: 'formSuccessRedirect',
  FORM_HIDE_AD: 'formHideAd',
  FORM_READ_ONLY_FIELDS: 'formReadOnlyFields',
  FORM_HIDDEN_FIELDS: 'formHiddenFields',
  FORM_DEFAULT_VALUES: 'formDefaultValues',
  FORM_LOGO: 'logoOnForms',
  WRITERS_PER_SPACE: 'writersPerSpace',
  ENTITIES_PER_GRID: 'entitiesPerGrid',
  FREE_FEATURE_COUNT: -1,
  AUTO_SUBMIT_FORM: 'autoSubmitForms',
  FORM_I18N: 'formI18n',
  DOWNLOAD_QRCODE_SVG: 'downloadQRCodeSVG',
  DONT_SHOW_UPGRADE_BANNER: 'dontShowUpgradeBanner',
  FORMS_PER_SPACE: 'formsPerSpace',
  SPACE_COLORS: 'spaceColors',
  ADD_EXTERNAL_LINKS: 'addExternalLinks',
  PRO_NODES: 'proNodes'
}
