import Block from '@/store/models/blocks/Block'
import store from '@/store/store'

export default class EntityListBlock extends Block {
  constructor(data) {
    super(data)
    this.children = []
    // register the itemBlock also in the vuex store to be able to edit it
    // If you have blocks to process during initialization
    if (this.itemBlock) {
        this.processEmbeddedBlock(this.itemBlock)
      }
    }
  
      // Example method to process blocks
      async processEmbeddedBlock(block) {
        // Create a new action in blocksModule.js that will handle the processing
        const processedBlocks = await store().dispatch('AGProcessBlocks', {
          blocks: [block]
        })
        this.itemBlock = processedBlocks[0]
        return processedBlocks
      }

  get grid() {
    return this.entityList.grid
  }

  get itemBlock() {
    return this.entityList.itemBlock
  }

  set itemBlock(itemBlock) {
    this.entityList.itemBlock = itemBlock
  }

  get viewType() {
    return this.entityList.viewType
  }

  async setGrid(grid) {
    const itemBlockData = {
      type: this.itemBlock.type,
      html: this.itemBlock.html
    }
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        entityList: {
          ...this.entityList,
          itemBlock: itemBlockData,
          grid
        }
      }
    })
    this.reload()
  }

  getExcludedProperties() {
    return ['children']
  }

  async setItemBlock(itemBlock) {
    // Only send necessary itemBlock data without circular references
    const itemBlockData = {
      type: itemBlock.type,
      html: itemBlock.html
    }
    
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        entityList: {
          ...this.entityList,
          itemBlock: itemBlockData
        }
      }
    })
    await this.reload()
  }

  async itemBlockUpdated( block) {
    await this.setItemBlock(block)
    await this.reload()
  }

  async setViewType(viewType) {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        entityList: {
          ...this.entityList,
          viewType
        }
      }
    })
    this.reload()
  }
} 